import React from 'react';
import Mapbox, { GeoJSONLayer, Layer, Marker, Source } from 'react-map-gl';
import ImageTomb from '../assets/images/LocationExact.png';
import ImageLocationUnknown from '../assets/images/LocationApproximate.png';

const test_geojson = {
  type: "FeatureCollection",
  features: [
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [[[-5.0117315,34.0369832],[-5.0140722,34.0356865],[-5.0121778,34.0350513],[-5.0042231,34.0343515],[-5.0003363,34.037318],[-5.0035345,34.0402439],[-5.0081436,34.0418414],[-5.0117315,34.0369832]]]
      }
    }
  ]
};

// Fill layer for polygons
// https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#fill
const polygonFillLayerStyle = {
  id: "polygons",
  type: "fill",
  paint: {
    "fill-color": '#ffb8b8',
    "fill-opacity": 0.5,
    "fill-outline-color": '#ff0000'
  }
};

// Line layer for polygons
// https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#line
const polygonOutlineLayerStyle = {
  id: "polygons",
  type: "fill",
  paint: {
    "fill-color": '#fff',
    "fill-opacity": 0.65,
    "fill-outline-color": '#000'
  }
}

class Map extends React.Component {
  constructor(props) {
    super(props);
  }

  reset() {
    let o = {
      center: [this.props.initialViewState.longitude, this.props.initialViewState.latitude],
      zoom: this.props.initialViewState.zoom
    }

    this.Mapbox.flyTo(o);
  }

  flyTo(params) {
    let o = {
      zoom: 18,
      essential: true,
      ...params?params:{}
    }

    this.Mapbox.flyTo(o);
  }

  render() {
    return(
      <div className="map__wrap">
        <Mapbox
          ref={ref => this.Mapbox = ref}
          initialViewState={this.props.initialViewState}
          onMove={this.props.onMove}
          style={{width: '100%', height: '100%'}}
          mapStyle="mapbox://styles/bradyrsheridan/ckzzyaa8h000414t5upfnkly4"
          mapboxAccessToken="pk.eyJ1IjoiYnJhZHlyc2hlcmlkYW4iLCJhIjoiY2t6enk2MzY0MGV0dzNkcWhiM2ptYXhubiJ9.5yDutwKDTWJVl11IYQ9naQ"
        >
          {/* map items */
          this.props.items.map((item, i) => {
            // render polygon fill
            if (item.location_polygon) {
              let feature = JSON.parse(item.location_polygon.map_geojson_polygon);
              let featureNew = {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: feature.coordinates
                }
              };

              return(
                <>
                  <Source
                    key={`source-${i}`}
                    type="geojson"
                    data={{
                      type: "FeatureCollection",
                      features: [ featureNew ]
                    }}
                  >
                    <Layer
                      {...polygonFillLayerStyle}
                      onFillClick={() => this.props.isMobileMap ? null : this.props.onClick(item)}
                    />
                  </Source>

                  { !item.location_polygon.centroid ? null : (
                    <Marker
                      key={`marker-${i}`}
                      longitude={item.location_polygon.centroid[0]}
                      latitude={item.location_polygon.centroid[1]}
                      onClick={() => this.props.isMobileMap ? null : this.props.onClick(item)}
                    >
                      <img
                        src={ImageLocationUnknown}
                        width={38}
                        height={"auto"}
                        style={this.props.selectedItem && this.props.selectedItem.id === item.id
                          ? {border: '1px dashed black', padding: 4, borderRadius: '50%'}
                          : {}} />
                    </Marker>
                  )}
                </>
              )
            }

            // render point
            return(
              <Marker
                key={`marker-${i}`}
                longitude={item.location.coordinates ? item.location.coordinates[0] : "-6.792623"}
                latitude={item.location.coordinates ? item.location.coordinates[1] : "35.857057"}
                onClick={() => this.props.isMobileMap ? null : this.props.onClick(item)}
              >
                <img
                  src={ImageTomb}
                  width={38}
                  height={"auto"}
                  style={this.props.selectedItem && this.props.selectedItem.id === item.id
                    ? {border: '1px dashed black', padding: 4, borderRadius: '50%'}
                    : {}} />
              </Marker>
            );
          })}

          
        </Mapbox>
      </div>
    );
  }
}

export default Map;
