export default `,      ........................                          .... ...............  ...   ..........................          .......   ......          ................. .....   .. .....   .,.... . .,......,,,,,,,.....,,,,....,,........,.....................,,,.......,,.....,,,,,,,,,,,,,,,,.,,,,,,....,.,,,,..     ....  . .   .   .    . .....,,....,..,,,,,,,,.   .....      .....,....,,,,,,,,,,,,,....         .,,..,,.  ........,
,                                                                                                                                                                  ................... ....,... .,.     .     . .......... ...  .......,..  .........     .       .. ..   .  .      ....  .. .   .      .....            .  ..   .  ... .  ........ . ............,,             .......... ...   .  ...,..,.   .. ...,,....,.. .....   ,
,                                                                                                                                                                  ................ ...... .... .,,.  ..        ...... .. ..... ... ...,..    .. ... ...  ..      .  .     ..     ..... .    . .        . .              .       .          ...... .... .... ...  ,,            .... .. .. .. ...,...,,,,...  .. .  ..,......,..  ...   ,
,                                                                                                                                                                  ...... ..... ............,.   ..... .  ...... . ....,...........  .....  ..... . .... ...      .....        .  .. .... .    .        .              ..   .....          . .... .. ......... ...,.             ..  .... ..   ,,............. ... ..,,..., ,,,.....  . ,
,                                                                                                                                                                 .......  ...........  . ..,... ....  ..... ..,,...........,.......   . .. ...... . ...... .     ....          . ... ... .   .                       .           .        ....,...... .. . ........               ...... ,...... .,..   .. .....   .,.,...,....,,,.....,
,                                                                                                                                                                  .,.................  . .,,. .  .. ..  ......... ...  ............  ...........,... ... .. .    . .    ...   . . . .. ...  ..        ..  .          .      .. ..  .      ....,,.. ....... . .....,               ...... .....,. ........  ...     ,,.,...,...,....,...,
,                                                                                                                                                                  ..,... .. .    ..,,............,.  .. ... ........   ............     . ..,..... .......         .  . .    .. . ...      . ..    .      . .                    . .       .  .. .... .....  ....,.             ........ .. .....,,,.....         .,,.,.,,.,.. .,. ,..,,
,                                                                                                                                                                     ........      .,............... .. . .. ..... ........ .. ...      ..... .....  . ...        .    .     ..  .   .    .... .          . .                    .      .     ..   ......,. .....,.           ..... .... .,... ..,......          .., ,,,. ,.. ..... ..,
,                                                                                                                                                                      ...           ......... ...... ..   ........ . ...,..,..   ....  ..... .,....,... ..               ..  ..  .. .         .                                               ......,.,. .... ...,.           ....  . .. ,...  ..,...,.          ...,..,.  ........,.. ,
,                                                                                                                                                                                     .... ... . ........  ....  .  ........... ...... .....  ...  ... .               .  ... .......     .                                   .   ..   .  .   ..   ..,.,....  ... ..           ...  ......,,......,...,.         .. .,...   .... ...,,..,
,                                                                                                                                                                                         .......,....  .  ......    ... .. .... .....   ..,...  ......                .   ..   .... .. ...   ..        ..   .              .  .  . . .... .  ..... ... .. .... ....          ...   ..............,...,    ..... .. ,,..    .... ....,..,
,                                                                                                                                                                                   ...   ........ .....         .    . .  ...  ...  . .......... ...,.      ...       ..      ....     . .  . .       .. .. . ...       .    .  ..   , ,..    .    ...... .. .. ,.           .... . ....  ............ ........ ...,,.     .....,..,,..,
,                                                                                                                                                                                   . .   ...... . .......  ..    .   . . ....  .,........  ....,....,.      . .     . .. .. .... .    .... . ..        ...    ..        .       ..   ...         ...... .   ....,.          ...   ...  .. .. ..... ... ... .. . .  ,,         .....,...,
,                                                                                                                                                                                   ...   ... ............ .   ..     . ......  .. .......  . ... . ....     ...  ... .. .      . ......  ....          ..      .      .          ..  .....      ..   ...   .  ....          .,   .....          .....  .... . ... .,,..    ....,..... .,
.                                                                                                                                                                                         .. ...... .......   ....    .... .. ..,...... .    .... ...    ...    .... .          . ...... .. ...                                       ..   .     ..  ..............         .,.  .......... .   .....    ... .. ........    ............,
.                                                                                                                                                                                         ............ .........,...  .,.,  ...,.. .....   ....  .. . .. ...   .. ...   ..   .  ..,.. ..   .  .                                  ... .    .      .    .   . ..  ...         .,.....  .. ..  .   .....      .. ... ....,. .  ...,,..,,,..,
.                                                                                                                                                                                          .. ..,. .    .............,......,...........  ...,... . ..   ...  ......    .    .   ...........   ...                             ... .. ... .      ..    . ....   ...         .,....           . ...,.        ........  ,,,. .,,. ...,.,...
.                                                                                                                                                                                           ..,....  ..  ....,... . ... . .,,.. ......................   .  .   . .. .  .. ..   ...,..    ..   ...                   .         .  .. .. .       ... . .. ...... ...         ..  ..          .. ..,.         ...............,,.. ....,...,
,                                                                                                                                                                                           ...........  ....,.... ....... ,............. ..............  ....  ....     . ... .. ..... ....   ....                ..           .     .... .   ... ................         ......  .     ..  ....         ...... ... ....,.. . .....  .,
,                                                                                                                                                                                          ..,... ..............  .,...,...,....... ........ .  .,,.....  .. ... ..        ... ... .. .    . . .  ..                ..                .... .   .......    . .......         .....  . ..  ....   .          ,,,,... ......... ...... .. .,
,                                                                                                                                                                                         ..,. ..  ........ .. ...,.. .,........... .. ... ..   ......  . .......         ... ....  ... ...   .           ...                         ....  . .... . ..   ..  ..,.          ......  ..    .. ...          .,.. ... .....................,
,                                                                                                                                                                                          .,........... ...,. ....  ............,. .  .. .  .. .. ..,..... ....... ..    ,.. . ..   ....            .    . .                          ..    ..... ....   .. .. ..         .,......   ..   ....           .,........ ,...,,.. .. .. ... ,
,                                                                                                                                                                                          .,....... .  . ...,.................... ... . ..   ........, ...  . .  ..     ., .. . .....  ....   ..   ...                 ..     ...               .......... .. ...         ......    .   ......          .,,, ......,. . .....,....   ..,
,                                                                                                                                                                                        ...................... . ...,......,. ,...,.   .... .........,.. .  . .   ....  .... ... .. .    ..  . .     .                 . .     ..    .          .....    . . ....         .. ........   ...  .          .,,,,..,....... ...........   .,
,                                                                                                                                                                                        .......... ......,................... .....,....... ..... ......,.... .   .... .. .  ......       .. ...   ..                  ..     .  .   ..        ..  ..    . ... ..         .. ..  ..    ......          .,.,,........,...........,.,.   ,
,                                                                                                                                                                                        .... .......,....,.  .......  .............,.......  .. .. ...... .....  .......... ...,. ..   ..........   .                   .      .. .             .. .     . ..  ..         .....        .... .          ..   . . ...... . ... .,,. ,. ..,
,                                                                                                                                                                                        ..... .  ...,,,....  ................  ... .. .  ......  . ...........,..,.... .........   ...... .........    ....                      .              ........  . ....         ... ..  ..    . ....         ...   ....,,..,.........,...,....,
,                                                                                                                                                                                         ......  . ......   .... .... ........ . ..,... .. ..    .. .... .....,..,.,.  ...... ......... ........... ..     ...                             . .  ....   ...  ..,.         ......  ...  ....            ........ ........,.,.. ..........,
.                                                                                                                                                                                         ...     ....     ............ ..... ... .... ...........................,...... ..  .. ....   .. .... ...   . .. ....                       . .    . . ....   .... ...,         .. ... .  ..   .             .... ............,.,...... ......,
.                                                                                                                                                                                                 .......  .. .,...... ... .,.  ............  ...  ....  .,.. ... .... ... .. .  ................    ..   ......                  ..         . . .          ....,         ...... .    ....                 ..,.. .. .. .....   ..,......,
,                                                                                                                                                                                                 ....... .......... ..... .... ......... ... . ...,......, ... ...... .......... ...  ........ ...      .. .....            .  ..           . . . .     . . ....         ...... . . .. .             .    . .....,,. ,,...   .....,,...,
,                                                                                                                                                                                                   ....... .... ..... ........... .... .. ........,..,........... ..,.. ............   .,,,. . ....   .........               .         .   ...  .   ......   ..        ......... .  ...             ...  ........,,.,...  . ...,,.....,
,                                                                                                                                                                                                    .. ................. .,.. ... .. ... ....,.. .....,.........,......,,.........  ..  , ,. . .....  ..., ,...                     .. . .               .    ..        .... .....                   ..  . .....,. ,.,.,... . ...,......
,                                                                                                                                                                                                     .... . ..............,.... ....,... ....,.. .,,,......    .....,.,.,.........   ...,....  ... .  . .......                     . ..  .                  ..         . ... . .                       .  ....., .,.,.... . . ,.. . ..,
,                                                                                                                                                                                                   ................. .. .....,.. ...... ........ ..,, . ...     .. .....,,... .. ..... ......     . . ... . ....                    .. . .  ...   ..         ..         .........  . .                  ........,....... .. ...........,
,                                                                                                                                                                                                   ... .............. .......,....................,,. ....      .... ....... ..     .........     . .......... .....                 . .  . . .              .          .... . ...             ...        . .......,,...  .. ..........,
,                                                                                                                                                                                                   ....... ......   ....... ...............    ., ..  ...     ................    . .........   .  .,,,.............                 .   . .                 ..        .. ...  . .             .... .  .... ..... ...,.  . . ...,. ....,
,                                                                                                                                                                                                    ....,. .....,.....................,. .    ....... ..      ........,........... ..... . .........,....,..     ...                  ..             .        .        .. ..     ..            ...... .............,,,.... ....... .. .,
.                                                                                                                                                                                                     .,,..  . ...,..,,.,.. .... .................. ...        ...... ,,... ........  ... ....,...  .,......... .....              ...                 . ...  .         .. .       ..              . ..............,,.   ........,. ....,
.                                                                                                                                                                                                      ....     ...... ..... .. .. .....   ..... ., ...   ...  .......,.........  ..,,.  . ...,..   .,..  ..,.,   ...              .            . ..     . .            ....      ..               ......... ......,.      ,..,...... .,,
.                                                                                                                                                                                                       ...     .,,...  ...... ...  .     ....,,..,...,,....,,..,. ..,,.. ... ..  ..,,,.. .... ...   ........,..... . ..           ..           ..        .  ..         .    .    .           .... .. . .   .. .....   ....,..... .... .,
.                                                                                                                                                                                                               .,,,..   .. .. ....       .,,.. ..,....,.....,,,,....,..........   ...,....  .....   ........   . . ....                         .  .  . .    .         .   . .. ..           ....   .    .  ...    ......,...,.. ......,
,                                                                                                                                                                                                               .....     ... ... ..      .,,,...,.,.. ............ ,,   .................... ....       . .    . .  . ..                   .     . .  .     ..         ... .  . .            ...   .  .  ..       .. . .......,. ......,
,                                                                                                                                                                                                        .... .. . .      ...   .. ..    .. .......... .............,.   ........ ......,.... ,.,.      .    .....      ..         .      .       . .        ..         ..      ..            ....              ..............,,. ......,
,                                                                                                                                                                                                        ...........           . . .     .........  .,,..............,,,.. .. ........,,,.  . ,.,....   . .   ....     . ..                      .. .        ..                . .     .     . . ..            .....,.,.. .. .... ..... ,
,                                                                                                                                                                                                        .,,.   .                ...     .... . .   .......... . ........  ......,.,..,,.. ....,.....   . .   ....    . ...        ....          .   .   . . ..      ..     .  ...    .   .... ...           .. ....,......... . . . ...,
,                                                                                                                                                                                                          ......               ....      ......      ..........  ...,.,..  ...... .  ,..............    ..     .        . ..       . .    .    .....     . ...      .     . . ..         . ..     . .  .   .    ...,,..... .. . . .. . ,
,                                                                                                                                                                                                           ....                . .       ......      ....,...,.....,,.... ..     .,.... . ,. ..... ..              . .   . .       ....                  . .        ..     ....  .....    .. .        .     .......  ...,... ..   .....,
,                                                                                                                                                                                                                               ..            .      . ..... .,.,,, ,,.. .. . ..  .,..,...... ... . ......      ..  .       .                             . ..             .      .   .            ... .      ... ..... . ... . ..... ..,
,                                                                                                                                                                                                                                                    ..... .. ...,,,,,.   .. ...  ,.... ....... ... . .......   . . .     ....            ....         ... . .           ..  .       .. ..         . .         .... ..........  .. . ...,
,                                                                                                                                                                                                                                                    .............,,,.     .....  ...,,.,.,... .... .........   ....   . .. ..             . .  .      ... .      ..    . ...  ...     ... ...     .  .      . ..  ..........,..... .. .,
.                                                                                                                                                                                                                                                    .........             .... . ......,,.,,... ............    ...  .... ...         . . ...         ...  .          ......  . .     . ... .                   .   . .... ... ., ......
.                                                                                                                                                                                                                                                   .,,,.,....             ......... .......,,.. . . .... ..     ...... ... ..         ..  ..   .     .  ...... .         ...          .  ....  .    .          ......   .  .,.... .,....
.                                                                                                                                                                                                                                                  ............             .. ....  ....   ,. .....    ...... .. ...     . .                       ..   . .... .     .   ..                    . .  ... .   ...           .......    ..,
,                                                                                                                                                                                                                                                  ............                 ..    ..   .,......     . ....... ...     ..,.                      .     .  ....         .     .  ......  .         .    .               .. .. ..     .,
,                                                                                                                                                                                                                                                  .. ... ,.                   ..          .,,.....     ............. .. ....,     ..   .    .    ..   .      .... .     ..    ...   ......           ..  .     .      ...........   ...,
,                                                                                                                                                                                                                                                  ...,,..,                                .,,...     ....... .. ...,,. ......            .      .. .     ..... .. .......    .... ..  . ..      ...    . . .. ..  ... ............  ...,
,                                                                                                                                                                                                                                                  ........                           .   ..,....     ........ ........  .....     .             ..     .      .  ........    .... .  ..  .    . . ...  .. .   ..  ...  ...... ...   ...,
,                                                                                                                                                                                                                                                       ...                         ...  ...,. .. ... ..... . .......,.   . .                      .. .....    ...........    ...  ... .  ..     ..    . ..     .  .... ..... . ...... .,
,                                                                                                                                                                                                                                                                                   .. ........ ........ .....,... . ..  . .                   .  .   .....    .........      .    .. .. .  .  . .. .  ... . .      .. ... .. . ... ....,
,                                                                                                                                                                                                                                                                                   .......... .................. ...... .                   ..     . .... ..   ...   ..    ..    .. .......      ... ....... .............  .. . ... ..,
,                                                                                                                                                                                                                                                                                     . .. .,......... .. .   .. ..... ...                   . . .  . .. .... .  ..  ..   ...     .... ......   ..... . .  .. ..  ..  .. .. .. ..   ....,
,                                                                                                                                                                                                                                                                                     ..,,,,....,....... ... .  ..   ...                         .  .  .  ...... .  .     .     ..   ... ... .    .... ..   ..,............ .   .... ....
,                                                                                                                                                                                                                                                                                     .. .  .,,,..,... .. .  ....  ....                    .,.. . ...  . .........              .  . ...   .. .  ....... . ..., ...... ........... .. ..,
,                                                                                                                                                                                                                                                                                     ...,,.,....,.... ....     .... ..                   ..... ..... ....  ... ...      ..  .  ..  ..       .   .   ....... .........  ...... .... ... ,
,                                                                                                                                                                                                                                                                                     ........ .,. .. ..          . ....               . .. ...  ....    ... .. ...      .  .   ..  .       ..     ..   . . .. . .. ... ....   .. ......,
,                                                                                                                                                                                                                                                                                      .  ............           ...   .                ..... .   ,.. .... .  .. ..      .. .   ..  .. .    .   .. . ...  ......  . ..    .     .. .....,
,                                                                                                                                                                                                                                                                                           .. . .. ..            .   .    .         .. . ....    ..........  .....  ...  . . ..  ..... ..  .  ..  ...     .. ... ........   .   .... ..,
,                                                                                                                                                                                                                                                                                            .. ..,..             ..                 . .. ...     ... ....    ........   .  ....... . .  .. .... . ....      .... ...    . .  ...... ...,
,                                                                                                                                                                                                                                                                                            .....,.            .            .          . .      ...... . .  .......    ... ..... ....  ..  ... . .....      .... .. ...  ......... .....
,                                                                                                                                                                                                                                                                                         .......,...          .                        ....    .    ....   ..   ... .  . . ...... .    ....     ..  .     . . ...   ..   ..............,
,                                                                                                                                                                                                                                                                                         .....,,.. ....    .   .      .                .....  . . ...    ............. ...  ......  .     .       . .       .......      ... .. .......,
,                                                                                                                                                                                                                                                                                         ........,.....              . .         .........    . . ..    .. ......... ...  .......         .       .  ..     ..........     ... ... ... ,
,                                                                                                                                                                                                                                                                                         ....  ........   ..     .         ....  ...... .     ... . .    . ... .   .........  .                     . .  .......... .... . ... ......  ,
,                                                                                                                                                                                                                                                                                        ... ......, ...   ...             ..... ....... . . ... ..      .........  ,...  . .. .    .       .        .......  ............. ..,......  .,
,                                                                                                                                                                                                                                                                                      ...........,..,..   ...     .        .. ................ .        ....... .  ,..... .     ...  .      .          ..... .  .......    ..,....... .,
,                                                                                                                                                                                                                                                                                      ....... .....,.          .. .         ......... ..., .... ..... . ..  ... ...... . . .   ..                     . .. . .....    ........      .. ,
,                                                                                                                                                                                                                                                                                      .,. .......,,..        ..  ...        .....,..... .. ..  ... ..... ...... ........ ...  ....                   ...  ..   . ..... . . .     ...  .,
,                                                                                                                                                                                                                                                                                      ..,.... ..,,..        .  ...          ... .... . . ..... .....  ......  . ...... ....   . .     .. . .       ..  ..  ..... ..  . ... .     . .  .,
,                                                                                                                                                                                                                                                                                       ..,,,..,,... ...    .  ..           .... ........  .....        ..  .... .. .........,, ..     . .   . .    . ....   .   ..... . ....  .... ... ,
,                                                                                                                                                                                                                                                                                        ......,,     ..    .   .           .... ....,.... .. ..   .. ...   .... ... ............      ...   .       ..          ...,.......   .... ....,
,                                                                                                                                                                                                                                                                                           .. ,,,.....        .         .... . .  ..,.. ...  ..  . . .... ....   ......... .,...                              .. .,.,. .......,........,
,                                                                                                                                                                                                                                                                                              .,,....     .....         ....   .. .,,,...   ...  . ......  ..       ......  .                     ..  ..      .....,.      .. ... .....,
,                                                                                                                                                                                                                                                                                               .,....     .. ..         ....       .,.  ........ ...  ...  ..  ..   .. ..   ...    .           . .   . . .   .... .         .     .. ..,
,                                                                                                                                                                                                                                                                                               .......      ...           .       ........ .,..... .....   ..  .  ..... .      .  ..           .  .... ..  ... ...   .    .. .....  . .,
,                                                                                                                                                                                                                                                                                               ....... .,...          .   ...   ... ....... .... .. ... ..  .  ... .... . ...  . ..                   .........    ...   .   ... . ....,
,                                                                                                                                                                                                                                                                                              ..,,. ........    .       ..  ..   . .. ............. ....... ..   ...       ..  .  .....             ..... ............   . . ......... ,
,                                                                                                                                                                                                                                                                                             ...,,.... .....     .    . .. ..  .......... .. .. . ...... ... . .. ....   ..... .....  ..           ... ...............    . .  ........,
,                                                                                                                                                                                                                                                                                            .,,... .   ......            ..................,.   .....    . .. .. .......... ..    .....             ... . ., . ...   .       ..... . . ,
,                                                                                                                                                                                                                                                                                            .....,,.....   ..  .         ,...  .. ..........    ... ..   . . .     . .... ...    .               .  ...    .....   ....      ... ......,
,                                                                                                                                                                                                                                                                                            ..,,..,....... ..  .         ,., . .............  .  .  .    .. .        ...  .. ..                   . ....   ... ... . ..     . .    ..  ,
,                                                                                                                                                                                                                                                                                              .............. .           ... .... . ........    ....  .        .     ..     . .. . .    . .  .       .      ...........       .     .  ,
,                                                                                                                                                                                                                                                                                               .............   ....     .... .......   ......               .   ...   .   .  .  .  .    . .               . . ..  ..... .         . ...,
,                                                                                                                                                                                                                                                                                                 ...... ...    ....     ....     ... . ... ..  .... .       .. ....   ......   . ..       ..             ..  .. .. .. ..    .  ..    ..,
,                                                                                                                                                                                                                                                                                                .......       .  ..     .. .....   ...... . . .    ....   .  ......    .. ..    ..                    ... .  .,.  .. .  ....... ..     ,
,                                                                                                                                                                                                                                                                                                .......      .   ...    ..... . .   . .. . .. .... .... .     .....     ...              ..       ..  ....  .. ..   . .....,......     ,
,                                                                                                                                                                                                                                                                                                ..,....    .  . .  .    ..... .. .   . .    ..... .... . .  ..  .....  . .     .     ..              ...  ...,,.  .. .............    .,
,                                                                                                                                                                                                                                                                                              .,,... ..    .  .... . .  .,,,  .      . . ..   ....   .  ....  . .  . . ...     .   . . .             .......  ... ............ ...    .+
,                                                                                                                                                                                                                                                                                              ,,,,... .  ... .   . . . ....,.     . .. .  ..   ,., . ... ... ....  . .      ......   ..           . .... ...    . . . ..... .. .. ... .,
,                                                                                                                                                                                                                                                                                             .,,..,.... ... .... ... . ......     ..  .. ...   ...  ..  ...  ...   . .   .    .. . ..             . ....        ...... . .,,,.. . .....,
,                                                                                                                                                                                                                                                                                             ..,,... .  ........  .  . .... .   ......  ..... ..    .   .... .. .... .      ..  .... ..       .   .  ...       ... ..  . ............  ,
,                                                                                                                                                                                                                                                                                              ..,.,.... ... .....     ..... . .. ....   .....,,..   . .....    ...   .    .  .... . ..         ..  .         ..   .    ... ... . ...   ,
,                                                                                                                                                                                                                                                                                                 .,,,.,,. ,. . .     . .. ... . ., ,.   ......... ... ......   ...... .   ..    ...... .......       ..    ..   ...  .. ...... . ..... ,
,                                                                                                                                                                                                                                                                                                  ..,,,..,.. . . .   ..  ....... ,...... . ..,..  . .  .. ..        . .          ......   ..  ...          ...       . . .   ... ...   ,
,                                                                                                                                                                                                                                                                                                    ...,,..... ... .. ......   .... ,......... ..   .. .....         .        .    ...    .....       .   .. . ...   ...      ..    .  ,
,                                                                                                                                                                                                                                                                                                     ..,,. ... . .....      ..  . ..,..... .  ...  ...  ....                 ..      ....         ..      . .  ...   .            .,,,.,
,                                                                                                                                                                                                                                                                                                      .,,,,,,.. ...... .     .... ..,,....... . .  . .  ....                 . ..   .......      .        . .  ...  .            .,,,,,,
,                                                                                                                                                                                                                                                                                                       ..,,,,, . .........   .  ...   ..,.. . .... ...  ....             ... ...   .........     ..   .   ..   ..   . .        .  ,,..,,
,                                                                                                                                                                                                                                                                                                       .....,,.. .............. ......... . .   ..  .   ....        .    . .        ........    ......                .      ... ..,,,.,
,                                                                                                                                                                                                                                                                                       .                  ...,.....,,.. . ...  ...... ... . ......      .... .         .   .  .  .  .. .....  ...  . ..       . .    .   .  ...........,
,                                                                                                                                                                                                                                                                                     ....          .       .......,. .. ............ ..   ........      .. .                   .. ...........  ..   . .     ..  .    .. . . . .....   .,
,                                                                                                                                                                                                                                                                                     ......      ......... ,..,... . ........... .....   .........      ..... .    .       ..  ....  ........ ..   .....     .          .  ... . .... .,
,                                                                                                                                                                                                                                                                                   ........     .....  ....,......,. .   ......    ,.......  .....       .. ..   . .            . ...... .. . ..    . .    ...      .....  . .. . . ...,
,                                                                                                                                                                                                                                                                                   ... .....  .......  ... ,.........   .. ..  .. .,..,,.......,,.       ....... .      .   ..,,..   .......... .   .   ...         ..... . ... ... ...,
,                                                                                                                                                                                                                                                                                 ............ .......,.. . .,........ ... .. .................,.,,   .    .   ...            .,., ...   ....... ..     ....    ... ..,.....   . ....,,.,
,                                                                                                                                                                                                                                                                                ................. ,..,,...... ..,...,..  .. . . . .....,..  ... ,.  ...       ...    .   .   ,,,,......... .. ... ..    ........    ,.... . .   ....,.,,
,                                                                                                                                                                                                                                                                                ....... . ..,,,,...  ., ....,...... ....... . ......,,,...,.,,,,,,  . .       ... . ..    .  .......... . .... . .     ...   .  .   .,..... ... ......,,
,                                                                                                                                                                                                                                                                               ...... ..... ..................  ........... ... ....,.,,,.,... .,.   . .      ...            ... . .... ... ....              ...      ...  ....  ....,,
,                                                                                                                                                                                                                                                                              .......  .   . ... ....... .  . ........,...  .. ....., .,,.....,,,.   .. .. . . ..              ....... ...  ..      .    ..               ..      .....,
,                                                                                                                                                                                                                                                                           ..............   .... . ....... ....... .......,... ... .,.  ..,,..,,.     . ..       ..         . ............           .. .. .             ....    ......,
,                                                                                                                                                                                                                                                                          ............. ..... . .......   .. .,... ........... ........ . ..,....      ..         .          ....  .. . ..     ..      .   .. .         .. ..    ......,
,                                                                                                                                                                                                                                                                           ......   .  .... ......... ... ..,,,,..  .... ....  ... ...,.....,....            .    ..      .   ...........      .   ....  .. . .      . ......   ., .,..,
,                                                                                                                                                                                                                                                                           .,........        .  ..... . . ... .,..  ..  . ..,... ................            .    ..      . .,,......  ..    ....  . .    ... ....        .    .. .... ,
,                                                                                                                                                                                                                                                                         ..,.. .....    .     ...... ... ....,,,..  ..   ..,.,...,. . ... .......                 .      ...,. ,..... ..     .....  ..       . . ..        .  ....... .,
,                                                                                                                                                                                                                                                                         ..... .......        ....  ...   ...,,... ...   . ,,.. ......... .,,.,..                 ..    .,....,....   ..     ..  .         .. ... .            .. .....,
.                                                                                                                                                                                                                                                                         ..,.. . ....    .     .  .  .    ..... .. .. ......,,................,..                  .   .... ...,,,,  .       ........        .......          ..... ..,,
.                                                                                                                                                                                                                                                                          .,.. .... ..   .     .  ..  ... . ......... .. .. ....,.  .... .,,,,....    .                ...... . .,,    .      ...  ...  .    . ..   ..   ..  .. ........
.                                                                                                                                                                                                                                                                           ,.  ... ..    .    .   ........... ....... ..........,.   ...,,,,.... .  . .           ..   ....,...,,,.     .   .   .....  ....  ........     .  .  ,.......
,                                                                                                                                                                                                                                                       .....            . ....  ... ..    .   . . ....   . .    .............,..,.   ...... ....    . .           .    ,.......,,.  .             ....... .. ... .. ....   .. .....  . ,
,                                                                                                                                                                                                                                                      ..........       .......  .. ...  . .  .. . ....   .     .  .. ... ....,..,,,..  ...,.. ,.    . .           .. .., .....,.,.        .......  .... .......  .... .    .  .....  .,,
,                                                                                                                                                                                                                                                   ..... ........      .......   .......  .   . .  ..    ..    .  .. .. .........,,,.   ...,. ,.      .             ....... ...,,. ...    .... .   .. . ........  .  .       ...... .,,,
,                                                                                                                                                                                                                                                   ....... . . ..      ........  ..         .........        . . ... .. ...........,..........,.                     ........ ,...  .    . ,.,      .... .. .. ...     . ....,....,....,
,                                                                                                                                                                                                                                                   .......  .. ..      ......... ...      ....... ...   . .......... ., ... ...... ,,.,....,....              .    . ..,. . ..  ....   .   ,.....    ... ........ .  ..     ......... .,
+                                                                                                                                                                                                                                                   .. ..... . ....   ...,. ....  . ... .  ... ......       ...., ... ......... . ...,,.....,..,.                   . ,,,.... .....         .......    . ... . ....   ..     .......   .,
+                                                                                                                                                                                                                                                   .. .  .. ......   ..,,. .. .  .        .....    ..  . ...   ,... ... ......   .. ....,,,,.,,,        .            ,,....,..,.         .....  .,,,,,,.....,,.   .......... ......   .,
+                                                                                                                                                                                                                                                   .... . ........   ..........       .. .  ...  . .... ....   .... ... ... ... ...  .,,,....,,,        .            +,,...,.,...       ..  ..,,,,,,,,,,.,.,.... ................ .....,
,                                                                                                                                                                                                                                                ........ ..... ...   .... .....    ...... ..    .  ..   .. .  ..... ...... .... ... ...,. .. ,,,     .  .           .,....,,,,.         .,. ...,.... . ,,.,.............. ....,........,
.                                                                                                                                                                                                                                                ..,,,.  .    . .,.......... .  ..  . ..... ... . .. .   .. .  . ... ...... ...  ..............,,  . .               ,,,,..,,+,...      .,.,..,,.........,... .. .,. ..   .,. . .... ..,,
                                                                                                                                                                                                                                                 ..  .. ..    .......,...,. . .  .. ..... ...  .. ..      . .  . ........... ..  ......  ........  .                 ++.,,..,.          .... .,,   ......,...... .,...    ., .......,,,,.
.                                                                                                                                                                                                                                               ...... ..    ...........,,  .    ..   . . .......      .    .  ...  ..........   . ....  .....     .                .+,.,.,,.           ,.. ...,.   ..,..,...... ......  .., .....,.... .
.                                                                                                                                                                                                                                              ........ .. . ... .......,.. .   ...  .. .. ....         ... .   .. ..  ................. .         .    ..          .+,.,,,.           ..  ..,,,.    .,,.,,,,,. ......,,,.,.....  .,.  ..
,                                                                                                                                                                                                                                             .... .... . .. ... ... ....     ...... ..     .          .        ...,,........................      .    ..     .    ,,.,,,.         .. ......,..      ..,,,..,,,,... .,++,.... .,..,.  ..
,                                                                                                                                                                                                                                             ......,.      ... . .....    ...... .....   ...    .  .           ........     .....,,,,,,........        ..          ,,,,,.          . .,..,,,,....         ,...,,,,,,.,.....,..,.....  , 
,                                                                                                                                                                                                                                              .....,.  .    ....... ......  ....... ... ...    ........    .  ....,,,,,,,,....,................        ..          .,.,..   .      . ....,,,... .         ,.,   ........ . .... ....  ,.
.                                                                                                                                                                                                                                              ... ... .   .  ....... ......... .. ...  ...  .    .....  . ......,,,,..............     ......,,        ..          ,,,...          .....,,.   ...         ...    . ......... .......  ..
                                                                                                                                                                                                                                               ... ..... .  . ....   . ...,......  ..... ...  .  ...  ..  .........   ....................... ,,        ..         .,.,.           .,,.,,,..                    .. .......,........ .....
                                                                                                                                                                                                                                              ..... .. ..   . .. .   ............ .... .....     .  ...  .....................                ,,        ..          ,,,.    .      ,+,,,,.                     .... ., .. ........ ......
.                                                                                                                                                                                                                                          ...,,,.........  .. . .   .................... ..     . .   ..... ....                             ,,        ..          ...     .     .,,.,....                 ....... .,..  ..,....,.. ....
.                                                                                                                                                                                                                                          ....,.... ..... . ......  ....,. ... . .  .......     .. . .........                               ,,         .          ..           .,,, .. ...                ........ ..,. .., . ......  .
.                                                                                                                                                                                                                                          ,...,. ........ .          .. .... ....  ..     .. .    ..........                    .....,,,,,,,,,,                    ....        .,+,.......               . ........ .......,........ ...
                                                                                                                                                                                                                                          ...  ......... ..  ..  .   ..... .. ..... ..  .   ..       ....  ....,,,,,,,,,,,,,,,,,,,,,,,,,........         .           . .        .+,..  ...    .. ...     ....., .....,...,..,............
                                                                                                                                                                                                                                          ..... ... .... ...      ..  ...........       .. .  ..... .....,,,,,.........        .......,,,,,,,,,.     .   .          . ..        ,+...            . ....  .,. ,.... ......., ,. ..........
                                                                                                                                                                                                                                           .,.     ..... ...     ......... . ... .. ..  .  .    .   ..... .....,,,,,,,,,,,,,,,,,,,,,,,,,......,.         .          ...        ..+,           .. .... ........., ...... ..........  ...  
                                                                                                                                                                                                                                            ...  .  ........     . .. . ............    ....... .    .. ..,,,,,,........                    .,,.        ..      .              ..,,               .  ........... ...,.............       
                                                                                                                                                                                                                                            ....  ...  .  ....   .... .   ,....  .  .  ......... . . .. ...                                ..,,.     .  ..                     ..,,               .. ...  .... ...........  ... .,.      
                                                                                                                                                                                                                                            ... ....  ..  ...  ...... .. .,.,...  . . ..... .... .  ..  ...                               .. ,,      .  .. .                    .,,.                .... ....    .........,,.. . ..      
                                                                                                                                                                                                                                             .....    ......  ... .....  ..,,. .. ..  .. ............   ...                              ..  ,, .        .                      ,+..                ........     .....  .. .. .......    
                                                                                                                                                                                                                                            ......  ...        . ...  .... .. . ...... ...... ...  ..   .....                           ..   ,, .        ..                    .,,.                   ...         ......... ..........   
                                                                                                                                                                                                                                            ...,..  ..      .    . ....  . . ..... ...... ...  . .... .  .. ..                         ..    ,,..         .                   .,+,                                ...,.....   ........   
.                                                                                                                                                                                                                                           . .,. ...        . ...    .   .......... .... ...  .....  .  .. .                         ..     ,,.          ...                 ,+,.                                      .     . .  ... ..
.                                                                                                                                                                                                                                            ......  ..      .........   ... ..   ....   ....  ...   ..... ..                       ...      ,+.     .    ...                .+,.                                                  ......
,                                                                                                                                                                                                                                             ......  .. .   . ..........           ........   ...   ........                      .        .,,.     .    . .                ,+.                                               ..... ....
,                                                                                                                                                                                                                                             ..,....  . .. .. ....         .....  ......    ... . . ..... .                      ..       ..,,           . .               .+,                                              ...........,
,                                                                                                                                                                                                                                              ......   ...,..  ....         .. ..    ...   .. .... .... . .                     ..        ..,,         . . .               ,+,                                              .......,,..,
,                                                                                                                                       . .                                                                                                     .,. ..........   . .   .   ....... ......  .....  ......  .                     .         ...,,                             ,+.                                            ...........  ,
,                                                                                                                                       ...                                                                                                     .,......,,. ....   . ..   .. . . .  . ...  ...    ......                       ..         ...,.  .  .      .               .+,                                             ............ ,
,                                                                                                                                       . .                                                                                                     ..,..,,,,,,....... ....  .  .   .... ...    . .   ....  .                     .       .......,.     .     ..               ,+,                                            ..............,
,                                                                                                                                       . ..                                                                                                     ........ ... ..... ....   . .  ...   ... . .. . . ... ...                   .        ..... .,,     .    .. .              ,+.                                             ......    . .,
,                                                                                                                                     .......                                                                                                      ...  . ..... .....,..    ...... ...... ..   . ..... ...                  .        ......,,,,     .    .. .    .        .+,                                              .....,,,,,,,.,
,                                                                                                                                    ....  ...                                                                                                          ....... .......... . ..... . ...  ..  .  ..... ...                 ..        ...  .,,..     .     ..              ,+,                                     ...     ...,..,,,,,,,,,
.                                                                                                                                   .. . .  ....                                                                                                           ........  ... . . ..... ... .  . ..   ...... .                 ..         ... ... ..     .     .              .++.                   ...       .............  ....,,,......,,,
.                                                                                                                                 ... ..    .....                                                                                                           ...... ........  .,,.. ...    .. ..........                  .  ....      .........     .     ..             ,+,              ...      .              ...     ..,,,..     ...
.                                                                                                                                 .. ....  .   ...                                                                                                           .,....,.......  ,......... ...............                 .  .,,,,..... .........     .     ..             ,+.        ..........                                          .
.                                                                                                                                .. . . ..      ....                                                                                                         ,,...,,,,,.... .,.,. .......,,............              .... .......,,,,.........      .    ..             .,,.   ...........  ..                                          .
.                                                                                                                              .....     .    .  ..,.                                                                                                        .,...,..,,,,   ........ .. .. ........ ..              .,,.  ..........,..,,. ....    ..    ..             .,,,,,.......       ..                               ...        .
.                                                                                                                            .......     .       . .,.                                                                                                       .,,,,,.  .,,  .,,...,...................               ,,,, .,...........,,..,,,,.    ..    .              ,.......            ..                              .,,. .      .
.                                                                                                                           .... .       .    .     ...                                                                                                       ..,..    ,,  .,..,.... ......,...                     ,,+, .,...... . ...,,+,,,,.    ..   ..             .,......             ..                             .,.,.        .
.                                                                                                                          .,. .    .  . .   .   .   ....                                                                                                              ,,  ,,. ..........,,.                       ..,,. ...... ... ,,,,,,..+,     ..  ..              ........             ..                             ,,.,.        .
.                                                                                                                         .,.. ............  .   .     ..                                                                                                              ,,  ,,.     .........                      .       ...  .. .,,,,.....+,      .  ..             .,,,,,....            ..           .                .,.,.         .
..                                                                                                                        ...,...........................                                                                                                              ,,  ,,.       ......                      .      ..,......,,,,......,+.     ..  ..             .++,,,.......         ..           .   .           .,.,..         .
...                                                                                                                       ..,...............,,,,,,,,,,..                                                                                                               ,, .,,                                   .       .,,,....,,,,.......,+      .   ..             ,+..   ....,,.       ...            .  .          .,.,,.          .
...............                                                                                                           ....           ...............                                                                                                               ,,..,,                                  .        ,,.. ...,,... .. ..,,  .   .   ..             ,+..  ......,.    ......  . .      . ....         ...,.           .
.  ...         ................                                                                                    ......  ...                        ..                                      ..                                                                     ..,,,.,,               . ....            .         .,,,. ...,... .  ..,,      .  ..             .,,... .. .. .,........    ...        ....        .....     . ...  .
.. .   . ...  .   ........................                                                                        ........ ...                        ..                          ...                                        ..                                    .....,,,,,                 .....                     ..,,,,.,,...  .....,,     ..  ..  .          ,+, .. .. .. ..........     .......... . ...     ...... ............
 ..    .. ......                 ..    ..                 ...    ..                                               ..    .. ...                        ..   ....                    ....        ......         .            ......                        ... . . ..... . .....  .        .    .   ..                  ... ..,,,,....  .....,.     .   ..  .          ,,. ....   .. .,..  ...     ..............      ..  .. .,,,...,,,.. 
      .  ..  .        ...         ..  ...     ..    ..   ...... ......                                            .     ......                        ..  .......                 . . ..       .... ..      .....          .......,,,.  ..,,,,,,,,,,,,. .. .. .. .......      ........  ...   .     ..         . .    ... ....,,...   ...,,,      .   ..  .          ,+.        ....,.......     ........ ....       ..  ... ,,...,,.....
.      .   ......     .......                  .   ...........  .  ....               ..                       ....      .. ..                        ..  .    ..                       .      ....  ..    ..   ..        ..,,..... ... ...    ..,,..... . ..... .. ...      .......  . . ... .      ..       . .       ........ ..      .,,      .   ..             ,+.         .. .... ..       .. .,.. . ..        ..  ...,, .., ,,.. 
..........   .     . .. ......             ..    ... .  . . .  .....  .                 ...........           ....       .. ..                        .. ..    .......                  ..          ....  ..     ..     . .......,,,,.. ..,,,,,,,..,,,,.... ..,,,.....       .....   .  .....         ....     ..       ...........      .+,      .   ..            .,,..,,,..   .  .. ...         .... .  ..          .   ...,... .,..  
 .   ... . ......   .  .                   .... .   .. .  ...  ..   ...  ...                     ..............          .. ..                        .. ..    ........                ..            ,.....      .... ..        ...........    .,,,,... . ..,,,......         .....  . ......          ....    .        ...........      ,+,      .   .             .,.....,,.     .... ..         .....  ...          ..   ......,,..   
 .. ........    ........    .    .    .  . ......   .............  ..........                           .......          .. ..             ....          .     ....   .               ..             .,,.           ...                 .       .,..    ...., ....        ... . .    ..... ..            ..  ..         ...... ..        ,+..    ..  ..             ,, .  ..,.    .... ..           ........            ..   ....,,..    
......  . .       ....  ....  ..       .....................   . .................              .....  .     ..          .. .....     ....... ... .               .   ..                        .     ...                                 ..     ...   ..........             .      ........           . ....            .,...,.        ++      .   ..             ,,. ....,.. .... ...             ......              ...........     
.. ...  .   ....      .         ...    .    ... .. .. .    . ..  ......,.    .......           ....... . ......          .. ..              .......             .. .... .   .. .        ...     .      .                                                 ......     ..         .     .......                              ......        .+,      .   .              ,. ............  ...                                   ....  ...    .
.                                  . ...... ....     ..  ..  .... .......     ......... ...    ..   .... ......          ..  .        ...   ...... .            ..    ..   ... .     ....   .  .                                                          .....    ...          .    ....                                             ...,,      .  ..             .,.... .... ...   ...                                      .....     .
.          .                           ....  .....  ...........  . .....    .....    ........ ..    ....... ..           ..             .........              ...         ...  ...,............                                                           ....   ....            . .       .......               ......             ....,.    .    ..             .,... ....... .  . ........                                ....      .
.....   ...     .      .........   .        .... .. . .. . .......   ...   ..       .  .   .....   . ......              ..              ...,...     ...      ...           ... ..,,.........,..                                                            ..    . ..            . .    . .........            .....               ....,,..   ..   ..             .,.....  . . .... . ........                               . ..     ..
,..................            .........    .. ...... .  .   ..      ...   ..       .       .... . .. ...                ...             ..,....     ....   . ..                .,.     ........                                                            .    . ..             ... .........   ...          ... ..               .. .,,. .  ..  ..              ,,..... . ..     . ......  ..                     ...     .. ..    .+,
,....... ....... .....           .. .. .. .. .  .. ....  .. ...      ..   ..         ..           . . .,,..             .. .   ...,..     ..,..      ....   . ..               .,.       ......            ..                                               .    . .              ....... .........  .        .......              .....,,. . . .  ..              ,,.   .. . ...... ........  .................     .. .     . .....,++,
+.  . .... .       ...   .                   .      ... . .   ... .....   .          ..           ...,,,.... .        ......   .. ...        . ... .,,.,,     ..               .,.          ..               .                                              .     ..              . ... .....   ..   ..       ... ..              ...  .,,      .  ..             .,,.  . .. ....   .. ... ..   ...,..,,..,,.....................,,,,+,.+
+....... ..       ...   .      ..    .  ......        .....    .. .....  ..           .            .,,,......        ... ..    ...,..          ..  ....,.     ..             .... .         ..               .                                              .   . .             ..,........,,..       .      .......        .    ... ...,,     .. ..              ,,. ........     ...     ...  ...................,..,..,,.......,,,,. ,
+..........................   ..    .  ...  ..          ... .       ..   .             .          ..,. .....          ....        ...           ....,,,,.     ..             ...  .     ......                                                                 . ..           ..,,..........,,.     ....     .. ...         .. .... ...,,.     .  ..              ,,.  . . .....  ...   . .... .......  .. .. . ...... .. ...........   +
+........  ........,............. .. . ..... ..                     ..                 .......    . ,,. .....         ...                             .         ..... . ... ... . ..  ....... .              .                                     ..,,,.....   ..           ..,...... ...  ....       ..  .......        .  ..,,....  ,,      .  ..              ,, ... .....    ...  .  . ........  .   ...  ..... .... .....,....    +
+      .......            ..    ........   ........             ... ..               ..........    .,,...,,....                                        .,,,,,,,,....  .  ...,, .   ......... ......          .                                    .,,,,,,..... .             .,..... . ..  ......      ...........            .,.. .  .,,         .               ,,..........   ...      .  . .... ..   ......................,...     +
+       ....                   . ...   . .......                ....               .............  .,,.  ..,,..,.                                    ..,,,,,,,,,,,,,.     .,.....    .....   .......          .                                   .,,.  ...... ..             .,....        ...  ..       .... .  .            .... .  ,,.        ..            .  ,,.....       ..... .  ..  . .... . .    . ..            . .,,.       +
+                             ..  .. . . ....... .             ...                 ......    ... .,,.     ....,.                                  ..,,,,.  .    .,,,,.   ,,   ..  . ..     ... .  ..                                            .,..     .... .            ..,...   .     .... ......  ........  .          . .. . .  ,,   .     ..               ,,. ....       .. . ....   ....... .. ..........  ........,,,         +
+                                  .....  ........          .....                 ....      ....,,,.       ...,,,.                               .,,,..      .... .,,,.  .,   .. .....     .....  ..           ..                              .,..      ..  ..           .,,,...       ...,. .....,..  .........              .....  ,,         ..   .          .,.. ....     ..  .. ....   ........  . .. . ... . .... ...,..         +
+                                   ...     .........   .......                   ...        ..,,,.        ....,,,,.....,......    ..............,,...  . .  .  .   .,,.....   . . .. .   ... .   ..            ...              .....  ..... .,..       ......          .,......   .  ...,.  ........   . ..                  . ..   ,,       . .               ,,.....,.    ...... .....   .  ...  .. ..........  ... .....           +
+                            .................. ..  ......  ......,,..           ...        ..,,,             ...,,,,,,,,,,,,..................,,,. .  . .           .,,,,..  ..  .....   ... .   ..........,,,,,,,,,,,,,,,,.,,,...................,,,.. ......          .,...      .  ....   .. .. ...  . ..                 .      .,,    .  ...               ,, ......   ...   ...  .      ....  .  ..........  ....,...            +
+                          ...................            ............           ...        .....             ..  .... .............   ........,..                    .,,... ..   .....  .... ...  ........,,,,,,,,,,,,,,,,,,,.....................,,,,,. . ...      ....,...      ..  ... ..... .....,.   ..               . ..    .,+.    .  ...               ,,    ...   ...........        . ..   ..... .............              +
+                          ..     ...      . .     .......       .,,..          ....      ... ..              ..    ....  ........     .........                       ....... .... ..  ..,,..,,,............           .......  .. ......... .....   .,. .....    .,,,,.....      ............. . . .,,.  ..              ..  .  ...,,.      . .            .   ,,...  ..  . ..........          ..  .. ..  ...  .....,.               +
+                         ..        .                    ...        .   ...........   .   .   ..               .. . . .. ... ..  .    ..,..  ..      ....               ... ............,,,....,,,,,........             .  ...  .. ... .. .....     ..,.   .,.  .,,,,......       ..... ..   .... ....,,. ..             ..      ...,,      ....           . . ..,.  ... .. ..    .   .          .. .. .... ..  .  .,,.                +
+                         ..      ..               ..   ..              ... .....            ..                .. .    . ...  .      .... .  ..     ... ..              ..... ..... ...,,..  .... .... .               .    ... ... ..........       .,.    ... .,,. .. ...        ... . .   .... ...  .,,...             .   ... ...,,     .. ..          .  ....,. ... .....      .   ..       ... ......        .,.                  +
+.                        .                            ......   ...          ...           ....                 ...    . .. . .      .. . . ..     ... ..               .... .. ..  ..,,. ....  ...  ..                . .  ..  ..   .......         ..     ... .,...  ...         .............. .   . ..,,.          .. .   ..  . .,.     .  ...         ..  ..... .....  ...         ...      ..  .......    ...,..                  +
+. .                     ...                                                 ..          .....                  .....  ...... . ..   .. ... ..        ..                 .....  ... ..,.          .          .              ..  .. . .. ...          ..     ...,,..  ....           ...  ......     ..    .,,....         ...  .  ...,.        . .        .     ...       .....       .. .       .. .  . ...    ..,..                   +
+.......,,,,,,,,,,,,,,,,,,.......................,,,,,..                 .  ...         .....                     ..... .. . ....  . ..  ....      . ....                ..  .  .......                ..  ..        .          .......              ,.     ...,,......              .......        .      .,,......      . .       ,,.     . .. . .    ..      .....    ......        ..        ..  ...........,,..                    +
+....,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,.,,,,,,,,,,,....,.                 .....       ......                         ...... .   ..    ..     .       ...                  ... .   ....,.              ....            .         .    ...              ..     ...,.....                .......                .,,. ...          ..   .,,.     . .         . ...   .. .. ..... .          .. .       .    .. ..  .,,..                     +
+............... ................................     ,,                .....       ......                             ..      ..    ......  ..     .                    .....    .....              ....        .  .            .....    ..         .,    ...... .                  ......                  .,,,....         . . .,,.      . ..       .. ..    ........ ....          .  .       . .... ... .,,.                       +
+...,,   .. . .. . .        .. ...    ....   ....     ,,               ....        ...  ..                             ...    ..      ...... ..                           ...     .....              ....         ..          ......     ....        ..     ..   ..                    .. .                   .,,, ....    .  . . .,,       . ..        .. .   ... .     ...          ..          ... . .. ..,..                        +
+  ,,..  ...  .. .. ...  .. . ....     .     .. .     ,,              ....         .    ..                             ... .  ..         ......           .            .  ..       ..                  .          .          ......  ........        ..   .... ....                     ... . ....             .,,  ....  ... .  ..,,       . .          .... .....     ...           ..           .  . . ..,..                         +
+  ,, .  .......... ......  . .  .    ...... .. .     ,.              ...               ..                             ...  ...    .  ........                         . .......   ..                  ....                . .....  ........ ..      ....... . .                           .. . ..              ,,    ..... ...  ..,..      ...          .... .......  ...            ...      ..  . ..  .,,.                           +
+  ,, ..... ..  ....  .  .... .  ....  .......       .,.              ...........  ..   ..                .          . ...  .. ..    .. .. ..                          .  ....... ..                   ....   ..          ......   .,,,,,...... .    .....  .. .                           ... ..               ,,     ...........,,.        ..          .    .,. ... ....             ....   ..   .    .,..                            +
+  ,, . . .     . . ..   .... .  ..     ......       .,.            ............. ...   ..                   ............   .....   ........           ...             .....  ......                  ..... . .....  ..   ..  ...,,,,.....,,,,...    .,....    .                            .....               ,,      ..,.......,,         .                .,...  ....                 .   ..   .   ...      .                       +
+  ,,.  .   .  ...................   ..... ..        .,.          .......   . ......    ....              .........,,...  .......   ........                            ..... ....                      ....     .. ... ... ....,,,. .......,,,,,. . ,,. .     .                             ..                 ,,        .,,,,,,,,.         .               . .........             .   ..  ..    . ....                               +
+ .,,. .... ... ......   ..........  ....  .. .   . ..,.       ..,,... .  . .   ...     .....          .....  .........   ......   .........                          .   . . ....                   ........  .... .  ... ..  ...  ... ...  ...,,   ,,...     .                                                ,,         . ....,+.         .               ...,..   ..            .. . .  ..     .....                                +
+..,...........          .  ...  ............ .   . ..,.     ..,,,..    .....     .     .....         .... ...  ....... ................  ..                              ......                     ........................... ...........    ,,  .,,.       .                                                ,,       ..,,.. .++          .     .         ..,,  . .. .   ....   ..    .....    .,,..                                 +
+..,. .........          .   .    .... ........     ..,.    .,,...  ...    ..           .....   .     ....... . ..  .. ... ........,,.. ....                              ...  ..                   ..... ........    ...... ........... ...... ,,.  ,, ........                                                ,,      .,,,.,,..+,       . .     ..      .  ..,. ..       .. .. ....            .,,.                                   +
+,...  .   ....          .    .  ... ..  ....   .    .,.    .,.       .                 ..... ...... ....... .. ......  ..............  ....                             . ......                  ....  .....,.........  .............  ........,,..,,.  .. . .                                                ,,      ..,,,...,+.      .  .       .     .  .,,. .   .    .  .    .            .,,.                                    +
,,... ..    .                   ...  . .... .. ..    ..,. ..,..  .              ..  ..................  ..   .  .... ... ..........  ..  ..                               .......                 .... .... .........    .. ....... . . .......  .,,,,, ...... .                                                ,,.     ..,,,,,.,+.      .          .        .,,... ....  .             .      .,,..                                    +
,,... .     .                    .. ......  ..... ..  .,,..,..                .. .   ..,,...    ...     .. ...  .  .. ......,.....   ..  .                                 .....  .                ..  ..  . ..... . ......... ... .  .... ....   .,,,....     .                                                ,,.             ++.  .  ..                   .,,... .. .               .      .,,.                                      +
+.,,. . ... .               .    .......    ..... ..   .,.,.                   ....  .. ...    .  ...... . ..  .   ..........,.....   . ..                                .. ,..    .             ..  ..   ........ ...... ..  .....   .........    ......    ..                                                .,,            .+,.  .  .. .                 .,,.. .. .                      .,,.                                       +
+ ,,. ........              ..   ....      .. ... ..    ....                   ...   ... .......  .. .        ..   .   ..... .... ...   .                               .. ..,.   ...          .  ..  . .... .. ......   ...   ...  .. ..... ....    . ..     ..                                                .,,            .+,   .  .. .              .   ,,. ....   .                 .,,,.                                        +
+ ,,..... .. ....   ..    ........ ...     .. ... ..      . .                   ..    .....       .. ..      ..      ..  .....,..  ....                                ... .... .  .           .  . ... .   .. .....    ....  ....  .............    ....     ..                                                .,, .....      ,+,   .  . ..              .  .,,. .                      .,,,..                 .                       +
+.,,.     .............   ........  ..  ...........       .                           ...  ...    ..        ..          ......,..  ...                 .               .......  . .           ..   ..  ..   . .. .........   .....  ... . .. .....     ..     ..                                                 ,,.........   ,+,      . .         . .   .  .,,   .      .              ,,,.                                           +
+.,,            .  ....    .             ...  .....  . ..        . ..                             .         .         ......,.,...  ..                     ..            .....  ...  .        .    . ...  .... ..........    .... .  .    .........    ....   ..                                                 ,,,,.. ...... ,+.      . .           . .    .,,      .                  ,,                                             +
+ ,,.    .......   ....   .........      .    ... .  . .      .     ..               ....      .. .         .         .. . .,.,.. . ..                    ...            .....          .    .      ...  .  . ....... .     ... ..       . ...   ... .   ...  ..                                                 ,,,...   .....,+.        .          .   .   .,, .   .. ..             ..,,                                             +
+ .,...      . .    ..     .. .   .       .   .....  ...     ....    .              .   . .               . .     .  ..    .., ,.. . ..                  ....            ....               ..     ..   .. .........   .   ... ......   .. .. . ...  .    ... .                                                  ,,....     ...,,        ..                  .,,.    ....              .,,.                                             +
+..,...       ..            ...           .   ...     .      .       ..              ......                 .   .   ..      ....,..                       ..    ..       .,,..             ...    . .     ........... .   ... .. ....  ..  . .. ....        . ..                                                 .,....       .,,   .    .          ..       ,,..   .. ..             .,,.                                              ,
,.... .                   ...             .  ..       .      .                         .....            .. .. ...  ...     .......... .                        ..        .,,.   .  ..     ... .     .    .....  ......    .. .....       ...     ...        .....                                                 ...,.........,.   .                 ..     ,,..  .. ..             .,,.                                .              ,
,,,....       ..          .     ....      . . ..     ..     .                           . ..        .  ... .      ...    .... .........                       ..         .,..            ...        .  ......   ...... ..... .. ...    ......   ....    ... .....                                                  ...,,,.....,,.             .             .,,   .. ..            . .,,                                               .,
 .....      ......     ....  ..... .      .  ..     ..                               ..  .  ...    ..   .       .....   ....  .   .....                   ..             ....   .       ... ..      . ......    .....  ....   . .     .  . ..  ...        .......                                           .......   ..........        .                   .,,   . ..           .   ,,.                                              .,,
. ...     .........  .        ..    .   . .  ..                                        . .......  ..   ..  .      ..  ....   ..   .....                                 ......         ... ..        .......  ......  ....     .     .    ...  ..          .... .                                  ..... .......          ... ..      . .                .  ,,...  ..    .       .  .,,                                              .,,.
.....      ..        .  .           ...    . .. ..                          ...      ..  . ...  ..  .. ...    .      ....  ....    ... ..       .  .                    . ..           ..  ..       ........... ... .....       .   .  . ...   ....       ..  . .                               ..........                    ..                         .  ,,......       .........,,.          .                                 .,,,. 
.,,,............  ............     .  ..   . ....                           ... ..    .  . ... ...   ....    ..      ... .....   ....   ..     .....                 .... ...   .     ......      ....  ..     ... ...          .  .  .....  ..   ..      . ... .                             ....                            .                          .  .,....... .      ..  ...+,.          .                                .,,,.. 
.,,,,.,,,,,,,,..................  ..   .. ......       .                   .    ..   ..    .. ...           ..     .... ...............  .......     ....            ...  ..          ......     ....        ....,....    . .   .   ... ...      ..         ..  ..                        ......        ..   .               ..                    .    ..  ........       ....   .+,.                                           .,,.....
............ ........   . .....        .....  ..   ..........  ..............   .......     .............  ...     ... ..............     ..           .....       ...    ..          .....     ....        ....., ..     .      . ... ....      ..         ... ..                        ....                ..           ....                ..       .   ....,,,.       .  ....,+.                                           .,,.... .
.       ....     ...      .....             .. ...........     ..........         ....     .....  ....... ...      ........   ......        ...              . ......     ., .   . .. ..............      ......,, ..  . . ..      ..  ....     ...             ..  ...                 ...                   .                                         .   ......,,,,,..... .. .,+,                                           .,,. ..  .
.    ....    ...            ...            . .  ..                                   ..........      ........... .........                 ..  ..             .. .. .................................   ....... ,,..  ........ .  ...   ..    ....             . .                     ...                                                              ..   ......,,,,,,,,,,.. ,,,.                                          ......    .
.       .....   .            ..            ...    ...                                 .....    ....        .  ..........   .....,,,,......,,...........,,,,,..........,,,,...    . .... .....  .....   ..  .. . ,,.  ....... ..   ..   ...      .             .                       ..                                                                .    .   .......,,..,,,,+,       ..                                 .... ........
.............      ...     ...,,,,,,,,,,,,....,,..................,,,...,,.,,..,,...,,,,,,,,,,,,,,,,,,,,,,,,,.............,,,,,,,,,,,,..............,,,,,.................................,,,.........    .....,,.    ........ ............. ..........       ..................................,.....                    .                        . .  .     ............ ....,,.                                          ...     ....,
,      .. .........          .,,,,,,,,,,,,,,,,,,,,,..................,..................,,,,,......................,,,,,,... ..... ......                   .......................              ......     ......      ...     ....,,,,.....   .    ..... ......    .   ....         ...............,..                 ..                          . .   .  ........   ........                                         ....          ,
,...............  .....     .                   ..........   ..                          ...  ...      ....  .   .  ...      ...    ......            ..   .......................     ..........              .     ............................. .. ...            .......                    ... ...,.               ..                           .     .        ............          .                               ...           ,
,............  ................       .....          .....   .       ..            ....... ..   . ...........       ..     .......      ....     ..        .. ......  ...     .........    .....     ..                ...  .............   ...  ... ..     ....  .  .... ...... ................ .... .,.             ..                            .     .     ....  .. .....                                          ...            ,
,.     ..........     .........................     ......                          .        ......  ...  ...      .... ...   ..    .......                ..    ..... ...  .    .........                                           ... ..........  ........,,.. ..  ........     .................  . .,.            .                           ...          ...   .     ..                                          ...             ,
,  .........      .........                           .. .                          ....  ........   .    ....   .. ...........   .......  .               ... ..  ............     ......                                            .... .. ......     .............   .. ...  ............. .       ..,........     .               .   .       ......      .....   ...                                             ...              ,
,    ..        .........                      .           ...     ...........    .  .....          ...  ......  .........        ......  .      ..         ...    . .............     ....                                            ..... .   ........ . ........ ...   . . ...............  . . .     ................              .   .    .. .....  ..   . .. . .....                                            ...              ,
,.    ..     ........                         ....             ........  ..       ..    ........    .........    ......        .....  . ..                 .....  .. .....   .............                ...                          ......  ......... . .... ...   ... ....... .    . .. ...          ..           ....  .              .    .   ....   .. ... . .......                                           ...               ,
,           .......                        ......      ...    ....... ..........  ...   .......     ........     .....       ......   ...       ..          ..... ..            ..........                   ....                       ...... ...  .... .  .   .... .    ....  ... .  ....  ..           ..        ... ...                     .   . .  . ...... .........                                          ...                ,
,     ........                         .........         ..  .... ................. .      .   ...........        ....       .....           ......           ... ..       .     ......                  .      ..                        ....  ........       ..   ...  . ...  ....    . .. ..           ..             ..                       . . .  . ....... .   ..                                            ..                 ,
,    .. ..                            ........    ...      .....  ..........   .  ......       ....                   ..     ....     ..     .. ...             ....       .                                 .....                          ...    .......     ..   .. ..  ...  .... .      ..            ...   ... ..   ...   .                  . .         .....                                                 ...                 ,
,   ..                              .....              ........   ...      ....    .....                           . .................................          ...       .......    .                       ......    ....                 ...   .  ...       ..... .... ......   .        ..            ...   .................    ..                .  ...  ... ...                                             ...                  ,
,                                ......              .........        .........      ...  ..         ..       ... ..............     .     ........ ..     .....       ..................        .                              .......   .......  .    ..      .....   . ... ...     ..                  . ..  ..      . ...  .     ...                    ....... ..                                             ..                   ,
,                               .....    ..  .  ..   ..  . .     ...............  ...............................    .               .         .                     .        ....                                                          ..... ........         ................        .     ...      ....     ............      ...        ..           .. ..  ..                                             ...                  ,
,..............    ..    ................................... .  .........................                                                     ..                                                                                  .          .... ...     .         ..............  .      .               ...               .     . ...       ....      .     .  ...                                               ....                ,
,........... .  ...  .....   ......  ....            .  . .....      .....             ....     .....                                         .      ..                                                  .                        .          ..   ...   ..              ..  ....                           ...                     ..  .       . ....                                                                ....               ,
,   ..                        ....           ..................  .   ..   ....     .....                                                            ..                                                                            .          ..   ...   ..             ...       . ....                    ...                       .......   . .... ....                                                             ...              ,
,   .  .                     .....       .      ...  ..........   .        ...     ...                                                             ..  .                                                                          ..          .  ...   ..               ..      .....    .                  ..                         . ...   .       ...                                                         .    ....            ,
,......                      .....              .... .. .        ..       .       .. .                                                            ..   ..                 ...                                                     ...           .     ... .. ...         .      ....  ...                   ..                            .     ...    ..                                                                 ..            ,
,..                        . .....             ..... ..          .       .. . ..  ..                                                             ..     .                .. ..                                                                       ...  .. .. .         ...  ...     .                    ..                                        .      .                                                  .....      ...          ,
,                          .... .            .....   .           ..         . .. ..                                                             ...     .               ... ..                                                                       ..   . .. ..              ..   .  .                   ...  .       .. .                  .     . .     ..                                                              ...         ,
,                           ...        .    .....                     .   ... . .                                                              ...  .   .               ..  ..                                                                       ..     . ..            .....   .. .                   ...            .. . .                     .      .                                             .                  ...        ,
,                           ...            ......     .    . .           .....                                                                 ..  ..                    .                                                                           ..       .            ......   ..         .           ...           .   .. ..                         .                                              .                    ...      ,
,                           ...           ......           . .         ...   .                                                                    .                     .                                                                            ..      .            .... ..  ...                    ...,           .     .. .                       ..                                             .                      ...     ,
,                          ...           .....               .         ..    .                                                                                          .                                                    .                       ..     .            ........  ..         .  .       ....,..    .          .             ..          ..                                             .                        ....   ,
,                         ...           ....               .          ..    ..                                                                  ..                  .                                                             .                 ..                   . ..... ..                     ..  .,..    .            .          . .         ..                                                                .       ....  ,
,                         ...           ....                         ...                                                                        .            ....  .         ..                                                                    ..                    .  ...  .             .      ...   .,.    ..                       ..        ...                                             .                             ... ,
,                        ....         ......                        ...                   .                                                             ......... ..   ..    .                                                                     ..                      ... ...  ...       .      ...   ..,.                                       ..                                             .                               ...+
,                     .......        ......                  .     ...                   .                                                             ..  ....  ...  ..... ..                                                                     .  ..                   ..       .     ...       ...   ...,.   ...                             .                                                  .                                 .+
+    .                . .. ..      ........                       ....                 .        .                                                      .    ..  ...   ..   ...                                                                    . ..                     ..      .. .  ...       ...    . .,.......   .                                                                           .                                   +
+                     .......     ..... .                         ..    .             ..       ..                                                     .    ..   .. . ..    ..                                                                                              ...   ...  . ...        ... . .. .,, ...     .                         ..                                                                                    +
+    .                ...  .    ......                          .. ..  ..            ..        .                           .                              ..       ....    ..                                                                                             ... . ...   ....         ..  . .  .,..                                 ..                                                                                     +
+                    ....       ......                        ....... ..             .                                     .                              .    .    .     ..                                                                                               .    ..    ...          .. . .  ..,.               .                                                                                                         +
+                  ......      ...                            ...... ...            .                                                                ...  .              ...                                                                                               ..        ..            .  . . .....                                               .                                                                         +
+                .... ..     ....                            ..........    ....                                                                      ... ..              ..                                                                                                ..       ..             ...    .....        .  ...                                                                                                           +
+               ...   .     ....                            .... . ...     ....                                                         ..          ......               ..                                                                                                .    ... ..            . .     .. ..  .    . ... ..                      .                                                                                   +
+            .              ....                          .....    .       .  .                                                         ...   .    .... .                ..   .                                                                                               ... ....                .   .. ..    .    .    .                                                                                                          +
+           ..            .....                           .... ....        .. .                                                        ...        ...       .           ....                                                                                                 ... .....                   .. ..,  .      ....                                                                                                            +
+           ..            ....                          .....         .........                                                        ..         ..     ...           .  ..                                                                                                .... .....                   .  ..,.                                              .                                                            .    .....  .,
,           ..            ...                           .....        .    . .                                                    .    ...        ...     ..  .         ....                                                                                                ....  .....                      ...                                                                                               ..           .  ..........,
,           ..       ..   ..                           ....                                                                      .    ..         ..    ...   ..     .  ..                                       .                                                          ...........                       .. .....                                                                                                      .     ...    ,
,            .            ..                          ....                                                                      ..   ..  .      ..    ...      ..      .                                                                                                   ..  ......                        ..                                                                                          ..               ..     .      ,
,                        ..                         ......                              .                                                      .     ...       ..                                             .                                                            ..     ...                        ..  ....   .                                                                    .         .                  ..     .      ,
,                                                  .....                               .                                               .       .   ...                                                                                                                   .  ........                         ..  ..      .         ....                                   .  ..              .           .                ..      .     ,
,                           .                      ....               .               .                                         ..    ..          ...                                                                                                                        ......                          ..   .                 .                                              .                                      ..............,
+                          ..                      ...                .                                                         .    .          ...    .                                                                                                                                                     ..    .   .. .        ..                                                   .                            .....  ............+
+                    ..  . .                      ...                 .                       .                                .  ....               ...                                                                                                                                                     ..         .           . .                                                                           .........        .    +
+                   ..  ..                         ..    ..       .  ..                     ...                                      .              ...  .                                              .                                                                                                    ...      .....                                                                  ....                 .                     +
+                 .... . .                         ..    .          ...                    ..                                      .                ..   ..                                             .                                                                                                    ...     ....   .                                                             .........                      . ..           +
+                 .    .                           .    ..          ...                   ..                                       ..             ....   ..                                                                                                                          .                       ...        ..                                                                                         .  ....              +
+                  .   .                          ..    .          ..               ..   .....                          ..  ....  ..             ...   ....                                                                                                                                                  ...      ...                                                                  ......  .                                    +
+                    ..                        . ..           .    ..              ....  .. . ..                        .......  ...      .     ...    ..                                                                                                                                                    ...      ...                                                                       .....                                   +
+                                             .  ...          .   ..              ....  ..   ..                        .....  . ...      ..    ....    .                                                                                                                                                     .,.                                                           .                    .  .       .  ..                        +
+              ...  .                           ...    .     ..   .              ....        ..                       .....  .  ...      .    ....   ....                                                                                                                                                     ,.       .                                                     .                   ....          .                        +
+              ..  .. .                        ...          ..   ..             ....        ....                      ...     ....     ..    .... .  ...                                                                                                                                                      ,.                       .                                   .                  ..             .                          +
,              . ...                          ....          .   ...            ....        ... .                      ..  ..  ...     ...   ....  .  ..                                                                                                                                                       .,                                                                             ....            .   .                      ,
,               ...                         ....                ..            ....         ..  .                     ...     ...     ...   ....     ...                                                                                                                                                 .     .,                                                                              . .            .   .                      ,
,                .                       .....                 ..            ....        ...                               ....    ....   ....     ..                                                                                                                                                         ..                                                                              ..             .   .                      ,
,      .                                  ...            .    ..            ...        .                                 .....     ....  ....     ..                                                                                                                                                          ..                                                                      .        ..               .                       ,
,      .               ..          .    ...                   .             ..         .                                          ...     ..    .... ..                                                                                                                                                       ..                                                                                 ..                                     ,
,          .                       ........   .   .                        ...         ..                                        ...  .. ..       .  .                                                                                                                                                        ..                                                                                                                        ,
,         .                       ... ..                                   ..          ..    ..                       .   . .   ..    .. ..     .....                                                                                                                                                         ..      .                                                                                                                 ,
,           .                     ..  .         .                 .         ............   ...                                 ...      ..     ... .                                                                           ..                                                                             ..                                                                                                                        ,
,   .                            ......    .   ..                                   ...   ...                                 ...       .      ..                                                                                                                                                             ...                                                                     .                                                 ,
,    ... .                       ....         ..                                         ..                             ..   ...   .         ....                                                                                    ..                                                                       ...                                                                                                                       ,
,        .                      ...          .                                          ..                          .       ...   .         ...                                                                                        ..                                           .                         .,.        .                                                                                                              ,
,       ..                     ....         ..                               .         ..                           .       ..   ..        ...                                                                                         ..                                          .                          ...                                                                                                                       ,
,        ..                   ....                                                    ...                       ... .  .    .   ..        ...                                                                                                                                     ..                          ...                                                                                                                       ,
,       ..                    ....                                                    ..                       ..      .        .       ....                                                                                                                                     ..                           ...                                                         .                                                             ,
,      ...            .       ...                                                    ..                       ...               .  .    ....                                                                                                                                            ..                     ..                                                                                                            ..         ,`