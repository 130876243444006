import saints from './saints.json';

console.log("SAINTS:")
console.log(saints);

if (!saints) {
  throw "Saint data failed to format during the build phase. Please notify the site's developer.";
}

const MAP_LAYERS = {
  "normative": {
    type: "mapbox",
    displayName: "al-rijāl",
    initialViewState: {
      longitude: -4.9789,
      latitude: 34.0599,
      zoom: 13.5
    },
    items: saints.filter((o, i) => o.map_layer === "al-rijāl")
  },
  "known_burial_locations": {
    type: "mapbox",
    displayName: "al-waliyāt",
    initialViewState: {
      longitude: -4.9789,
      latitude: 34.0599,
      zoom: 13.5
    },
    items: saints.filter((o, i) => o.map_layer === "al-waliyāt")
  },
  "unknown_burial_locations": {
    type: "image",
    displayName: "al-waliyāt ghayr ma’rūf",
    initialViewState: {
      longitude: -4.9789,
      latitude: 34.0599,
      zoom: 13.5
    },
    items: saints.filter((o, i) => o.map_layer === "al-waliyāt ghayr ma’rūf")
  }
}

console.log("MAP_LAYERS:", MAP_LAYERS);

export default MAP_LAYERS;