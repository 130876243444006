import React from 'react';
import Markdown from './Markdown';
import { Link, useLocation } from "react-router-dom";

function Header({ lang, onSelectLang }) {
  const location = useLocation();

  const navLinks = (
    <p>
      <Link to="/" className={location.pathname === "/" ? "selected" : ""}>
        Home
      </Link>
      <span style={{margin: '0 7px'}}></span>
      <Link to="/about" className={location.pathname.includes("/about") ? "selected" : ""}>
        About
      </Link>
    </p>
  );

  const title = (
    <Markdown>
      Visiting the Female Saints of Fez, *Rawḍ al-Awliyā’*   (in-progress)
    </Markdown>
  );
  
  const titleMobile = (
    <Markdown>
{`**Visiting the Female Saints**${'  '}
**of Fez, *Rawḍ al-Awliyā’*** (in-progress)${'  '}`}
    </Markdown>
  );

  const translationButtons = (
    <p className="header__lang-selectors">
      <button className={["header__lang-selector", "en" === lang && "selected"].join(' ')} onClick={() => onSelectLang("en")}>
        English
      </button>
      <span style={{margin: '0 3px'}}>
        /
      </span>
      <button className={["header__lang-selector", "ar" === lang && "selected"].join(' ')} onClick={() => onSelectLang("ar")}>
        Arabic
      </button>
    </p>
  );

  return(
    <div>
      <div className="header-placeholder"></div>
      <div className="header-wrap__outer">
        <div className="header-wrap__inner">
          {navLinks}
          {title}
          {translationButtons}
        </div>

        <div className="header-wrap__inner mobile">
          <div className="title-wrap">
            {titleMobile}
          </div>

          <div className="nav-wrap">
            {navLinks}
            {translationButtons}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;
