import React from 'react';
import Markdown from './Markdown';
import FooterShim from './FooterShim';

function Saint({
  title,
  title_arabic,
  preview_text,
  preview_text_arabic,
  description,
  description_arabic,
  lang,
  isSelected,
  selected,
  inactive,
  onSelect,
  locationType,
  location
}) {
  let _title = title,
      _previewText = preview_text,
      _description = description;

  if ('ar' === lang) {
    if (title_arabic) _title = title_arabic;
    if (preview_text_arabic) _previewText = preview_text_arabic;
    if (description_arabic) _description = description_arabic;
  }
  
  let locationText = (locationType === "exact")
    ? `📌  [Exact location known ↗](${"https://www.google.com/maps/place/" + location.coordinates[1] + "," + location.coordinates[0]})`
    : (locationType === "approximate")
      ? "❓  Approximate location known"
      : "❌  Location unknown";

  return(
    <div
      className={["saint-wrap", "rtl", isSelected && "selected", selected && "selected", inactive && "inactive"].join(" ")}
      onClick={inactive ? null : onSelect}
    >
      <div style={{display: "flex", alignItems: "flex-start"}}>
        {"en" === lang && <p style={{marginBottom: 0, marginLeft: 8, marginRight: 12}}>{selected ? "📂" : "📁"}</p>}
        {"ar" === lang && <p style={{marginBottom: 0, marginLeft: 12, marginRight: 0}}>{selected ? "📂" : "📁"}</p>}
        <p className="name bold">{_title}</p>
      </div>
      
      {!selected && <Markdown className="preview-text">{_previewText}</Markdown>}
      
      {selected && (
        <>
          <Markdown className="body">
            {`${locationText} \n\n ${_description}`}
          </Markdown>

          <FooterShim />
        </>
      )}
    </div>
  )
}

export default Saint;
