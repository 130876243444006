import React, { useCallback, useRef, useState } from 'react'
import sketches from './sketches'
import fitTextToContainer from '../../helpers/fitTextToContainer';

export default function PlainTextSketch({ style, preStyle, sketch, backgroundImg }) {
  const [fontSize, setFontSize] = useState(10);
  const [lineHeight, setLineHeight] = useState(0.6);

  const updateFontStyles = (node) => {
    if (!node) return;
    let fittedFontSize = fitTextToContainer(sketches[sketch], "monospace", node, fontSize);
    let fittedLineHeightToFontSizeRatio = 6 / 10
    let fittedLineHeight = fittedFontSize * fittedLineHeightToFontSizeRatio;
    setFontSize(fittedFontSize);
    setLineHeight(fittedLineHeight);
  }

  // initialize font size
  const ref = useCallback(node => {
    updateFontStyles(node);
    window.addEventListener('resize', () => updateFontStyles(node));
  });

  return(
    <div className="plain-text-sketch" ref={ref} style={style ? style : {}}>
      { backgroundImg
        ? <img className="background" src={backgroundImg.src} style={backgroundImg.style ? backgroundImg.style : {}}></img>
        : null }
      
      { sketches[sketch]
        ? <pre style={Object.assign({}, {fontSize, lineHeight: `${lineHeight}px`}, preStyle ? preStyle : {})}>
            {sketches[sketch]}
          </pre>
        : "Sketch not found '" + sketch + "'" }
    </div>
  );
}