export default `
                                                                                                                                                                     
                                                                                                                                                                     
                                                                                                                                                                     
                                                                                                                                                                     
                                                                                                                                                                     
                                                                                                                                                                     
                                                                                                                                                                     
                                                                                                                                                                     
.                                      ....                                                                                                                 
..,...                                 .. ..                                                                                                                 
, .....                               ...  ..                                                                                                                
,...  ..                               .   ..                                                                                                                
..  .  ..                           .  .   ..                                                                                                                
.,...  .                          .  ...  .                                                                                                                 
 , ,                             ..  . .  .                                                                                                                 
.,.,,                            ,  . . .,,                                                                                                                 
....,    .                      ..  . . ..,                                                                                                                 
.., ,,   ..                     ,  . .  . ,.                                                                                                                
 ,, ,,. . ..                 ....  ..     ,.                                                                                                                
.   .,. ,. . ... ... .         .,,,..,..     .,                                                                                                                
.    ,, ..  . ,. ...   ... ....,....., ,    . ,                                                                                                                
.    ,,. ..   .,,. . ....,,,,,,,.  ,., ,    . ,                                                                                                                
.    ...  .. . ..  ..,.. ...  .    , , .    . ,                                                                                                                
  . .. ,.  ,        . ...     .., , .    . ,                                                                                                                
  . ., ,. .,       .. ....    . ..,..    . ,                                                                                                                
  . .. .. . ..          ..   . . ....    ...                                                                                                                
 ..  .  . ..            ..   . .. . ..   ..,                                                                                                                
 ..  .. .  .  ..        .   . .   . ..  ..,,                                                                                                                
 ..   . .  . ..... ..   ..  ..... . ..  . ,.                                                                                                                
  ..  , ...  .          ..  .,    . .   . ,.                                                                                                                
  ..  . .    ..         .  ...     .    ..,.                                                                                                                
. . .  .     . .    .   .. ..    . .    ...,.                                                                                                                
..   ...  .  . .    .   .. .     . .   .. .,,                                                                                                                
...       .  ...   .    . .. .   . .  .   .,,                                                                                                                
. .  .. . .   ..  ..    . .. .. . .. .,,,, ,,                                                                                                                
.... ,    . .     ..          . . ... ...,.,,.                                                                                                               
.. ..,  . . .     ..     .    .     ..  .,....                                                                                                               
..  .   .   . .  .      .    .      .   .. ...                                                                                                              
 ..     .     . ..           .    .  ..  ., ,.                                                                                                              
   .  .      ..  ..          .  ...  ...  ,.,.                                                                                                              
 .   .        . ..           .     .. ... ....                                                                                                              
              , ,.         .       ..  ...   .                                                                                                              
 .     .  . . ,...                  ... ..  ...                                                                                                             
 ,..  ..  . . ....         ..  .     ... .   ..                                                                                                             
., .  ... .. . . .             .    .  .. .  ..                                                                                                             
.   .      . . . ..      .       . .    .    ..                                                                                                             
. .. ..     . . .. ..     .               . . ..                                                                                                             
. ... .     ...  ....          .  .  .    . .  .                                                                                                             
.    ...      .    ...     ..     ..  .,,,,. .  ..                                                                                                            
.. ...    . .            .      .... .. ,,,, .. . .                                                                                                            
., .,,.    .            .             . .. .. ,. . ,                                                                                                            
,. ...,,                             ....  ...,.  ...                                                                                                           
,. .  ,,,               .            ..,   ....   . ,                                                                                                           
.. ... ,,.                          .,,.  . ,..     .                                                                                                           
.,. . .,.                          .,.  .  ,,.     ..                                                                                                          
,,.  .,.                     ...  ,,..,.. .,.     ..                                                                                                          
+,.  ,,                      . ,  ,.. ... .,      ..                                                                                                          
,+,..,.                      , ,  ..   ..... ..   ..                                                                                                          
,,,...                       . .            .,..  ..                                                                                                          
, ,                        . .             ,..   .                                     .,,,,,.                                                              
, ,                       ....           ..,..   .                                   .,,  .,,,,,.                                                           
,..                       ....            .,..   .                                ..... . .. ..,,,,,.                                                       
.                         . .             ..,. . ,                               ...      ...    ...,,.                                                     
 .                        .               ,.,  . ,                              ..             ...  ....                                                    
 .                        .               , ,    .                                              . .. ....                                                   
                         .. .             , ,    .                                                .. ...,.                                                  
    .                    . ..             ,.,                                   .               .  .   ...                                                  
    ...  ..,,.           . .             .,,,    ..                          .                         ....                                                 
      .,,,,,,,,,,.       . .             ,,,,   . ,                          .                          .,.                                                 
    ,,,,,,...,,,,,.                  .,,.,.,,   . ,                         .                            .,.                                                
 ..,,..        ..,,,                .. , , ,,   . ..                       .,.......         ..           ,,                                                
 .,..  .,,,....  .,,,,     .        .....,.,.   ...,                       ,.                .. .         .,.                                               
....   ..  .  .    ,,,,.  ....      .   ...,    ., ,                      ...,...,..         .   ..        ,,                                               
,  ,    .  .....    .,,,.   ..         ., ,,     , ,.                    ., .    .       .  ..     .       ,,                                               
.,..,  .,,,,,,,,,,.    .,,.  ..     .   .,.,.     ,.,,                    ,..           ... .               ,,                                               
,,,,, .,..     .,+,,.   .,,.   .    .   ,,,,      ., ,.                   , .    ...           .        .   ,,                                               
.,,   .,. .       .,,,    .,,   .   . . ,, ,..      ,,.,                   , .                               .,                                               
,,    .,            ,,,.   .,.      .   ,.,...      ., ,.                 .. .                       .       .,.                                              
,,    ., ..          ,,,.   ,,,.    .   ..,...      .,.,,                 ..  ..,..    .                .    .,.                                              
,,    ., ..           .,,,.  .,,    .. .. ...        ,, ,.                 ....,,.,,..,..   ...          .   .,.                                              
,,,   .. ..            .,,,   ,,    .  ......        .,,,,.               .,...    .......                   ,+                                               
.,, .  . .               ,,....,,      ..,..          ., ,,.              .,..  ..,,,,,,.                  . ,,                                               
.,.,.. ...               .,, ...,..   .. ,..  ..       ,,,,,,,.           .,, ....   .,,,,.....            . +,                                               
.,,.... ..                .,... .,.. ., ,. ..,...      ,, .,,,,.          ,,.,,.  ..,,. ..,,,.  .         ..,+,                                               
., ... ..          ...    ,,  . .,, .. ,.. .,,,,.     ,,.  .,,,         .,, ,.   ., .,.     .,..         . ,+                                                
,.. . .           ...    ..,  . ., . ,, ,..  ...      ,,,,...,        ..,..,....... ,,  .,,,.   .         +,                                                
 ,,   ..          ...     .,. .. ,.. ,.,,.  .. ..     . .+ ..,.       ,,,. ,.,.. ...,, ,,,.  ,,,         ,+.                                                
 ,,.  ..          . .     ...  , . . ...      ...     . .,, .,,,,,.  .,,   ,..,..,,.., ,, .  . ,.   . .  ,+                                                 
 ,,   .                   ..    . . ..  .     .,.     . ..,. . .,,,. .,.  ,,.   ..,,,. ,,...   . ...     +,                                                 
 ... ..              .    ..     ....  .. ..,,,,          ,, .  ..,,,.,,. ,,       . . ,...    ....,    .,,                                                 
.. . ..             . .    ,. .  ..,.    .,,,..           ,, .     ,,, ,..,,           , . .. ..  ,,    ,,..                                                
   . ,.             ..      . .. . , . .,,..         .    ,, . .     ,.,..+,           ,....     .,.    ,,.                                                 
   . ,.                     ...... .  ,, ....        ..  ..,, . .    ....,+.           ....     .,,     ,,.                                                 
 ....,          . .          ....  . ., ,.,  .           . ,, . .        ,,            ....     ,,      ,,                                                  
  ....          ...,.        ,.. ..  ,,,, ,. .         . . ,, ....       ,,.      ..  ., ..     ,,..    ,,                                                  
  .             ,,  ....     .,...  ,, ,, .  .         . . ,,  ,..        ,,     . .,..,.,,     ..,, .  ,,                                                  
  .. .          ,..  ....    ....   ,,.,.....,         .   .,  , ,        ,,      .. .., ,.      .,.    ,,                                                  
 .,  .          ,. ,,.  ..   .. . .,, ,,.,. .,        .     ,, ,.,.       ,,       ,.  .,,       ,,.    ,,                                                  
.,....          ,, . ..  .  .,,..  ,,.,.  ....        .     ,, ,.,.      .,,        ..          .,.     ,,                                                  
,,. ,.          ,, .  .  .  .,,    ,,,, .. .,               ,. ,..,      .,,.    .,......       ,..     ,,                                                  
,,  ,,          ,,    .  .  .,,    ,.,, , .,,               ,. ., ,.   ... ,,      ..,,,,,.    .,.      ,,                                                  
,.  ,,          ,,   ....,   ,.    , ,. .  ,.               ,.  , .,   . . ,,.   ..     ...   .,..       ,,                                                 
,.  .,....      ,,  ... ..  ,, . . ,.,... .,                ,,  .. ,.  . .. ,,    ..  .,,,.   ,..        ,,.                                                
,.   ..  .      ,.  .,.   .,,..  . ,.,,,..,,                ., .,  .,...  . .,,    ,,.,,..   .,.          ,,.                                               
,, .  . .      .,.  ,,    ,,. .. . ..,,,...,                .,..,   ,,..   . ,,.   .,,.   ...,.            ,,,.                                             
,,   .. . .    .,  ...    ,, .. .  . ,., .,,                .,  ,.  ., .     .,,.        .,,..       .      ,,,,,,.                                         
,,   . ....    ., ...    .,. . ... . . ,. ,.                .,  ,.   , .      .,+,.     .,,.         .       .,,,,,,,,,.                                    
.,.  .   ..    ., .    . ., . ...,.  ,, . ..                ,,  ,,   ,.      .  ,,,,,,,,,,.    .     .        ..  ,,,,,. ...,..                             
 ,,.,.   ..     ..,.  .  ,,.... ., ...,,..  .               ,,  .,   ,.        .  ,,,,,,,.      .    ..      .     .  .,,,,,...,,....                       
 .... .  ..             ., ....,., .,. ... .                ,.  ,,   ,.                  ...... .. . ..       .       ..,..  .  ..       ..                 
 .   .....     .     .  ,. ,,.,,,,. ,    ,..                ,,. ,,   ,.                   ,.    ..   ..                     ... ..            ..            
 ..  . ,..          .  .,  ,  .  .. ,    ,..                ,,  ,,   ,.                   .,.    .                            ....       ..                 
.,. ...,.         .. . ,.  ,     .. ,    .                  ,,  ,,  .,.                .   ...   ..               ..           ..,,.          ..            
,.     .         ..   ,,   .., ...  .    ,.,                ,.  ,,.,,.                     . .    .                .             .,,                        
, ,,, ..         .... ..  . .,.,..,  .  .,,,,              .,.  ...                          .                      .             .,.                       
, , ,...         .....,   . ,,,,  ,  ..,, ..,              ,,    .                           .                      ..             .,                       
, , ...,.           .,,    .,.,,  ,.  ,,... ,,             ,,        .                    .. .                     . ,             .,,                      
, ,..,.,,,.      ......    ., ,,  ,...,,., . ..            ,,                             .. .                       .,             .,.                     
,,., ,. .,,.   .....   .   .,,,.. ,. .,,.,.  .,            ,,                             ..   .                      ,.             ,,                     
., ,,.....,,,,,,,...        ,++,, ,    ,, .  .,           ,,.                             .,. ..                     . ,             .,,                    
 ., .   .  ......   ..  ...,,,...,, .  .,,    .,         ,,.                              ,,...                        ..             .,.                   
  ,.  ... .    .....,......,.    ,,..,. .,.    ..       ,,.                            . .., ..                         ,              ,,                   
  ,,.,   ...,,,,.... .,,..       .,,..,  .,     ,      .,,        .                       .,  .      .                  .,             .,.                  
  .,,  .,,,.. ...  ,,,,,.         .,.,,   ,,    ..  .,,,,     . .  .                      ..  .      ..                  ,.             ,,                  
   ,,  ,,..   .   .,,.              ,,.    ,.    .,,,,,.         .  .                     ... ..     ..                  .,             .,.                 
   ,,  ,, .,,,...,,,,               ,,     .,     ,,,  .         ..                     ... . ..      .                   .,             ,,                 
   ,,. ,,   .......,,               ,+      ., .  .  .             .                 .  ...   ..                    ...    ,.            .,.                
   ,,  ,,          ,,               ,+     .,,,,. ..                                   .  .  . ,.        ..           .    ..             ,,                
   ,,. .,,         ,,               .+,   .++,...  .                                   . ..  . ..         ..         .      ..            .,.               
   ,,.  ,,         ,,                ,, .,+,  . ...                                   .. .    . .          ..                .             ,,               
   .,,  ,,         ,,                ,+,,,,       ..                                  ..,.    . ..          .                ..            .,.              
    ,,   ,.        ,,                 ,,,          .                                 . ., .      .                           .,.            ,,              
    ,,   ,,        ,,                                                               .. ..  .      .                           ,,            .,.             
    ,, ...,        ,,                   .           .                      .          ..   .       .                           ,,           .,,             
    .,..  ,.       ,,                   .           ..   ...                         ..     .       .                          .,.           .,.            
    .,,,.,,,       ,,   .                            .. . ......                    ..      .        .                          ,,            ,,            
    .,,,,, +.      ,,  ..                             .. .                        .,.        .                                  .,.           .,.           
     ,, ,,.,,      ,+ ..  .                            . ..                     .,,.         .                     .             ,,            ,,           
     ., , , +,     ,+,....                             .. ..                  .,,.                      .        .  .             ,,           ,,           
      ,,,.,,,,      ,,...            .                  .. .                 .,.                         .        .  .            .,.           ,,          
     .,,,,,+ +,     ,, ..            .                   ,...              ..,.                .          .           .         .  ,,           ,,          
     .,,,, +,,,    .,, ,.,           .                    , ..             ...                 .           .        .  .           .,            ,.         
     .,,.,.,+ +,   ,+. , ,        .  .                    .. ..            ,                   ..           .           .           ,,           ,,         
      ,, ,, ,,.+,  ,, ,,.,           .                     .. ,.           ,.                   .            .           .          .,            ,,        
      .,.,, ,+,,+,,+. ,,,,           .                      ., ,           ,.                   ..            .          ..       .  ,,           ,,.       
      .,,,,  ,+,,++, .,,,+.                                  ,..           .,    .               .            ..          ..         .,            ,,       
       ,,,,   ,+,   .,,. ,,        .                         .   ...    .. .,  .                 .             ..          .          ,.           ,,       
       ,,.,.   ,+,..,+.  ,,.                                     .,,,, . .  ,  .                  .             ..          .       . .,           .,.      
       ,, ,,    ,++++.    ,,.                                .      .,,. .  ,.  .                 .              ..          .         ,.           ,,      
       ., ,,     .,,.     .++       .                         .....   ...    ,.    .               .              .          .         ,.            ,.     
       .,,,,               ,+,                              . .   ..    .... .,  .  .              .            . ..                   ,.            ,,     
        ,,,,                ,+, .                           . ..,,.        .. ,   . ..                             ..         .        ..            ,,     
        ,,.,                 ,+, .                            .. ....      .. ..  .. ..          ..              .  .                   .            ,,     
        ,,.,                  ,+, ..                          .....        .   ... .  .           .   .           ..                    ..           ,,     
        ,, ,.                  ,,. ...   ......                   ...      .     ....                  .                                ..           ,,     
        ., ,.                  ,,......           ...         ....   .    .       ...                  .              .                 ..           ,.     
        .,.,,                  .,. .,,+,+++,,,,,...     ..   .    ...     .       ....                  .                               ..         .,,      
         ,,,,                  .,, .,.,,,,,,,,,,,,,+,,.       .,,..      .         ....                  .                              ..,,,,,,,,,,,       
         ,,,,                   ,,..,,     .........,,,,..               . .        ...                  .                               .,,,,,,,,..        
         ,,,,                   .,. ,,                ,,....  ...   .    . .        ...                   .             .                 .                 
         .,.,                   .,. ,,                 .,,.. .           . .      .  ..... .              .             .                 .                 
         ., ,.                  .,, ,,                  .,,,, ....       .      . .. .. ..                               .                .                 
          ,..,                  .,, ,,                    .,,, ..,...    . .  . .  . .. ... .              .             .                .                 
          .,.,                  .,. ,,                      .,+,.  .......  .    . .. .. ..  .             .           . ..                                 
          ., ,                  .,, ,,.                      .,+,..     .           . ..  .. .                         . ..                                 
           , ,.                  ,, .,,                        ,, .,,,.                .  ., ..             .             .               ..                `;
