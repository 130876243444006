export default `       +++                                                                                                                                                           ++                        ++|,++++          +++|+                  +|++  ++                                ,+    +,+            
| |                                                                                    ,       +++                                                          ,||++          ++++         +,+ ++|,          ++ ++           +++    ++,| +|||                      +|+     ,|||,  + +            
| |                        ,+,                          ,++                         ++|||+    ++,++                                                 ++     +|+ ,+         +|+,+,    +++ ++|,              |+ ++++        +||||+  ++ | |  |+                    +|,++    ++ ++  | |            
| |          ++,          ++||+                        ,|+|+                      +||+, ,|+   ++ ,+                  +++                 +++,      +|++   ++ ++++         +| ,+    +++++ ,, ++|+          ++|, +|+       ++  ,|+ ++ | |  ++                    ++ +|    |+ ,|  | |            
| |         +|+|          ++ ,|+                       +|  |+                  ,+|+  ,   ++   |+ ++          +++    ++,|+              ,||++|+    +, ++ ++ ,|||+          +|  |    ++ ++    ++ |,         ,++++ +|       +++|+ |+++ | |  |+     ++||++   ,+++  +|  |,   |+ +|  | |            
| |         |+ |+         |+  +|                       ++  ++               ++++  ,+|||+||,   |+++    ,++   ||,|,  ,|  ++              ++ +  |+  +,,|+ ++ ++               +, |+   ++ +|    ++ |+            +| +|       ,+++|+ | +|+ | +|   ,+||++++|+  +++++  |+ |+   |+ ++  | |            
| |         |+ |+    ,,   |+  +|                       ++  |, +++, ++    ++++  ,+|++  +++     ++++   +|++, ++  ++  +|  ++             ,+,|||+ |, + +  +, + ,  ,,          ++  |,   ++ ++   +|+ |,            +| ++            |,++ +  | ++  +|+,     ++  ++ +|  ,| +|   +| |+  | |            
+ +         |+ |,   +|++  |+ ,|+                       ++ ++ ,|,+ ++||+++,  ,+++,             ++,|  ++ +|, ++   +, ,|  ++              + +  |+++  +  + ,+ +++, ,          +|+|+    ++++ +|||+ ++       +++   ,| ++   ++++++   ++ |+   |,|+ +|, ++|+, |+  ,+ ,|   ++ |,  +| |+  | |,           
+ +         ++,|   +| ++  ++ ++                        ++ ++ |,,| ++ ,,  ++++     +|++        ,| |  ++|+,  +|   +,  | +|               + +  ,| +    + ++ |+,|, +     ++    ++,      ++ ++++   +,+,    ||+|+   | |+  +|,+,,+    |,+|   |,++|+ +||+++,++  ,|+ ++   +| |+  +| |+  | |            
+,+,        +| |, ++ +|,  +| |                         ++ |+++,|, +|+  +,,       ||++|+        | +,  +,     +|+++   |+++               |++|, ++,|  +,++ ++  +  +  ++,|++   +++,       ,+      ++|||+ +|   |+  |+++  ,|   ++    ++ |,  |,+++ ++ ,++ ,+   ,++||,   +| |+  +| ++  | |            
+,+,     ,++ | |+ +,|+    +| |      ,+++               ++ + ++|,   ,|+ +|,      +|   ,|   +++  ++|+   ++     +++    +++|              +|  ++ ,| |,++,+  ++++|  + ++| +++  +|++|,       ||+++      +|+++   ++  ++++   ++ ++     ,| |+  |+  ,|, ||+|,      +++,   +|+ |+  ,| +|  | |            
+,+,    ,|+|,++|+ ++,     +| |      ++||+          ,   ++ |  ,   ++  |+ +|      +|    |+ +|+++ ++++  +||+           +| |, + +    +++  +++||+  |++,+ +  +  ++ + + ++++++,  + ,  |,   ++  +++,     ,+|,++   |+  +| |    |,+       | |+ ,|, || ,|,  ,|        ,,++||+ +|    |,+|  +,++           
+,++    ,| ++++++         +|,|      ++, ++       +|++, +| +,    ++|+  || ++     +|+   |,+| +|+ ++++  ++ |+ ,++++    +| |+ + + ,+ + +,  ++,,   +++,,++ |+++,  + +  ++,     ++|+ ++  ++++   ++, +||||+ ++  ++    | |    + +       |,++ |+ +|  ++   ++  ,+  ++|||++  +|,    |,+|  +++|,          
++++   +, ++ |+++  +||+,  +|,|      ,+|+ |,     ++  ,+,,+ ++ ++,+,,|   |+,|      ++  ++ +| ,,  ++++  ++,| +||||||+  +| ++ + + +++ ++,  +||+   +|   + ++  ++ ,+,+     +++   , ++++  ++++  |+ +||+    ,++ || ,++ | |   ||+|+      |+++|+  +|  ++   |+ +|++ ++              |,++  ++  +          
++++  +||+  ++ ++ +|+,+|, ++,|  ,++ ,, + ++     ,|   ++ + +++|++ +,+   +| |,     ++ ,|, +| +|, ++,+  ++++ |+    ,+|, |,+| +,+ + + ,,  ,|,,++   |  ++ ,++ ,| +,+,  ,+||||+   +| ++  ,++  |, ||+   +|||+ ++ ++ +,| |, +|   |+    ,| ,,, +  |  +|+ +| ,|, | ++,     ,+||++  | |+   ++ ++         
++++  +, +,|+  ++ |+   +|+|,,|  |+|+|++, ++ ,    ++  ++ |,|+++ + +++   +| |,   +|| ,|+   +, ++ ,+ +      ,|       ++ |+,|  +  +++     ++++ |,  |+,|   ,++ | + +  +|+   ,+  +| ,|       |,,+     ++,,  ,+ ,, ++ |,|+,|    ++    ++   ,+++ |  , , +| +|  |+ +++       ,+|,,| |+    ++ |         
++++  +|| +    ++ |,    ++   ++++ ,+,    | +++    +, ++ |+|+,|++       ++ |, +|++ ,|+   +|  ,+  + ++      ++     ,|+ |+ |  +     +|++  +++,|+  |+,|   ,|,++ +,+,++ +,  ++ +| ,|+      ++,+ +++  +|++,  | ,++  ,| |,,|    +|   +|   || |,+| ||++ ++ ,|++++  +,     ,++++,++ |      | +,        
++,+   , ,+   ,|, +|     ,++ +++     +++|+ + |    ++ ++ |+|+ ,+ +++,   |+ | ++,   ++    ++  ++  + +,+     ++   +||+  |++|  +   ,|+, +    |,|+  |+ +|++|+ |+ ++ +, |,+ ++ |+ +|+   +,,,| |,++ +   +||++ ++     ++,|  +|   ++ +||  ,|+ |+ |++|    |+  ,+++ +|+ ,+++||||+  |,+|      ++++        
 | |     ,+  +|+   ++  +||+++   ++++||+++  | ++++|+ ,+  |+|+   +|,+|++++ ++ ++    ,|,   +|+|+  ++ +  +   |+ ,+|++   +| +|,,+, ,+   ++  ,|+ |,  |,,  ++  |+  ,|  ++ +| ,,+  |+    ,+,,| ++ ,|++      ++ |+    +| |+   ++  |++|+  +|, |+ +| ++   ||       ++ ,|+++,,    ||, |+      +,+,        
 ++++     + +|,    ++ +|+  ,|++||++++      ++ +++, ++ ,,++++ ,++    +++ +|   +|+++ ++    ++,  ++ +  +, ,|+ ||+     +|, |+,+,+++ ,  +  +|, |+  ++ |||++||+    ++|+  +| ,  +|+     | + + |   ,+  + , +| +|    ,+ ++    ++ +| ++++|+, +  +|, ,||||| ,,    |+ ||,        ,+ +|+       + +,        
 ,| |     + |,    +| +|     ++++            |+,  +||,+ +,| |+|+ ++|++++++     ,+,+ ,|       +++ +   + ,| ,|+      ++ +|+  | ++ + , +++, +|,  ++ |+ ++++       ,,    +++||+      +| +, +,      ++ ,|+ +|    ++ ++     |,+|,  +|+,  +++|+    ,+++ +++   |+ |+   ,      ,+|++        | |         
  +,++   ,| |   ,|+ ++                       +|||+,  +++ |+    ,++,+++++        |+ |+     +++  ++, +  |,+|,     ,|+ ++  , ++  ++ +    +|+    +,|+                ++  ++,        ++ ++ |       +,,+, ||    ++ ,+     |+ |,          ++          ,| +  |+ |+  +||+                 ++,+         
  ,| |,  ++ |++|| ,|+                                     ||++,++              ++ +|        ,++  + + +| | ,++  ++ ,|, ++|+ |||+  ++|++, ,+|+ ,+                 ||++           +   , ++       |+  +|+   ++  , +   ,+,,|,                       +,++ |+ |+   ++,+                 + +          
   ++,+   +, ++ ,++                               ,+|+,,,  +++ +  +++,        ++ ++       +++   ,+,+ |,++ |+|+ ,,++, +   +  ,        ++++,  +                 ,+ ,++           +  + ++      +|+  ,  ++|+  +++ |++|+ ++                        +| | |+ |+    +++|                ++,+          
    | ++  ,||+||++                                +++++,,    + +  |+++      ,+, |+              ++++ | | ++ |+  ,,   +   +,       +++    ,+++,                , +|+            +  ,++     ,++  ++ ,+,   ++, +, ,   ++                         |,+,++ |+     ,+++                + +           
    ,+ ++   +++                 ,,                +|+  ,+    , , +| ++     ++ +|,               +++++|,+ ,|+| +|+    +  ,+     ,+|+ ,++, , ,+            ++   ,+,              ,, ,     ,++  +|+++++++|+,    +++||+,       ++                ,| + | |+                         +,,+           
     ++ ++                     +,                  ++,,+    + +  ++,|,   ++, ++                  + +++++  ,+ ++,|+   ,+++    ++++  ++ ,+ ,+         ,+++++++            +|+,    +, ++++++  ++,   ,++,          ,,         |||+               ++ ++ |+        ,,               ++ +            
      ++ ++                  ,+ ,+                  |+++ ++,, +  ++,+  ++, ,+,      +|++         + ++,++     |, |+      ,,,++   , ,+  ++  +,    ,+++,     +|     ,+++  ||+++     ++,    ,++,                             |+  |+              +| , +|        +||+             ++ +,            
       +| ++                ,+ ++       +|+,        ++++++++ + +, + |++, ,++  +++  +|,+|,        ,+ , |      |, |+    ,|+|+  ,+++  +  ++ +|  ,+++   ,++++  ++   ++  + ++  ++       ++++++                               |+   |+    ,,         |+ +|   ,++   |+,|+          ,|+ ++             
        ++ +|,             ,+ +, +|++  ||++|        +++ ++|+ +, +++ ,  ++,   ++ + ++   |,         +|+|+      |++|     ++   ,+   |+ +  +| +++++  ,+++,  +|  ,+  ++ +|+ ,++ ++   ,            +++                        |+    |+   +||+   ++   ,+++   ,|+|+ ,|  |+       +||+ ,|+              
         +|  |+           ,+ +  ++ ++ +|   |+     ,+     +  +, ,, , +++,     + + +,,+++|  ++       +++       +++|    + +|, ++   +| |,  |,,+  ,+++    , ,|++|+  +,+,    ,| ++ ,|++ +|++     +|++,                      ++ |+, |,  |+ ,+  |||+  ,,     +|  |,+|  |+      ++,  ++                
           |+ +|+++||+,   + +   ++++  ,|   |+    +|++     +|+++ ,+| |+      + + + +++++  +++,           ++   +|+|  ,|++ +|+ |+   ++++  |+ ++++      +|| ++++   ,+       |+|+ | ++,|++|+   ,+ +|,      ,+,            ++ |+++,|  |+ +|+ +|  |,,||||,  +|  |,,| +|       ++ ++,                 
            +|+ ,++  +|  ,,,+    + ,++++  +|    |+ ++     |,  ,|, |+|+  +,, ,,,++ ,      |,|+          +||+  +| | ,|, +   |+,|   ,| ++++ ++,     ++++ ++ ,,+   ,+     , +++++ +| +|  ++   ,+|+,      ,|+|,          ++ |, |++| ++ ||,  +|  |,+|  |+  +| +|  |++|        + +                   
              +|+++|+ |, + +      +++++  |+    + +++      |,   ++ |+|+ + ,, ,+ | ,++,    |,++          ++ |+  | |,+++|+   ,| |,   ++ , ++ |     +|| ++|+,++|| +||++++||+,|+ ,++  +|  ++      ,       +| |,          + +,  +++| + |+     +||+ ++  |+  ,|,,|  |+ |+       | |                   
                ,++,+,++ + +     ,+   +,++     ,++        ++   ++ +++++ ,+     +  ,+| ,+++  ++         +|+ |+ | |,,++      | |,  +++ ++++ |+    +++++++ ++, |+++ +|++, | |+++, ,+ +, ++    +|||,     +|,|          + +,   ++,| +++           +|  ++   ++ |+ +| ++       | |                   
                    ++++ + +      |+ ,  ,        ++   ,++, ,,  |+ +++,,,+  ,++++    |+++  ,  ++    ++, , +,++ | |+   ,++  ++ | ,++ , ++ |+|++++ ,++, ++  ++ ++,|+      | |+|  +++++ +|    +|, +|,    +| |,         ,,+    +| | ,,      ,||||, +| +|   ,| ++ +| +|       | |                   
                    ,| + + +      ++ +, +     ,|||++ +|+++++ ,|+  |+   +  +|||+     |+,+,   , +  +||+|+||+ ++ |+++ ,+|++||+ ++ |, ,+ |, |+|,+ +    +|||+  | ++ ,++ ,, ,| |,|  ++  ,+|     |,   +|    |, ,+         , ,+,  +| |+  ,+|+, +|  +|, +| ++   +++|  |+,|       | +                   
                     + | + ++    ,|,  ,+     +|+   +++ ,,   +|+  ,|   +,  +   ,|+|+|+  +   , ++ +|    ,    |, ++,|+|+      +| + ++ + +  | | +++  ,|+,  |,++ |+  +,  , |+,| |  ++     +++  |,    |, ,||   ,+       ,+++|||+,| |+  |+++| +|+, ++  +| |++ ++,|  ++ |,     ,+,+                   
                     ++++ + +|+++|  , |,    +| ++,    +|+|+|+   ,|,+  +   +|, ++ ++,   +   ,++ ,+ +++  ,+||+  ,|  ,  +|||||+ +| + + ++ |+ | ,+, +|  +  + | +|  ++    ++ ,| +, ++ ,++|++|+ +|+   |, +,     ++   ++  ++   +| | |+ +|   |+ ++++,|   +,  +,++ |, +| |+     +,+,                   
                      + + ,++ ,+, +, ,+    |+ +|+|+,+|+  ,+,  ,+| ,| ,++   ++ |,     + ,+++++ ++ ++,+|+|++,    +||+||+,,++   ++ ,, ++ |+ |+++++|+ +|++,,+ +|, +|    ++   | ++|+,       ,+  ++  +|  |      ++ +|||+   ,,  | | |+ +|   ++   ++ |, +|  ,| |+ |  +| |+     + |                    
                      ++,+  +|+++|+, +   ,|+ |+  ,+++  ,     ,+  +|, + +|+|+ ++  , ,,+,      ++ ++   ++          ++    ++    +|, ,+ ,|, |+ ++ +  |+ ++ , +|,  ++  ++,    ++     +++++++++  ++, ++  ++    ,| ||,  |+|+++  | | ++ ++   |, ,|| ,| +| ,+ |+| +|  +| |+     + +                    
                       + ++   +++  + +  ,+ ,|,        +++    ,++|+   ++  ,  |+  +++ + +     ++ ++                 +++ ++|+    ++|+  + +|,   +|+||,  +|++|+    ,+||+       +|||+,+++++++,  +|,  ++   ++,+|| +|    ,+, ,  +| |,+|||    ++||+  |+ | ++++ ,, |+  ++ |+    +,+,                    
                        + +,       + + ++ +|,         + +     ,,      +||+|++  ,+ + + +   ++, +                   +++,++,+         ,+++      ++,     ,++,       ,       ,  ++,  ,        +| ,+ |     ++++ +| +++    +++||, +| ++  ++ ,++  +|+ +| +,++   ||   |,,|     + +                     
                        ,+ +       + +++ ++           +++               ,,      +++ ,+ +++, ++                    +++++||+          ,                            +++   +++    + +       ++ ++, +        ,|+ +|,+|++|++++    |+,++||||||||||+  +| +++, +|+   ++ |+    ++,+                     
                         +,,+      + ,  ++                                           ++   +++                     ,++, ++                                      +|  +   + +    + +    +++  ++ + +       +|, |+   +++,         +||+, , ,++++     ++   +||+   ,|,+|     + +,                     
                          + ++     +++++                                              ,++++                                                                   ,+      ++ +    +,,+++++  ++,  + +     +|+ +|+                      +||+      +,  +||||+    +|, |+     + +                      
                           + ++     ,+,                                                                                                           ,,          ,++ +|+ ,,+ +++  +,    +++,    + +++++|+ ,|+                       ,| ,|,    |||+   ,      +| ,|+     + +                       
                           ++ ++                                    +++                                                                          +||+            +  | ,+  + ,++ ,+++++       ,+ ++,  +|+                     +++  |+ |,   ++ ,|,         ,,+|+     ++ +                       
                            ++ ++                                  +|,+                                                                        +|+  |+          ,|  ,+  ++ ++ ,             ,  +++++++                     ,|+ ,, +||+    |,  |+         +++       + +                        
                             ++ ++                                 ++,+                                                                       ,+    ++           |  ,|  , ++++,      ++    +||  ++,              ,+        + ,++   ++     |, ,|,   +,             + +,                        
                              ,+ ++                                |+,+                                                    ,++                ,+++, ++           |, ++  ++  ++      +||+  +| +|                 +||+      ,++, ++    ++   +|+|+  ,|||,           +,++                         
                               ,+,,+,                              |+++                                                   +|+|+                  ++ ++           |++|     +|+||+    |, |, +|  |+               ,|, |+      +,,|+|+  ++||   ++    ++ ++          +,,+                          
                                 ++ ++            +,               |+++                                                 ++   ++                  ++ ++           |+++    +|    |+   |, |, +|  ++  ,++,         +|, |+        || ++  ++ ++   +++  |+ ++         +,,+                           
                                  ++  +|++,      ++,,       ,+     ++++                                               ,+, ++++,                  ++ +     +|+,   +++|   ,| +++,,|   ++ |, +| ,+, +||++          ++ |+        || ++  ++ ++  +|+|, |+ |+      ,++,,+                            
                                   ,++  ++|,    ++ +       ,|+|+   +++|                                               , ++,      +++        ,+++ ++,+    +| ++   +| |   ++,|+,+ |+  +| |+ +| ++ |+  ++           | |+        |+ |+  ++ ++  |+ ++ +| |     ++++ ++                             
                                     ++|+ ++    | |        ,+  +|  +| |                                               ,++ ,+++, +|+||+     +|++|+++ |   ++ +|    +| |  ,| |+ ,+ |+   |,++ +| |,++ ||+,           | |+        ++++  ,+ +|,  |+ |, +| |,    +, +++                              
                                       ,|,++   ++++    ,+,  +++ |+ ,| |+                                                  |++++  ++  |+   +|  ,++++ +,  ++|+     ++ |, |++|  ++ |,   |++|  | |,++|+              | |+        ++++   +++,   ++ |   | |+    ++++                                
                                        +,|,   + |    +|||++, + |+  |,|+                                                 +|   +,,+++,++   +|  ++ ++ +,  ,,,     +|  |,+| |+ +| +|    |++|  |+|+ ,  ,++       ,   | |+   ,++  ++++     +++  ,| |,  |+|+    ++++                                
                                        | |,   | +   ||, ++++++ |,  |+|+                                                 +|   +,   +,++   +|  ++ ++ +,        +|+   | |+,+ +|, +|   +| +|  |+++   +|||+    +|++  | |+  +|+++ ++++   ||||||  |,|+  |+|+    ++++                                
                                        | |    + |  |+ ++      ++  +| |+                                        ,       ,++   +  ,++ |+    |, ++ ++ ++     ,+||+ ++ +++ +,++  , ++++|, |+  ||+|  +|   |+  +|,++  | |+ +| +|, ++++  +|    |+ |+|+  |+|+    ++++                                
                                        | |    | |+|+ |||+,++||+  ,|, +++             ++++++++++++++++++|||||||||||||||||+,   +++|+ ++    +|, ++ ++ ++  ,+|||+ +||++ ,,  +, +||+ +|+  +|   ||,| ,|    ,|, ++ ++  | |+ + +|, ,|,++  +|,   ++ |+|+  |+|+    + +                                 
                                        + |    ++ + +|+  +|+,,,   |,,+  +||||||||||||||||||+++++, ++++++,  ,              ,++,    +|+     +,  |+ +| |+  ++    ,  , |+     +||+ +|,  +|+    |++| ,|     |+ +| ,|  | |+ +++  +|+ +|   ++   |+ |,|+  +++|    | |                                 
                                        ++++   ,||+||            +,,|+||+             ++,,++++++++++++++||||||+||||||||||||+++|+|+++      |||||  +| ++  ++    ,+||+ +|+++||+    +||||+    ,| ++  |+    |+  +, |+ | |+ ,  +|| ,+ |, +|+  ,++|+ |+  +++|   ++,+                                 
                                         | |    ,+++             ,++   ,+||||||||||||||||||++++++,++++++++++++++, ,,,,,        ,  ,+       +++    |,+|+|+,        |+ ++++,        ,,     ,|, |+  ,|+  +|,,|+  |+ | |+  +||+ +++,+|||+ ++ +|+ |+   ++++   + |,                                 
                                         + +,                    ++                                                       +++    ++|+        ,,   ++ +++   ,++++, ++                   ++|,,|+    ++ ,|+ +|  +|+ | |,,||+   +| |  , +||||+++||   +| |+  ,| +                                  
                                         ,+ +                                                                            ,++|,   ++,+      ,+|++   |+  ,+||||++||||+                ,+|+, +|,     |+ |+  ,|+||+  |,++|+      | +|||||,  +||++   +|, |,  +,++                                  
                                          + +                                                                            ,|,++   ,+++     +|+ ,+   ,+|+|+++,     ++                ,+  ++++      |+ |+    +++    +| , ,|++  ,|, ,+++          +|+ +|+   + +                                   
                                          ,+ +                                                                            +++     ,,     ++ ,||+     ,+                            + ++++       ++ |+             ||+||+,|++|+                , ,||,   + +                                    
                                           + +,                                                                                          ++||+                                    + +,        ,++,|+               +++   ,+++                 ++++    ,+ +                                    
                                            + +                                                                                          +++                                      + +        ++ ,+                                                    + +                                     
                                            ,+ +                                                                                                                                  , ++     ++, ++                   ++                               +,++                                     
                                             +,++                                  ,,+                           ++,                                                               + ,|++++  ++,                   ++|+                             ++ +                                      
                                              + ++                                 + +                          ++++                             +++                                ++     ++,                ,+, ,| ++                            ,+ +,                                      
                                              ,+ ++                  +++           + +,                         + ,+   ++                       +|+|+                       ++       ++++++                   +++  ++|+                           ++ ++                                       
                                               ,+, ++               +|+|+          , +                          ++++  ++++                      ++ ++ ,+,                  +||+    ,+,      +++        ++,   ++ |  +++  +,                     +++, ++                                        
                                                 ++ ++,             |, ++  ++|++,  ++,                             ,  ++++             ,++      ++,|+ ++|,                 ++,+   +|,+  ++  + +       ||+|,  , ++   ,+ +++,                   +++ ,+,                                         
                                                  ,+, +|++       ,  +,,++  +, +++   ,                   +++     ,, ++ ,+,+             ++++      + ,, +,++                 +++   ,, ++ ,+++ | |,     +|  ++  +++   ++|+++++                   + +++                                           
                                                    ++  ++     +,   ++++    |+ |, ,|||                 ,+ +      ++ +  + +             ++++      ,+ + ++++    ,               +|, ++ , ,| + + |  +++ ,|  |+    +++ +|++,+,+        ++++,      + +                                             
                                                     ,|++|    +, +          ,+++  ++ |+    ++           +++       ,,,+ + +          ,  ,+,+ +|++  | | ,+,+   +,+             ++++  ,||+ | | | | +++|, +||+   ,||+|+ | | + + +++++ ,|+++|+     + +                                             
                                                      ++++   +, +     ++     ,  ++++ |+   +|++            ++   ++++ +,,+,+          ,+  + +,+  +  + +  + +  ,| +          ++ +| ++ ++ |,+ | | |,+| ++        ,+   | | | + ++|||||++|   ,|+    + +                                             
                                                      +,+,   + +   ++++++ ++,  ++++ +|    +|+|          ,++|+ +++, ,+ ,,,,   ,     ,+ + , ,,+++,  + +  + +  |, ++       ,||||,+ +| |+ +++ | + +,+| ,|+++++,++ +, +| | | + +++   |+++    ++   +,+,                                             
                                                      + +   ,+,+  +++, ++ +,+  |   ++     |++|       ,++,| ++ |+ ,+,  , ,   |+|+|+|++ + +,+    ,  +,+, + + ++   +,     ,|,   +, ,+++, +++ + + +++|  ,+++,+|+|++  ,++| + +,+,+  ,| |+ ,+|+,   + +                                              
                                                      + +   ,+,+  ++ +++  | ++ ++  +++   +| ,++     +|||,+ ++ +  ,++, +,+, ++  ,  ,+ ,+ |,|+,+|||++ + ,+,+++     +     +,+||++++, + ,+   ++ + | ++,+,       +  ++  + ++, + ++   ++|  +++     + +                                              
                                                      + +    + ++++ ++    ++++ |+   +|+ +| ,, ++   ++   ,, ,,+ ,+  ++ +,+ ++ +++|+++|+ +|  ,++   ,  + ++++++     + ,  ++,|      +|+||||+|+ ,++,,+ |+|||||||||+++++++|+ +,+ + ++ ++   |+     ,+,+                                              
                                                      ++++    +    ++ + + |+,+|+ +||+++ ++   , ,   + +||++++  ++++|++  +, + +++++,     +||++,+|+++++  +++ ,+    + +,+ + + ++++,  ,+   ,+,      + +,      ,, ,     ++,   , +,+++|++,  +++    + +                                               
                                                      ,+,+     ++++,  +, ++   , +++++++ +   , +  + ,,+ ,++++++  , ++     + ++    ,+|++  +++++ ,  ++    ,,  ++++|+ +,++++ ++ , +,   +++    ,++++ ++ ++++++  ,++,           + |  ,+||||, ++   + +                                               
                                                       + |               , ||+ ,|       ++   +++ ++ +  ++++   +,+       +,,+     ++ ++      ++|             ,+++ ,+,++ | +,++++    + +    +++, ++  +,   +, +++,          ,+,+      ,,,+++  + +                                                
                                                       +,++        ,,+++ ,+  |+ +        +++|+ + ++ + ++++++  + |      ,, +      ++,|       +++,                  + ++ + ,+,       ,+,    +|++|+   ++|+++,  ++           +,+,         ,,  ,+ +                                                
                                                        + |        +    ++   ,+++          ,,  + ++ + ++++++  + +     +  +       + ++       ,++                   | ++ +                   ,++     ,++ +,                ,+,+             + +                                                 
                                                        ++,+        ++++                       + ++ +  ++++   ,, +,+++  +        +++                 +++          + +, +                                                  + ++           + +,                                                 
                                                         + ++                                  ,,,  +          ,      ,,                            ++ + ++       ,+   +                                                  ,+  ++        +,,+                                                  
                                                          + ++                ,++++             +   +           ,+++++             ,+,   ++  ++|++ + ,+ +++,       +   +                                        +++        ,++        ,+,,+                                                   
                                                          ,+ ,|,              +++++      +,     ,+  ++     ++                      + +  +||+ +,+++ ,++ + ++         ++ +,          +++                         +++|+         ,++  +++++ ++                                                    
                                                            ++ ++             ++ ++  ++ ++|,     ,+ ++    +|+|                     +++  +|,| +| +| , ,+ ++    ++     +++          +|+|+  +++    ,++,           |+ ++    ++       ++    ++                                                     
                                                             ++ +|++          ,|+|  ++|,++++      ++++    +| |+                    ,     | |  ++++  ++ ++ +, ++++                 ++ ++ ,|+|,   |++|,          +++|,  ++,,,      + ++++,                                                      
                                                              ,+, ,+,          ,    +|++++++      ++++     | |,            ++     +++|   + +  ,  ,,|+ +, ++|,,| |                 ++ ++ ,| ++  ,|  |+     ,++  ,++,   , ++       + +                                                          
                                                                ++ |        ++  ++|+ | |++++  ,+  ,|,|     | |   ++,      ++++    ++ |+  +,+, +  , ++ +, ++++ | +                 ,++|+  |+++   ||+|+    ,|||+ +++++  +          + +                                                          
                                                                ++,+       ++++ ++,+ | |,+++  ++|+ | |  ,  + |  ||||,     |++|     + |+  ++++,,  +,+|+ + ,| | + +,        ++             ++++    ++,     +| ,| +,++|+ +||++      + +                                                          
                                                                ++++      ++  + ++,| | | | + ,+ ++ | | +++ ++++++  ++    ++  +    +| +   ,+,+ +++    +,++ | | +,+,       ,+++  ++  +++++ +++,     ,++    +|  | ++  |+,+,,+|+     + +                                                          
                                                                +,+,     +|,  +,|, ++| | + + ++  | + | + |+,+,+++ ++,   +| +, +  +|, +++ +|,|+  ++,  ++++ +,+,+ +   +++++ + ++++++ ++, +,+,+,     ++++   ++  |, +  +|,+    |+   +,+,                                                          
                                                                ++++     ++ +  + ++ , ++,+ ++|   + + +,+ ++++ |++,  +, ,+  + ,++ +  ,  ++++  +|||+++++ ,++| | | +  |++|+++, +++|,+ ,   +++,+, +++++,++  ,+ ++ +|, + ++  +++|+   + +                                                           
                                                                ,+ +     ++|||+++++++++ + ++++||, +,     | +    ,, +++  +, ++ ++ ++|||||++||++  ,+    ++   ,+ | + ++ ,      + ,+,+ +++ + | +,,+++,  + , + +++,  +|||  +||+++    + +                                                           
                                                                 + +,     ,,  ++,   ++  +++  ++ ++++|+++++ ++||||+|+++  ,+    +       ++  ++,+++++++++++++++  +++ | |||||+++  + +, +,,++,+ + ,+ ++++  + +,+ , ,++  ++|+        + +,                                                           
                                                                 ,+ +                   ++       ++++ ++,   ,     ,++,   ++|||                                ,+ +,++  ,,  +++  +   +++ ++|+ ++++   + +  + ,++,         ++    ,+ +                                                            
                                                                  + +,                                                     ++                    ++,,           ++ |   ,  ,   +  +       ,, ++ + ,++  ,  + ,+++        ,,,,   + +                                                             
                                                                  ,+ +                                                                          +|+  +         ,+ +  ,+,+ ++++,,,++,       ++ +, + + +   +  ++          ++   +,,+                                                             
                                                                   +,,+                                                                         | +  +        ,+ +, +   +      ,+ ++      +, +   ,++ +, ++                  ++ +                                                              
                                                                    +, ++                               ,,                      ,    ,+,       ,| |  +       +, +,  +,+         ++ ++   ,+ ,+        ,+ ++                 ++ +,                                                              
                                                                     ++ ++                       +++   ++|,                    +++   ++|       ,+,+ + ++, ,++, +                 ++ ,+++, ++          +|++                ++ ++                                                               
                                                                      ++ ,+                     ++ +   ++++                    + +   | ++   ++  + +, + +++++ ,+            ,++    ,+    ,+,         ,+ ,+          ,++++++  +,                                                                
                                                                        ++ ++,                  ,,++   ++|+   ,+++             , ,   ++++ +|+++ +++| ++ , , ++             ++++        +,      ,+++ ++|            ++++,  ++                                                                  
                                                                         ++ ++++        ++,     ++        +  ,+  ,      ,,    ,+++   ++++,+ +|+ +| |+,| +,| +, +++      ++,,+++     +++,       +, , +,++           ++ ,+++,                                                                   
                                                                          +++  +      ,|++,       ++,  +|||+ ,+++,     +++    ,+  +  ,+,+ ++,,   | |+ | | +,+ ++ +      ,,+ ,+,     |,++    ++ ,+|+ ++++ ++         | +,                                                                      
                                                                            +| +,     + ,|+      +++|, +  ++    +      | +,    ++ +  ,+,+   +|+  | |  | | +++ ++|+      + +  +      ++|,   +|++     ,+,,+,, ++      + +                                                                       
                                                                             +,+,     +,+,       ++ |+,+ +++  +|||,   ++ ++   ,+| +  ,+,|  +++|+ | |  | | +,+ ,,   +++, ,,+  +   ,, ,+     |+ | +++ ,+, ++++ |,     +,+,                                                                      
                                                                             ++++     , ++++++++++, |++| +++  ++ ++  +|   |, ,|++ |+ +| |+ ++ |+,| |  | | +,+, +++,| ++ ++ +,+  ++++  ++++ ++,| + | ,+, ++ + |+     +,+,                                                                      
                                                                             ++++     ++++++++++  , +,|,   ++ +| |+ +|, ,, + ++,, ,| ++  ,+++ | |+ ++++ + +,+,,++|++ ,+  +|+ ++ +, ++++,+|+ ++, + | ,+,+  ,| +,     + +                                                                       
                                                                              + +     ++ ++++++++|++, ,,|++++ ,+ |, ++  ,, +, +|||++ +||++, +|| +,+  , + ,| +,,|    +|+  +,  |+ |+ ,  ,   +   ,+ ++ ++ +|+++ ++     + +                                                                       
                                                                              + |     |,++++++++,  ,,,++, ,  ,|+++  ++  ,+ |     +++  ++,++|++  ++|||+|+ + ++ +| +|||+   +++++  ++ |+++++++  ++ ++  ++++   ++,+     + +                                                                       
                                                                              ++,+   ++ |        +,        ,++ ,|   ++    ++                         +,  +++  |,+|        +++  ++ ++        ,+ +  ,  ++||||++++    +,+,                                                                       
                                                                               + +, ++ +,       +++,       +,+++     ++++++                       +++        ++ + +|+,    ,, ,++ ++   ++  , + + +|++       ,       + +                                                                        
                                                                               ,+ ++, +,        +,+,        ++         +++                        +,+,      |+ +    ++   ,  ,, ,+    ,,, +, + ++,  +      , ,     + +,                                                                        
                                                                                ++  ++          ,++                                               ,+ +    +| ,|, ,+++     +,  ++      ++ +, + +,++++      ,,+    +,++                                                                         
                                                                                 +| +,                                                             +, ++||+ ++              ,,           + ++ +                 +,,+                                                                          
                                                                                  ++ +,                                                             ,+    +|+                            ++ ,++                |,,+                                                                           
                                                                                   ++ ++                                               ,              ++|++                               ++,+|              ++ ++                                                                            
                                                                                    ++ ++                                      ,++  ,+|++       +++                                        +| +           ,+|+ ++                                                                             
                                                                                     ,+, ++   ++,                             ,|+|+ +  ,+      ||,+             ++,                         ,++  ,+      ,|+ ,|,                                                                              
                                                                                       ++ +|+||++                         ,++ ++ ,+ ++++       ++ ,+          ,|++,                    +++ +,   + +      ++ |+                                                                                
                                                                                        +|, ++ +|       ,++             ,+|++,++ ,+    +,          +, ,+|+,   ,,,++   ,++        ++   ++ + , ,  ,++      ++,+                                                                                 
                                                                                          +|++,+|       +,+,     ,+++   ,  ++, ++|+  +|||+    ++, ,+, +  +,   ,++,    ++|,      +|||+  +|+ ,,     ,      ++,+                                                                                 
                                                                                            ++| |      ,| ++     |++|+  ,+|+,   ,,  +|   +    +++,++  ++|+     +,     |+++      ++  + ++   ++,  +++++    ++++                                                                                 
                                                                                              + |     +|   +,   |+  ++              ++,+++     , ,,      ,++  |||+   |+  |      |+ ,|  ,  ,+,|  ++, +    ++++                                                                                 
                                                                                              ++,+   ,+, +, +  +|   ++,, ,++||+,++++|+,++++    +||||++|++|+|+,| +|  |+    |+   ,|, ++ ||+  | |+   +,++   | |,                                                                                 
                                                                                               | |,  ,| ,+, ++,|  ++ ,|||+++  +++ +++ + +||+  ++   +++,    ++,|, ++,|  ,  ,+,  |+ , +|, +|++ |+ ,++ +,  +| |                                                                                  
                                                                                               ++,+   |, ++ ++,++|++|+   ,++++   ++ +||||,++ ++ |||  ,++++||, |+ ++ |      |, ++ |+,  ++,   ++  ,  ,+   |,++                                                                                  
                                                                                                | |,  +|+++|+  +++   +|||+++++|||++++,  ++,+ | |+ +|||+++++,  | +|, +     ++  ,+|+ +++  ,+|++   ,+|+   ++ |                                                                                   
                                                                                                ++ |   ++|++              ++     ,  ++   + +++++   ,,       ++ ++   ++  ++|    ,,     ++,              | |                                                                                    
                                                                                                 ++,+                    ,+++   ++++,,   + ++,++         +++, ++     ++||+           ++++             +,++                                                                                    
                                                                                                  + ++                   ,+++   +,,+++   + ++ |             ++,                      ,+++            ++ +                                                                                     
                                                                                                  ,+ ++                   ,,     +,,,    +,  ,+    ++    ,++,                          ,            ++ +                                                                                      
                                                                                                   ,|,,+                                 ++  ++   +++ ,++                                          ++ +,                                                                                      
                                                                                                     +, ++                               ,+  +,  + ,+ |,++                                   +,  +|+ +,                                                                                       
                                                                                                      ++ +|+,    ,                        ++ +   ++  + +|,                                  ++|||+ ,+                                                                                         
                                                                                                       ,++ ,+||||++                        |,+     ++ ++, ,,                                ++   ,++                                                                                          
                                                                                                         +++     ++                        ,,,   ,   ++  +++,    ,+,     ++,    ++          ++++|+,                                                                                           
                                                                                                           ,+||++++                    +++       +||+ + ,  +    ,|||+    |++   ,+++         +,++                                                                                              
                                                                                                               ,+++       ,++,        ++ +         +++,,,,      +| ++    +++   ,| +         | |,                                                                                              
                                                                                                               ,+,+       +++++  +++  ,,++           + + ,+|+   |+  +,   ++     + +        ,| |                                                                                               
                                                                                                                + +       ++ ++  +,|+ ,             ,+,+ |,++  +|  ,+    ,+++  +| +        ++++                                                                                               
                                                                                                                + |       |+ ++,++ |+ ,++|||||||||||+ +  ++,| +|,   ++||||+ +|||, |+       + +                                                                                                
                                                                                                                ++,+     ++ +  ++ ,|  ,+             ++  ++ | +++|+,      ++ ,, , ,+       + +                                                                                                
                                                                                                                 + +     ++||++,+++,  ,+ ||||||||||||+   ++,+  +|++++|||||||||||+||+      + +,                                                                                                
                                                                                                                 ,+ +     +,   ,+,   ,+ ++              ,+ +,     ++         ++,         ++ +                                                                                                 
                                                                                                                  + +,      +++,  ,+++ ++            ,+++ ++    +|++,           ++|,     + +                                                                                                  
                                                                                                                   + +     ,+ +,  ,,  ++             ,   ++     , +|            ++++    | ++                                                                                                  
                                                                                                                   ++,+    ,+++   ++++,              ++++       +++              ++    +,++                                                                                                   
                                                                                                                    + ++           ,,                                                 ++ |                                                                                                    
                                                                                                                     + ++                                                            ++ +                                                                                                     
                                                                                                                      +,,++                                    ,                   +|+ +,                                                                                                     
                                                                                                                       ++ ++,                                 +++             ++|+|+ ,+,                                                                                                      
                                                                                                                        +|, ++++++,          ,,               + |  ,++        |,++ +|+                                                                                                        
                                                                                                                          ++  +++++      ++++,,               | |,++ ,,      +|  +|+                                                                                                          
                                                                                                                           ,++++,++    +++   ++               | | +, ,       +| |++                                                                                                           
                                                                                                                              +++++   ++ +|++,           +++  + + ,, +,      ++++                                                                                                             
                                                                                                                                + +  ,+ +             +, ++|+ + + ,+ ++      +,|,                                                                                                             
                                                                                                                                + +  +,+,,+||++++++++||| + +| + +,,| ++      | |                                                                                                              
                                                                                                                                + +  + | ++    +++++,  ,++ ++ | +  +,++      + +                                                                                                              
                                                                                                                                + +  + + +++|++++++++||+ ,++, +,| ,+ |+     +,+,                                                                                                              
                                                                                                                                ,,,+ +,,+, +,  ++++,   ++++   ++, ++++      | +                                                                                                               
                                                                                                                                 + +  ++ ,+,      ,,+++            ,,      ++,+                                                                                                               
                                                                                                                                  + +  +++       ++,  +                    + +                                                                                                                
                                                                                                                                  +,,+           +++ +,                   + ++                                                                                                                
                                                                                                                                   +,,+           ,                      +,,+                                                                                                                 
                                                                                                                                    +,,+                               ++ ,+                                                                                                                  
                                                                                                                                     +,,+,                         +++|+ ++                                                                                                                   
                                                                                                                                      ++ ++                      ,|+++  ++                                                                                                                    
                                                                                                                                       ++ ,|++++     ,++,        ++ ++|+                                                                                                                      
                                                                                                                                        ,|+ ,+,++    ++++        +,+++,                                                                                                                       
                                                                                                                                          +||+, |    ,| |        + |                                                                                                                          
                                                                                                                                            +++ +     | |        + +                                                                                                                          
                                                                                                                                              | |     | |+      ,+ +                                                                                                                          
                                                                                                                                              + +   ,++  ++     + +                                                                                                                           
                                                                                                                                              + |  +|+    |+    + +                                                                                                                           
                                                                                                                                              + +  ++  + +++   + +,                                                                                                                           
                                                                                                                                              ,+,+ |,++   |+   + +                                                                                                                            
                                                                                                                                               + + + ,   ,+   + +,                                                                                                                            
                                                                                                                                               + + ++ +  |    + +                                                                                                                             
                                                                                                                                               ,+,+ ++, |+   + +,                                                                                                                             
                                                                                                                                                + |  ,+++    + +                                                                                                                              
                                                                                                                                                +,++        + +                                                                                                                               
                                                                                                                                                ,| |       + ,,                                                                                                                               
                                                                                                                                                 ++,+     ++ +                                                                                                                                
                                                                                                                                                  +,++   ++ +                                                                                                                                 
                                                                                                                                                   + ,|||+ +                                                                                                                                  
                                                                                                                                                    ++   ,+,                                                                                                                                  
                                                                                                                                                     +|||+                                                                                                                                    `