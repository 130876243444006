export default `                                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                                         
                                                                                                                                                                                                                                                                                                                         
,                                                                 
, ,                                                                
,                                                                                                                                                                                                                       ,        , ,                                                                
,,,,      ,,                                                                                                                                                                                                            ,,,       , ,                  ,,                                            
,,,,     ,,,               ,,,,,                                ,,          ,                                                                                                                                            ,,       , ,                  , ,                   ,,,                     
, ,    , ,,              ,,    ,                             ,,, ,  ,,,   ,,,   ,,,                                                                                                                                              , ,                  ,,           ,,,,     ,,,                     
, ,     ,,              ,  ,,, ,            ,,    ,          ,, ,, ,+,,,  + +, ,, ,                   ,    ,,,             ,,                                                                                          ,,,              ,,,              ,        ,,,,,                     ,,     ,
,                    , ,                 ,,, , ,   , ,       ,, ,,,, ,, ,         ,,,,  ,+  +, , ,, , ,             ,,,   ,,,  ,       ,,,     ,,,,        ,,   ,,,  ,,,,                                                         ,,,,  ,,  , ,,     , ,    ,,,,,     ,      , ,       ,, ,,       ,,           , ,,   ,,
+                    , ,      ,+,,      ,,,, , ,, ,, ,       ,,, +,, , ,,           ,    ,, +, ,,,, , ,            ,, ,   , ,  ,,,,    , ,,    , ,,    ,   ,,,  ,,,, , ,,                                                        ,,,, ,,,,, ,,,,     , ,    ,,  ,  ,,,,, ,,  + ,        ,,+    ,, , ,           ,  ,   + 
,                    , ,   ,,,,  ,      ,,,, ,   ,, ,        + , , , ,,,        ,,,   ,,,,, +  , ,  , ,              ,,   , ,    ,,,   , ,,    ,,,    ,,,  + ,  ,,,, ,,  ,,    ,,,                                               ,, ,, ,,,, ,,,,  ,, , ,    ,,,,,  ,   , ,,, , ,              ,,,,,,,,          , ,,   , 
,                    , ,,,+,   ,,,  ,    ,, , ,,,  ,,    ,,  , , , ,           ,+,+  ,,,,  ,,  , ,  ,,,  ,,        ,,     , ,  ,,,,,,  , ,            , ,  , ,  ,,,,   ,,,,,  ,,                ,,                               ,+,+,  + ,  , ,  ,,,        ,,,   ,,,,  , , , ,          ,,  ,, ,,,,,           ,,    , 
,                  ,,  ,    ,,,,   ,,,      , ,  ,,      , , , , , , ,,,,  ,,, ,, +, ,,        , ,      ,,,,              , ,  ,  , ,  ,,, ,+,  ,,,   , ,  , ,  ,,,,  ,, ,  ,  ,,,             ,,,,                         ,,    ,,,   , ,  , ,  ,,,         ,,     ,   , , , ,    ,,,  ,,,,  , ,, ,   ,,,,           , 
,                ,++, , ,,,+,  ,,  ,,,,     , ,    ,,,   , ,,,,,,, , ,,,, ,,,, ,+ ,, ,+,   ,,, , ,  ,,  ,, +  ,      ,,,  , ,  , , ,,     ,,,,  , ,   , ,  , ,  ,, ,  , ,,,  ,   ,,            ,,,,             ,,,        ,,,,         , ,  , ,   ,  ,,,,   ,,,,,       , , , ,   ,, +  ,+ +  , ,  ,  ,,  ,,  ,,  ,,, , 
,                +,   +,      ,,,, ,, ,,,,,,, ,,, ,,,+  ,, ,, ,,,, , , ,, +  ,, +  +  ,      ,,,,,,,,,, ,, ,,,,,,+,,,, ,, ,,,, , ,,     ,,, ,,  , ,,  , ,  , ,  ,, ,  , ,  , ,, ,,,,           ,  ,           ,,   ,      ,,   ,,   ,,,,, , ,,,,  ,,,,+  ,, ,+   ,,      , , , ,  ,,   , ,, ,, , ,, , ,+    ,,+,+,+,,,,, 
,               ,, +, ,,      ,, , +   ,,    ,    ,, +,,,   ,, ,,, , ,  , ,, ,, ,, ,,,,  ,,, ,,,,,,, ,, ,,             , ,,   ,,   ,,   ,,   ,,,, ,,,,, ,  , ,  ,, ,,,, ,, , ,, +  ,,         ,,   ,         ,,    ,,,,,, ,     ,  ,,,,, ,, ,,,, ,,,,,    ,,,,    ,,  ,,,, , , , ,+    ,,,   ,,,,,, , ,             ,,   
, +,,+,,      ,, ,  ,,,,,,,,,,,,, ,, + ,,,,,   , , , , ,,  , ,, , ,  ,  ,,,+,, ,,,  ,,  , ,,,,,,+,,,,,,, ,,   ,,,,,,,   ,,+,,    ,  ,  ,  ,, , ,,      ,  ,  ,  ,, ,,        ,,     ,       ,,           ,    ,,,  ,,  ,,,  , ,  ,  ,,,,,,        ,, ,,,  ,, + , ,,        ,,  ,, , ,  ,,  ,,,,+,,+,,,,,,
,,,,           ,,,,,,,,,,,  ,       , +  ,   ,,,  ,,, ,,,   , , ,, ,,   ,    ,   ,,,,,  , ,,     ,,,,      , ,,,,   ,,   ,,,  ,,,, ,,,,,,  ,,,, ,, , ,,,,,, ,,   ,, ,         ,      ,       , ,,,,,,,,,,,,,,,,,   ,, ,+,,   ,,,  , ,+,,,,,,,,,++,,,  , ,,,,  , , ,,+,,,,,,, ,,,,  ,,,, , ,,,  ,          
, ,          ,,, ,       ,,        , ,,   ,,       ,   ,  ,,  , , ,  ,, ,              ,    ,,,            , ,,                      ,     ,,    , ,, ,    ,,    , ,,         ,,    ,,       , ,         ,,,      ,, ,           , ,,                ,, ,,    ,,   ,,    ,            , ,              , ,
, ,         ,   ,        ,,,     ,,  ,   ,  ,            ,, ,, , ,,  ,+ ,            ,,  , ,,             ,, ,                                   , ,,         ,,, ,,           ,, ,,,        , ,                  , ,  ,        ,, ,                ,, ,,           ,,,,              , ,              ,,,
, ,       , ,,,      ,, ,,     ,  ,,     ,,               ,, , ,,    ,,,         ,,,, ,, ,,,,, ,        ,, ,                         , ,      ,, ,,         ,,  ,,             ,,,          , ,       ,,          ,,, ,       ,, ,                ,, ,,            ,  ,              , ,                 
, ,,        ,      , ,,       , ,,,                  ,  ,  ,, ,,                     ,,   ,,   , ,     ,, ,,                         , ,     ,, ,,          ,,,,,                           , ,                  ,  ,  ,    ,,  ,                ,  ,              ,,,,              , ,                 
,,    ,   ,,,      ,  ,,        ,                     , ,,,,  ,                    ,,,          , ,   ,,  ,                           ,  ,,,,,  ,,            ,                              , ,       ,,             ,  ,,,,  ,,              ,,   ,                                 , ,                 
, ,,,     , ,      ,                                        ,,                                          ,,                             ,      ,,                                             , ,                       ,,    ,,                  ,,                                   , ,                 
+ +   ,   + +         ,,                                ,,,,                                          ,,                                 ,,,,,                                               , ,                         ,,,,                  ,,                                     , ,                 
, +       , ,      ,  ,,,                                                                                                                                                                    , ,                                                                                        ,                 
, ,   ,,, + ,,,,,,+,, ,,,                                                                                                                                                                                                                                                              ,                  
, ,  ,+,,,,  ,, ,,     ,, ,,,,,+,,,,,,,,,,,,,,,,,,,,,,,,,                                                                                                                                                                                                                                                 
,,,+++     ,,,,,,,,,,,,, ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,++++,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,  ,,                                                                                                                                                                     
,, ,,  ,,,,,,,,,,+,,,,   ,,,,,,,,,,,,,,,,,,,,,,, ,,,                                               ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,                                                                                                                                      
,,,,,, ,,                 ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,++++,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,  ,,                       ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, ,,,,,,,  ,,,,,,,,,,,,,,,,,,,,,,,,,,                                                                          
,,+,+,,,  ,,  ,                                                                                ,, ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,                                                ,   ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,  ,,,                                          
,    , ,,                                                                                                                                            ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, ,,,,,,,,,,,,,,,,,,,,,,,,,,,                  ,,,,,,,,,,,,,,,,,,,,,,+,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
,                         ,,                                                                                                                                                                                                       ,   ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,   ,                           ,,,,,,,,,    ,,,
,                                                                                                                                                                                                                                                    ,,          ,,,,,,,,,,,,,,,,,,,,,,+,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
                                      ,,,,,,,,,,,,,,,,,,,,,,,,,,,,
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
                                                                  
,,                                                                                                                                                                                                              
,,+,                                                                                                                                                                                                             
,, ,                                                                                                                                                                                                             
,+,,                                                                                                                                                                                                             
                                                                  
,                                                                                                                                                                                                                                                                                                         
,,+++,,,++++,,,++,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,+++++++++++,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,                                                                                                                                                                                               
,,           ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,  ,,,  ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,+++,,+++++++,,+++++++,,,+++++++++++,,,+++++++++++,,,,,,++,,,,,,,,,,,,,,,,,,,,,,,,,,,,,++,,,,,,,,,,,,,,,,,,,,,,,,,,,,,  ,                                                               
,,,,,,,,,,,+,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,++++++,,+,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,                                                                                     ,,,    ,,,,                    ,,,,,,,,,,,,+,,,,,+,,++++,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
,               ,,,,, ,,, ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,       ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,+,,,,,++,,+,,,++++++,,,,++,++++,,+,,,,,,,,+,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,                                  ,,     ,,,,  ,,,,,,,,,,,,,,,,,,,,,,,,,,,
,                                                              ,,,                                                                                                                       ,,          ,,,,,      ,,,         ,,,,  ,,  ,,,,,,,,,,,,,  ,,,,,,,,++,,,,,,,  ,,,,,,,,, ,,,,,,,,,,,,,,,,,   ,,,,,,,,,,,,,,,,,,,
,,+,                                                     ,,,                            ,,,                      ,,,,,                                                             ,,                 , ,        ,,,,    ,,      ,,,, , ,,,,,,,,,,,,,,  ,,
+ ,,                                   ,,               ,, ,,       ,,,                 ,,+,                    ,,    ,                                                                       ,      ,, ,,                 ,,,,,    , ,               ,,  
,,,        ,,         ,,,,   ,,                              ,,,     ,,,      ,, ,,      ,,,,           ,,,   + +,  ,,,,      ,,,,   ,  ,,,,                                     ,,,                      ,,       ,,,     ,,,,,       ,,,       ,,  ,    ,         ,,,,   ,, , 
,,,+       ,,,,        ,,,,   ,,,   ,,,                  ,,   + ,     , +      ,,,,,   ,,  + ,           ,  ,  +,,, ,, ,,      ,,,,  , ,,                    ,,,,     ,,,   ,,,   ,,,,                 ,  ,,,,      + ,,     ,,,    ,,  , ,,      ,+,,,     ,,,      ,  ,,  ,, , 
,,,           ,,, ,+ +      ,+ ,,        ,,,,   + ,, ,, ,                 ,,,,  , ,,    + +      ,      ,,,, , ,           ,, ,, ,,,, , ,,   ,,, ,+ + ,, ,       ,,,           ,  ,,   ,, ,  ,, ,,  , ,,                ,,,  , +      + ,,       ,,   ,,, ,, ,       ,,,  ,,, , ,      ,, ,,   , ,,
,,              , ,  + ,,     ,, ,,        ,, ,   ,,,, ,,,,            ,,,  ,  ,, , ,,    , ,  ,  ,+,,,,, ,, + , ,    ,,,     ,, , , ,        ,, +, + + , ,       ,, ,           ,,,,,   ,, ,   , ,,  ,,,,                , ,, , +      , ,      ,,,,,  ,  , , ,           ,, , , ,,      ,,,    , , 
,   ,,  ,,,      + ,, , ,, ,,,  ,,,          , ,   ,,,,  ,,       ,,   ,,,,,  ,, , , ,     , , ,,, ,,   ,,  + + + +  ,,, ,     , ,, , ,  ,,++,  + ,, , , , ,      ,  ,,   ,,       ,,,,    ,,  ,,   ,  , ,,         ,,,,,  ,, , , ,      , ,  ,,, ,,  ,,  , , , ,  , ,  ,,,  , , ,, ,             , , 
,  ,     ,,,,  ,  ,,,, , ,  +,,,   ,,  ,,,    , ,,   , ,   ,      ,+,+  ,   ,,  , , , ,     , , , , ,,    ,, + + + +  , ,,,    ,, ,  , ,  ,, ,+, ,,,, , , , ,      ,,,,    ,,,  ,,, ,, ,  ,    , ,  ,,  + ,     ,,, ,,,,,+,  , , , ,      , , ,, , ,,    , , , , ,  , ,  ,,,, ,,,, , ,     ,,, ,,, , ,,
,,,, ,,+,,, ,, ,,+  , , , ,  + ,, ,+,+, , ,,   ,, ,,, , , ,+++    ,+  ,,,,    ,, , , , , ,,  , , ,,, ,  ,,,,  , , + +  , ,   , ,+ ,,  ,,,, ,+  ,, ,,,, , , , ,   ,     ,   ,+ + ,,,+ ,,,, , ,  ,+   ,,   + +    ,,,,,,,    ,  , , , ,  ,,  , , ,,,  , ,,,,, , , , , +, ,, ,, + ,,,, , ,   ,,, ,,,,,,, ,,
+ +  ,,    ,,  ,,,, , , , , ,, ,, ,, ,, , ,, ,, ,, ,, , , ,, +,  ,+  ,     ,++, ,, , , , ,,, + ,    ,, ,,,  , , , , ,  , ,,+++++, ,+, ,, ,, ,  ,, , ,, , , ,, ,,,,,, ,,,  ,+, ,,,, + ,,,, ,  ,  ,   ,,   + +   ,,      ,,,,,  , , , , ,,+, , ,,    ,  ,,   ,, , , ,,,   ,,,, ,,, ,, , , ,,,  ,        , 
,, ,  ,,,,,   , ,, ,,,,, + , , ,+  ,+ ,,,, , ,,++,  ,,,, ,,,, +, ,,     ,,,+,,  ,, , ,+ , , , +   ,,,+  ,  ,,,,  , + ,  ,,,,,     , ,, ,,   ,    ,,, , ,, ,  ,, ,, ,, , ,, +,   ,,  ,,, ,, ,  ,,,,    ,, ,, , ,,,    ,,,,    ,,, , , , ,, , +, ,,,,,, ,,  ,,,  , ,  , ,,,    , ,, , ,,  ,,, ,,,,,++,,,,, 
,, , ,, ,,      ,,    , ,, , , ,,  ,,    ,,, ,               ,+ ,,    ,  ,,    ,,  , , ,, , , ,,,  ,,   ,, ,,   , ,, ,  +   ,,,,++++,, ,,,,, ,+,,   ,, , ,,   ,,   ,, ,,,,,, ,,,   ,    ,  ,   ,  ,++,,, ,,,, ,    ,        ,,  ,, , ,  , + ,,,  ,,    ,  , ,,, , , ,,+,,,,,,,,  ,, , , ,   ,,  ,,  ,,   
, ,,, ,,  , ,  , ,,,,, ,,, , ,   ,, ,,,,,   ,+++,+,,,,++,,,,,, , ,,,,,,      ,,      ,, ,, , ,,,,,,,,,,,, ,, ,,  ,,,, ,, ,,,      ,,      ,,,  ,++,,  ,,,     ,,,,,  , ,  ,,,  ,,,,,,,,, ,,  ,  ,, ,,,,  ,  , ,,,, ,,  ,  ,, ,,, ,,,, ,, + ,,+,,  ,,,,, ,, ,,  ,,, ,,    ,    ,,,  ,    ,, ,,           
,,   ,,       , ,,     ,,, ,   ,,  ,,         ,,,  ,,,   ,,,  ,,,, ,,  ,,,  ,, , , ,   ,, ,,     ,,,,,,  ,, ,,    ,,  , ,  ,,,,,                             ,     ,, ,,     ,          ,, , ,+ ,,         ,, ,  ,,,  , , ,  ,,   ,,  , ,,     ,,,,,,,  , ,,                       ,    ,,,,     , ,    
,,,,,    ,,,, ,,         ,,, ,  ,,  ,  ,,                    , ,     ,  , ,, ,, ,  ,,,  ,,             ,  ,,        , ,, ,, , ,                            ,,,,     ,,    ,,,,,   ,  ,,  ,,  ,+,,         , ,        , , ,   ,      , ,,              , ,,                          , ,         , ,    
,   ,,          ,,,  ,,,  ,    ,                    , ,    ,  ,     ,,  ,,,,,,,          ,,,,,  ,,        ,  , ,,,,,,,                            ,,,,  ,,,,     , ,,    ,,,   ,                ,, ,    ,,,,  ,     ,    ,,  ,          ,,,,, ,,                           , ,                
,,,,            ,, ,      ,    ,,                   , ,    ,,,  ,,  ,,    ,,,           ,  ,  ,,         ,  ,   ,,,                                ,,            ,,,,   ,   ,,,                 , ,              ,  ,,,,,, ,,          , ,,  ,,                            , ,                
, ,,         ,,                     , ,         ,, ,+                   ,,, ,          ,, ,                                                             ,,,,                   , ,     ,,,, ,   , ,  ,   ,,           ,,  ,,                              ,                  
,,  ,, ,,,,,,,  ,,                 ,    , ,,         ,, ,                     ,  ,,      ,,  ,                ,,  ,,                           ,,,    ,                            ,, ,         ,,  , ,,,,                 ,,,                                 , ,,              
,+,,,  ,,   ,   ,,                  ,,,,   , ,,,   ,,   ,,,                   ,, ,   ,,,,,,,  ,               ,+,, ,,,  ,,,                     ,, ,  ,,,,      ,,,                   , ,          ,, ,       ,,     ,,                            ,,,        ,,,    ,,,           
,      ,,  ,,    ,,,  ,,                ,,  , ,,  ,   ,,,,,, ,                       ,,,, ,,,        ,,         ,,,, ,+, , , ,  ,,+,                ,,,  + + ,, ,,      ,,,,   ,,,,           ,  ,          ,,,     ,  ,,   ,,,,      ,,,         ,,      ,,,,,      ,  ,, ,  ,,,          
,++,     ,,,,,  ,,,  ,,,,   ,,      ,,,  ,,,, ,, , ,,, ,      ,,                       ,,,,        ,,,,,,,   ,,,  ,, + ,, , , ,,  + ,,            ,,, ,,,, + + ,, ,      ,,  ,   , ,,  ,,,       ,  ,,     ,,  ,      ,,  ,   ,, +      ,,,,  ,,   ,,,, ,,  ,, ,,      ,,, ,   ,  ,          
,  ,        ,,, , ,, ,, +  +,,,     , ,  ,, , ,+ ,,     ,,,,+, +                              ,,,,,, +, ,,  ,, ,,  + ,,,,, ,, ,   ,,,, ,,,   ,    ,,,, , , + +  , ,      ,,,,,   ,,,+  , ,,       ,,  ,,,,,,,,   ,,     , +    + + ,,,, ,, , ,, ,  ,,,, , , ,,,,,        , ,   ,, ,     , ,  
,  ,      ,+,,, ,,,,  + + ,, ,,     ,,    + ,,,, ,,,, , ,,  ,+ +       ,+++,,    ,,,     ,,,, ,    , +, ,,  ,,, ,  ,,,,   ,    ,, ,,,,,, , ,+,,,, ,,,+ , , , +  , ,,      ,,,    ,, +  ,, ,               ,  ,  , ,     , +    + , ,  ,  , ,   ,   , ,, , ,, ,,,   ,,    , ,,   , , ,,, , ,  
,,  ,,     ,,  ,, , ,  + + ,,,,  ,,,    ,, + ,,,, ,   ,   ,,  , ,, ,,, ,+,    ,  ,, ,  ,, ,, ,, ,,,,, ,++,    ,, ,  ,,,,  ,, ,,  + ,,, ,,, ,,    + ,,,+ , , + +  ,,,               , ,  , ,,  ,,++,,,     ,,  ,  ,,,    ,, ,    + + ,,,,  , , ,     , ,  , ,              , ,    , ,   , , ,, 
,,       ,,  ,,     ,,  ,, , ,  + +    ,  , +,  , + , ,,,,,,  ,, ,, ,, + , ,,,, ,, ,,,  , , ,, ,,+ ,, ,,      ,         + +  , ,,  +,,, , +,,,,, , ,+ ,+, ,, + + , + + +  , ,  ,,,     ,    , ,  ,,,   ,,  ,        ,,,,         ,,,,    + +  ,,   + ,  ,,   , ,  ,,, ,+++,        , ,    , , ,,, ,, , 
, ,       ,,,   ,   ,,   ,  + +  + +  ,,,+,, ,,  + +,, ,,,,,,  , ,, , , , , , ,, ,, ,  , , ,,  ,,  , ,  , ,,,,,,,,,,+,,,+, +  , ,, ,,  ,,, + ,,,,   ,, , ,, + , + + + + +  , ,, , ,,  , ,    , ,      ,  +   ,             , ,,   ,, ,    + +    ,  + , ,,+,  ,,,,     ,,  ,,       , ,    , , , , ,,,, 
,,,,         ,,,,,,,+  ,  ,,, ,  + +  ,,    ,,   +,,,, , ,, , ,,  ,,, , ,,, ,,,  ,  ,,,, ,  ,,,,         ,, ,,,  ,,,  ,,,  +, , ,  +      ,, +,,+  ,+  ,,+, ,,, , + + + +, , ,, ,, ,,,,   ,  , ,  ,,,++,,,  ,      ,,,,,,,, , ,  , , ,,,  , ,, , ,  , , +  +, ,+ +, ,, ,,  ,, ,,,    , ,   , , , , ,, , 
,  ,,,,,,,,,    ,,,  , ,    ,,  , +  ,, ,,,  ,, +    ,  , ,,,, ,,   ,, + ,     +  ,    ,, +,       , ,,   ,   ,, ,,,,  ,, ,, , , ,, ,,,,,, ,+ ,, ,+  ,    ,   ,,,,,, + ,,,, ,  ,              , ,,          ,,  ,,, ,,,,,,,  , ,,,, ,,,, , ,,,, ,, , , ,, ,,  + ,,,,      ,, , , ,,,  ,,, , , , ,  , , 
,,        ,,+,      ,, ,,,,,  , ,,  , ,, ,      ,++,,   ,, , ,,,,,,, ,, , ,,,,, +,,,,,, ,, ,,,  , ,,,,,,+,,,,,+,+,,++,,++,, ,,, ,,,,,,,   ,,, , , ,,,,,,+,,,,,  ,,  ,,,   ,, ,, ,,    ,     ,  ,, ,+++,,,,   ,,  ,,   ,,,  ,, ,       , ,   ,, ,, , , ,, +  ,, ,   ,   ,,    ,, ,      , , ,,, , ,,,, 
,,,,,,,,,,,,,+,,  ,,,        ,,,  , ,,  , ,    ,      ,,  , ,,      ,,, ,,, , ,    ,,  + ,, ,+,,      ,                        ,,,,       ,, , , +  ,    ,,,       ,,,,,,  ,, ,, ,            , ,,       ,,,,,,,,,,,,,,,,,,  ,,,,,++,, ,,,,  ,,  ,,  , ,,  , ,,,,  ,,,,,,,,,,  ,,  ,,,, ,,,  ,, ,,,, 
,   ,,          ,  ,, ,   ,  ,  ,           ,,,           + ,  ,,        + +   ,  ,,,,                            ,          ,,, ,,,   ,,,                  ,, ,    , ,        , ,                               ,,,,       ,,,,      , ,,  , ,,  ,,,       ,     ,,,,    ,,,,,,  ,,,  
, ,              ,, ,,   ,,  ,,                          + ,  ,         + +    ,,,,,,                                       ,+   ,,  ,, ,,               ,,  ,     , ,       , ,,  ,                                              ,,  ,,  ,  ,  ,, ,                                  
, ,             ,, ,,     ,,,,                           +   ,,         + +    , ,  ,                                        +,  ,,  ,,,,,             ,,  ,,      , ,     ,  ,,       ,                                 ,,,,,, ,,    , ,,  ,    ,,,                          ,       
,,,            , ,            ,, ,              ,,,            ,,,      +   ,,    ,,,  ,, ,   ,,,,,,                                         +, +,  ,,,,               ,,,         ,    ,   ,                               ,,,        ,,    ,   ,,,     ,,                                  ,       
,,,              , ,,         ,,  ,,             ++,,           +,,,,     ,,  ,,   ,+,,,  , ,,                                                 ,+,,,                                        ,,                               ,, ,,       ,+,,,,,      ,,,,,                                            
,,  ,,             , ,,,    ,,,  ,,          ,   ,, ,+          ,+   ,      ,, ,,   +,  +, ,, ,,    ,+,,                                         ,,,                        ,       ,,    ,,                           ,,,    ,, ,,         ,            ,         ,,,                                  
,, ,,    ,,,         ,    ,,                ,+,,  ,,,,           ,+   ,       ,+,,   +,  +, ,   +,  ,, ,,               ,,,,                          ,      ,        ,,,  ,,,,    ,,                    ,,            ,,,,,   ,,,,,                               ,, ,                   ,,,            
+ ,, ,,, , ,,         ,,,    ,,  ,,,,   ,,, ,, ,,    ,            +,,,,        ,,    +, ,+ ,,,    ,,,  ,,               ,,,,                    ,,, ,,,,    ,,,   ,,, ,,,, ,  ,   ,  ,,             ,,,  , ,  ,,,     ,  ,,     ,  ,                   ,,         ,  ,,                  ,, ,     ,,     
,, ,  , , ,, ,  ,,         ,,   ,        ,,,, + ,, ,+,+,            ,   ,             ,+ ,, ,,   ,,  ,  ,,               ,+ +  ,,,,              , , ,,,+    ,,,   ,,+ ,, + ,  , ,,  ,      ,,,     ,, ,  , ,         , ,,,,      , ,                  ,,,+    ,   ,         ,+,,          , ,,  ,,,,,    
+ +,  ,,,  + , ,+,,             ,,,,,,   ,, + ,,,, +, ,+,           ,,,,++,    ,,,    ,+ +,  + ,+, ,,,, +,                + + ,,  ,              + + ,+,+   ,      + + ,+ + ,, ,   ,,      ,, ,,    ,  ,  , ,   ,,   ,, ,         ,,  ,,    ,,,   ,,,   + +   ,,,   ,,,     ,, ,,     ,,,, ,, , ,   ,,    
,+ +   ,    , , , ,,   ,,                  , + ,,,, +,  ,,          ,,,    ,,  ,,,+,    + +,  ,,,     ,, +,                + + ,,,,,              , ,, + +  ,,,,,,  + +  , , ,+ ,  ,        ,, ,,    ,,,,  , ,     ,  , ,,            ,,,,,  , ,   , ,,  ,,,, ,, ,,  , ,     ,,  ,     ,, ,, , , ,,,,      
,, +  ,,,,, , , ,,    ,,,,       ,,,,,,,, ,, + , ,  +,  ,,          +       +  ,+  ,,   + +,     ,+,  ,+ +,          ,,,,  + +     ,  ,,          ,,,, , , ,, ,, ,, , ,  , ,  +   ,          ,,, ,,,       , ,  ,,,,, , +           ,,    ,    ,,  ,, ,  ,,,, +, ,,  ,  ,,   ,,,,,     ,+,,, , ,    ,,,    
,+ +  ,,, , , ,   ,+, ,, ,,      ,   ,, ,,,,,, , ,  ,+   +,         +,      +   ,+  +   + ,,   ,+,,,,  + ,,          ,, ,, + +  ,,,,    ,      ,,,,,,, , + ,,,,,, , , ,, , ,  ,  ,   ,,         ,, ,,      , , ,,   , , +      ,,  ,, ,,,, , ,,,,,  , ,  ,,,, ,,,,,  ,, ,,,   ,,,  ,,,, ,,,  , ,  ,+,,,,   
+ +,     , , ,, ,, ,,,, ,  ,,,,,,          ,, , ,   +   ,,         ,,     ,,    ,  ,,  + ,,   +,   ,, +,,+  ,,,,,,, ,, ,, + +  + ,, ,, ,  ,,+++,+, ,, + ,,, ,,,, ,,, ,  , ,  ,, ,  ,,,,    ,,,,,   , ,,,,,, , ,,     , ,,    ,,,, ,,,,  , , ,  ,,  , ,  , ,,  ,,,   +    ,       ,, ,, , ,  , , ,+    ,   
+, +, ,, , ,,,,,,   ,, ,,  ,,,,,    ,,+,,,,, ,+ ,   +,   ,,,      ,,,     ,   ,,,  +   + ,,   +,   ,, ,, +  , ,,   ,,  ,, , , ,, ,+ ,,  , ,        , ,+       ,,   , , ,, , ,,  ,  ,, ,, ,,     ,, ,,,  ,,   ,,, ,,,  , ,,,, ,, ,,,, , ,,,, ,  ,, ,, ,  , ,,    ,, ,+   ,,   ,,,,,  ,,,,,,,,, , ,,  ,,,   
,+ ,, , ,, ,     ,,,  ,    +       ,         ,,,,  ,+  ,, ,,+,,,,++,  ,,, ,,,++,  ,,   +,,,   ,,   ,  ,, +  ,, ,,,,  ,,, ,,,, ,, ,, ,,,,  + ,,++,,,,  ,,,,,++,,,,,,   ,, , ,,   ,, ,, ,, , ,,,  , ,   ,,  ,,       ,, ,,   , ,, ,,,   ,,  ,,,   ,,+, ,  , ,,  ,,,,,,,       ,,     ,  ,        ,,,        
,++, ,,,  ,,++,,,,,,,,  , + ,,,,,,,  ,,,    ,,,   +, ,,,+,  ,,,,,  ,,,,,,       ,,    ,, +,,,,,  ,, ,+ ,,  + ,,,,,,,,,  ,,,  ,,,+,  ,,  ,, ,    ,    ,,,,,, ,+,, ,,,   ,,   ,,, ,  , , , ,  ,, ,  , ,,,,,, ,,,,+,,,,  ,,,,, ,, , ,,,,  ,    ,,  ,   ,  ,  , ,, ,    ,,  ,,,, ,,  , ,   ,,,,,,       ,,,  
,       ,, ,   , +,  ,   ,, ,         ,, ,+,  ,++,,,,,,+,,   ,+,,,,,,,     ,+  ,,,,  ,,  +, +, ,, ,                ,,        , ,                        ,,,,,  ,,, ,      , , ,  ,,,,  ,,,       ,,,  ,,,    ,,  , ,,,,, ,,,,,,,,,,,,,,,,,  ,    , ,,,,,,,,,,  , ,,,, ,, , ,, ,,, ,,,,,,  ,,  
, ,,     , , ,,        +, ,+,     ,,,,+,,       ,,,,,,        ,+,    ,+, ,+, ,,  , ,, ,   ,       , ,        ,, ,, ,,,,,                   ,,              ,                                ,,   ,, ,,           ,    ,,,,,    ,    ,,        ,,  ,,  ,,,, ,,,,      ,,, ,,,,   
,,,     , ,,   ,,,  , +       ,,, ,+,                          ,,,      ,++,, ,  ,,  ,,  , ,,  ,  ,,       , ,       ,, ,, ,,   ,                                  ,          ,    ,,                , , ,  ,,                         ,,  , , ,,,,      , ,             ,,       ,,,    
,, ,    , ,,   ,   , , ,     ,+,  ,+,                          ,,,,          , , ,, ,+,  , ,,    ,          , ,,     ,, ,,   ,,,,                              ,,,,              ,  ,                 ,,  ,,,,                          , ,  , ,,  ,,    ,, ,    ,,,,  ,,,,,,             
,  ,, ,   ,  ,     ,,,  , ,,,,,,,  ,,,             ,              ,,,,          , ,  ,,,  ,, ,,        ,,      ,, ,,,,,,  ,,                                                      ,,   ,,,     ,,,           ,,                            , ,  , , ,,,,    ,, ,  ,,,  ,  ,    ,             
,+,,      ,    ,, ,, ,,           ,,   ,  ,,+,             ,,,,             ,+,,    ,,     , ,     ,, ,,        ,,,,      ,, ,,   ,,                                                     ,               ,,,                                         , ,  , ,,,,,      , ,  ,  ,,    ,,,,              
,+,,,       ,     ,  ,,             ,,,,,,+,,      ,,   ,,, , ,,                    ,++,    ,  ,,,,,  ,,        ,, ,,       ,, ,,,,                                       ,,               ,,            ,, ,,                                        ,    ,            , ,  ,,,,                       
,, ,+       ,      ,,,   ,,            ,,,        ,,,, ,,  , ,,          ,+,,       +  ,,   ,       ,,          ,, ,,    ,,, + ,,   ,,     ,+,    ,,                ,,,  ,,,,  ,,        ,    ,,,,       ,, ,        ,   ,,,                           ,   ,            ,  ,                       ,    
,, ,,      ,, ,,        ,,,, ,,,,            ,,,  ,+ + , , , ,    , ,   ,,  ,  ,,   ,,,,    , , ,            ,,, , ,,   ,, , ,, ,   , ,   ,+,,,  ,,+,              ,,  , ,, , ,     ,    ,, ,        ,,, ,,,     ,    ,  ,,,   ,,,                     ,,  ,   ,,,,      ,  ,,,,    ,,,           ,,,   
,+ ,,      ,+,, ,,,,   ,, , ,+,,,,          +, ,   + + ,, , ,,    , ,  ,, ,+,  , ,  ,,,     , ,,,, ,        ,+ , ,, + ,+, ,,  , ,      ,  +, ,,  ,, , ,,,         ,, ,,   , ,  ,, ,,     ,,,, ,,,,   , ,  ,   ,  ,    ,  + +  ,,,,                       , ,,, ,, ,   ,,  , ,, ,   ,, ,  ,,,  ,,  , ,   
,+ ,,       ,, ,,,,+, ,, ,, ,    ,,        ,, +,   , ,   ,, ,,         ,,,,    ,,,    ,,,,  ,,  , ,,    ,,,  + ,, + , ,, ,,   , ,   ,,,,,, ,,,,  ,+ , , ,         ,,,,    , ,     ,  ,   ,           , ,, ,   ,          + , ,  ,,   ,,             , ,  ,,  ,, + + ,,,,,  ,   ,,, ,+ + ,  , ,,,, + ,   
+,,+         ,,   ,,  ,,   +     ,        +,,,    , , ,,     ,  ,,,,  + ,,          ,,  ,,  ,  ,,,    ,,,,, , ,, , , ,, ,,  ,, ,  ,,         ,   + , ,,          + +     ,     ,    ,   ,      ,,   , ,, ,,       ,,,,  , ,  ,,,   ,,,            ,      ,,, + , + ,  ,,  ,,,      + , ,,,  ,, + , ,   
+,,+   ,,+,  ,+,  ,+    ,  ,,,   ,        , ,,,,, , , ,, ,,, ,, +  ,, + ,, ,   ,,,  + ,,,,  , ,  ,,   ,+ ,+ ,,,, , ,  ,, +  ,, ,  , ,,,, , ,,++  , ,  ,,,        + ,     ,        ,  ,  ,, ,  ,,,,, , ,   , ,      , ,  , ,       ,, ,    ,         ,      + + , , ,,  ,     , ,,  , ,    ,  + , , ,   
+,,+  ,,,,,,  ,,  ,,  ,+,,  ,,  ,,        ,, ,,,, + ,,,, , ,,,, ,   , , ,,+,,, , ,, , ,     , , +,,,  ,+ ,+ ,,,, , +,  , ,   + ,, , ,, , , ,, ,, , , ,+ ,,       + ,,,,  , ,       ,     , , ,,   ,    ,  ,,,,  ,,,  ,  , ,  ,,+,  ,     ,,,     ,,,  ,,,  , + , ,  ,,,+,  ,,  ,   , ,  ,,,, , , , ,   
+,,+  ,,   ,+,,,  ,  ,+, ,, ,,  ,,         ,,  ,, ,  ,,  ,,,  ,,,  ,,, ,    ,, ,, ,,,  ,,,  , ,,,  +  +, +, +  ,,, ,,,,, ,  ,+   ,,  ,,, , ,, +, , , ,+ ,+       ,,   ,  , ,             , ,,,     , , , ,+, ,  ,   ,,  , , ,, ,,  ,,,, ,, ,,,  ,,,,,,,,,,,, , , ,  ,,  , ,,  ,,   , ,  ,  , , , , ,   
+,,+  ,+    ,,    ,,++,   ,,,  ,,           ,,,,, ,,,   ,,  ,,   ,,,,, ,,,,,,  ,,     ,  ,, ,,, +, , ,, +, ,,,,   ,,    ,   +,,,,   ,     ,, ,+  + ,  +, +        ,,,,, ,,  ,,,  ,,,    ,,     ,,,,  , , +,  ,,,,   ,,  ,,,,,, ,+ ,+   ,,   ,,, +      ,  , ,, , , ,+,  , ,, ,     , ,, ,  ,,, , , ,   
,, ,+   +,   ,,,,,,  ,  ,,,   ,,,                  ,,,,,,,,,,,,,,,,     ,  ,,  ,, ,,,,,,+,,, ,,  ,  , ,,,,  ,,+,,,,,,,,,,   ,, +,,,,,,,,,,,, ,+,  , ,  , ,,         ,,,, ,,,,   ,,       , ,,,,,     , ,,,, ,, ,,    ,,, ,,     ,, ,,     ,,,  , , ,,,,,,,,,,, ,, , ,,    ,,   ,,,  +   ,       , , ,   
,+ ,+,   +, ,,+,,,,,,,,,,,,,,++,  ,            , ,                  ,   , ,    ,, ,,,,,   ,,     ,, ,, + +,      ,,,        ,, ,,          ,,,,,       ,, ,,               ,,,,,,,,,,,,   , ,         ,,, ,,+,,,, ,,,,  , , ,,,,,,  ,, , ,,, ,+,, , ,           ,,,  ,,+,,  ,,,,  ,  ,,,,  ,,,,,,    ,   
,,, ,+,   +, ,+,      ,,          ,,,,           , ,                ,, ,   ,   ,,, ,,           ,,,, ,,  , ,,                ,, ,,              ,    ,,,,  ,,         ,,,                  ,             ,       ,,,,  ,+,, ,,,,,,     , ,,      ,  , ,,,,,  ,,,,       ,, ,,++,,,,,,  ,,,     ,,,  ,,,    
,, ,+,   ,, ,+,        ,,,,,,,    ,, ,                  ,,,,       ,  ,,      ,,  ,,                ,,   ,, ,       ,,,     ,, ,,             , ,        ,,           ,,,       , ,       ,   ,,,  ,,                                  , ,, ,,,     ,,,   ,,,  ,,                  ,       ,,,             
,, ,,    ,, ,+,         ,, ,  ,    ,,,,                 ,+,,,,  ,,,  ,,,      ,                  ,,  ,,    ,  ,,    ,,,,,,,,,  ,               ,,,    ,,,,              ,        , ,        ,  , ,,                               , ,  , ,,  ,  ,     ,,,,,,,,,,,,                          , ,             
,, ,   ,+, ,,            ,,,,,,                        ,+,  ,, ,+,,,        ,  ,, ,,              ,  ,,     ,  ,,,,,,    ,,  ,, ,,+,    ,                                        ,  ,      ,   ,,                           , ,   ,,  ,  ,  ,,,,               ,      ,,,                   ,,              
, ,,,,+, ,,          ,,,                              ,,   +  +  ,,       ,,,, ,, ,,             ,  ,,      ,,     ,,,,,,,,,,  ,  ,, ,+,,   ,,,,                 ,,,,,           ,              ,                             ,    ,, ,,    ,,                      ,,,,,                                  
,, ,,, ,+,        ,, ,,,,,   ,,     ,,,               ,,, ,, ,, ,, ,,    ,+ ,, ,, ,,   ,,        ,,,,         ,,,,,,   ,,,     ,  ,, ,, ,, ,,,,+,                ,  ,,                       ,,     ,,,,,                      ,,,,  ,,                             ,,  ,                                  
,,, ,,,         ,,, ,  ,,  ,,,     ,,,                 ,,,, , ,  ,,,,,  ,+ ,+ +, +   ,, ,       ,,  ,,,,                      ,, ,, +, ,, ,,  ,,           ,,,  +   ,        ,,             , ,    ,   ,  ,,,            ,  ,     ,,                               ,,  ,  ,,, ,,,,                        
,+,,        ,, ,,,,,,,,  ,  , ,,, , ,,  ,,,,,         ,,,, + , ,    ,, ,+ ,, ,,,,   , ,,         ,,,,,,                      ,,,,  +, +,  +, ,+          ,,,,  ,, ,,      ,,,,      ,      , ,    ,, ,,  ,,,,          , ,   ,, ,,           ,,,                   ,,,   ,,+, , +  ,,     ,,       ,     
,,, ,+ ,     ,, , ,,,+ ,,,, ,,,,,,,  ,,,,  ,,,, , ,,, ,, ,,  + ,, ,,,+  , ,,      ,,+,    ,,                  ,,,       ,, +   +, ,,,,       ,, ,,  ,,,           ,      ,  ,   , ,     ,,,  ,,  ,,         ,,,   ,+ +,     ,,,  ,, +,  ,,     ,,,         ,  ,, , , , ,,,,  ,,,,,     ,,,    
,,,  , , ,   , ,, ,+ ,,,, ,,,     ,  ,,,,, + ,,,,  , , , ,,  +,,, ,,,+ ,  ,   ,,,,,,  ,,,,,,                 ,+,+,  ,,, ,, +   ,+,  ,+,      ,,,       ,,,,     ,,       , ,,,, ,,  ,,        ,, ,,                + +  ,,, , ,, ,+ ,, ,,,,    , ,       ,, , ,,     + ,, ,, ,  ,  ,,  , ,,   
,  , ,   ,,,, ,, + ,, , ,,,     ,  ,,  , + +,,,  , , + ,,  +,,, ,,,, , , ,,+,,   ,+,,  ,           ,+++,   ,+ ,,,++,,, , +    ,,,,  ,,        ,,+, ,,, ,,,    ,,     ,,     ,    , ,,   ,,,,,  ,,        ,,,     + , ,, , ,, ,  , ,, ,, ,,   ,,,       ,, ,  , ,,,,, +, ,,  ,,  ,,,, ,, ,   
,    , , ,,   ,, , ,,,, , , ,,   ,,   ,, + + + ,    , ,, +,  +,,, ,, ,  ,, ,,   ,,         ,,      ,+,   ,,   +,       , , ,,      ,, ,+       +, ,, ,    ,,  ,,,,,,,          ,  ,, ,,  ,,,,,,  ,         ,  ,    + , , ,   , ,  , ,  ,, ,,         ,,,  , ,  , , , , ,,,,,   ,,  , ,  , ,   
,,,   , ,   ,,  , , ,,,, , ,  ,,  ,   ,+ ,, + + ,,     ,  ,  ,, ,, ,,,   ,  ,+  ,   ,      ,,,,   ,,,  ,+,,,    ,  ,,  ,+ + ,,  ,,,,,, ,,       +, ,, ,,  ,,   ,     ,,  ,,, , ,   ,,  ,  ,       ,,         , ,    , , ,, ,,,, ,  , ,  ,,,,    ,,,,,,,,,, , ,, , , ,,,, ,,,  ,,,,, , ,, , ,   
,,,, ,+ ,,,,,,,,, ,,, ,, , , ,,   ,,+++  +, + ,,,,        ,,+,  ,  + , , ,   ,, , , , ,,   , ,,,,,,  ,,        ,, , ,  ,, + ,, ,,,++, ,,       ,,  ,,  ,  ,  ,,,      , ,,,    , ,, ,  ,, ,,      ,,        ,, ,    , ,    ,   ,,  , ,   ,,  ,  ,, ,,,, ,, , ,, , , ,,,+      ,,  ,,, ,, , ,   
,,,, ,,              ,, , ,, , ,,,     ,+,  ,, ,,,    ,,,,    ,,, ,, , , ,,,,,  , ,,  ,,,,,,  ,,,  ,   ,,,     +,  ,  +,  + ,, ,,    ,, ,,   ,++,  ,,,,,    ,,    ,,,,, ,,   ,, , , ,   , ,,  ,,,  ,,      ,,  ,,   + ,,  ,,       , ,,    ,+,,,,, ,, , ,, , , ,, , ,, ,,,,,, ,,  , , ,  , ,   
,,,, ,,,,,,,,,,,,,,,,,  , , ,, , ,,+++,,    ,+  ,    ,,,,,,,,+,  ,, ,, ,   ,,  ,,   ,,  ,,,      ,,     ,,,,,  ,    ,+,  ,, +,  +,  ,, , ,,,,+, ,,,     ,    ,,,,,,,,,   ,  ,   ,,, ,    ,, ,,+++,,,      ,,    ,, ,,  ,,,,    ,,  , ,    ,,   ,     ,      ,, , ,, ,,   ,   ,,   ,,, ,  , ,   
,, ,   ,,           ,    ,,, , ,,       ,     ,+,,,,,,,    ,,,   ,, ,, , ,,,,,,,,  ,+,        ,,,,,  ,       ,, ,,,,,,  ,,, ,,   ,,  ,, ,, ,,, ,++,,++,,,   ,,,           ,  ,        ,,,    ,    ,,,      ,,,,,  , , ,,  , ,,   ,, ,     ,, ,,, ,,,,  ,,,  ,,  ,,,  , ,,,,,,,  ,,,   ,, , ,,   
,+, ,,,,,,,,,,,,              , ,   ,,,,+,+     ,,,,,,           ,,  , ,, ,        ,,  ,,,,,,,,,,     ,    ,,,,    ,,   ,,  ,,   ,+,  ,, ,,   ,+,,     ,  , ,    ,,,      ,  ,   ,  ,  , ,,  ,,               ,,,,,, ,,+,,,,,,,,,,,, ,,    , ,     ,,,,,,,,,,        ,  , ,,   ,,,,,,,,,  ,,,    
,,,  ,           ,             , ,   ,,,   ,,                     ,,,,  , , ,,,,,  ,, ,+,,,,,,,,           ,+,,, ,,     ,,,,,,   ,+   ,+  ,,  ,,,  ,       , ,    , ,     ,   , ,  ,,  ,   ,,, ,,                ,,,,  ,   ,,    ,,,    , ,     ,,,,                 ,, ,    ,,            ,,     
,,,,,,,,,,,,,,,, ,             , ,,  ,     +                      ,,,   , , ,  ,  ,, ,,,          ,,, ,,  ,,     , ,     ,,    ,,,    ,,  + ,,,   ,,,     , ,     ,,,   ,   ,   ,    ,,     ,+,,,                            , ,        , ,  , ,   ,    ,,      ,   ,, ,    , ,                   
,,++,,,                         , ,  ,,   ,, ,,,                        , , ,,, ,,  ,,            ,   , ,,,      , ,          ,,  ,   ,  ,, +    ,, ,     ,           ,,         ,,,,        ,,,                             , ,        ,  , , ,,,,,             ,,,  ,      ,,                   
,, ,  ,,  , ,, ,                        , ,,   ,  ,,,   ,,       ,+    ,,        , ,       ,,,      ,,, ,, +,     ,,,      ,       ,,,   ,   ,,,                                                        ,    , ,,,      ,,     ,,   ,,                            
,,,, ,,,  ,                   ,,,   ,       ,,,    ,,,,      ,,   ,,         , ,    ,,,,  ,   ,  , ,, ,,                   ,,,,   ,,,   ,, ,,                ,,                                     ,           ,,,         ,,,,                 ,,,,         
,                             ,,  ,  ,,    ,,                   ,, ,  ,+  ,,,,,,     ,+ ,,       ,  ,,          ,  ,,,,,,,  , ,     ,,, ,,                         ,,      ,  ,,               ,,,,    ,,,      ,,,         ,          ,   , ,,  ,,,,,,                             ,, ,,  ,     
,,,    ,,,      ,,,,       ,,, ,,, ,,,,,,+,,           ,,        ,,,,  ,, ,,           + ,,    ,, ,,,,      ,,,   ,,        ,  ,,+,,    ,,     ,,,,,           ,,  ,   ,,   ,  ,  ,,           ,+ ,,   ,, ,   ,  ,,,,  ,,   ,+,,   ,,,   ,  ,   , ,,  ,      ,,,,  ,,,               ,  ,, ,,,    
+ +   ,, ,     ,, ,       ,+,+,,,,,  ,,,,,  ,,,,      ,, ,              + ,  ,,,,       ,     ,, , ,  ,,   ,,,,    ,, , ,,  ,  ,   ,  ,+,      ,   ,   ,,,      ,, , ,, ,   ,     ,,,          ,+,,+  ,  ,,  ,,, ,, , ,,,,  , ,, ,,, ,   ,, ,   ,, ,,,,      ,, ,, , ,,              ,, ,, , ,,   
+ +  ,  ,      ,,,    ,,  ,, ,, , ,        ,,  ,     , ,,        ,,,,   , , ,,  ,   ,,,   ,,,  , ,,  ,,,,  ,, ,,    , ,, ,, ,  ,,, , ,,  ,,,,  ,,     ,, ,       , , , ,,      , ,, , ,         ,,,   ,,,    ,,,, , , , ,,  +,,, , ,,,    ,+,   ,,       ,,, ,, ,, , ,,     ,,,,   ,, ,  , ,,,,   
, ,   ,  ,+,          ,,+ ,,,+, , ,  ,+,,  ,,,,,  ,    ,         ,  ,,  , , ,,,,   ,        ,, ,,,,  , ,,   , ,,    , ,,  , ,  , , , ,, ,, ,,   , ,,, , ,,   ,   , , ,,      , ,, ,                ,,    ,,, ,, , , , ,,    ,, , ,          ,,, ,    ,,  , ,  ,, , , ,     ,,  ,  ,, , , , ,,,,   
, ,    ,,  ,,   ,     + ,, ,,   , , ,,  ,,  ,,,  ,,+,  ,         ,, ,,  , ,    ,, ,, ,, , ,, , ,,,,  ,,,    , ,     ,,+,  , ,, , ,,,,,, ,  ,     ,,,+ ,,    ,,,, , ,   ,,,,, ,, , ,         ,,,   ,,,, ,,,,,, , , , ,   ,,,  , , ,,,,   ,     , ,,  ,,,, , ,,  , , ,  ,      ,,,   ,,  ,, ,,,,,   
, ,,  ,+   ,,  ,,+,  ,, ,,  ,,  , + ,,  ,,      ,, ,, ,,,,       ,, ,,  , ,  ,+,+,, , ,,,, , , ,,,,   ,,,   , ,  ,,, ,   ,, ,, ,, ,,,,,    ,,   ,+, +   ,,  , ,, , ,   ,   ,  , , , ,   ,, ,,,,, ,+ ,+ ,,  ,, , , , ,  , ,,  , , ,  ,, ,,,,   ,, ,  ,,,, ,, , ,, ,  , ,,          ,,    , , , ,   
,,   ,,,,  +,    , ,,  + ,,  , ,,,,,,, ,,,    ,   ,,,,, ,, , ,,      ,,, ,   , ,, ,+ ,,  ,  ,, , ,, ,,,,  ,,,+,  , ,  ,,,,,,,, +  , ,, ,,,,, ,, ,+   +,  ,,,,,,, , ,, , ,,  ,   ,  , , , , ,  , ,,  ,,,,  ,  ,  ,, , , , ,  , ,,  , , ,,, , ,, ,   ,, +  ,, ,  , , ,, , ,,  ,  ,,,,,,,   ,,  , , , ,   
,, , ,+   ,,, ,   ,, +, + , ,,  ,         ,   ,,,  ,   ,,+, + ,,     ,,   ,, ,,  ,,,, ,, , ,   ,  ,  , ,,  ,+ ,,  + ,  ,, ,,,,,,,  , , ,,, ,, ,,  +, ,, ,, ,,       ,, ,  ,,,    , ,, , , , ,  ,  ,,   ,    ,,,  ,,,, , , , ,, ,,  , ,   , ,  , ,  ,+  ,,,,  ,,,,,, ,,  ,,   ,,,, ,,,,,,,,,,,,, , , ,   
, ,, ,, ,    ,+++,,, ,,,+ ,,,,,,,,,,,,,,,,,,,, ,,  , ,,,,,  , ,,     ,,,,  , ,, ,    ,+, ,, ,,    ,  , ,    ,, + ,, ,  ,,           ,  , , ,, ,+, +,  ,,,,,,,,,,,,,,,  , ,     ,     , , ,     ,  ,  ,,,,,,,   ,,    , ,, ,,,  ,, ,, , ,, ,, ,, , ,,  ,  ,  ,    ,  , , ,  ,,             ,,   ,  , ,   
, ,  ,, , ,,,,  ,,,  , ,,      ,,,   ,,  ,,, ,,,, ,, ,     ,, ,       ,,,+,, ,,,,,,,,,,   ,,  ,,  , ,,  ,   , ,, ,, ,  ,, ,,,,,,,,,, ,,  ,,,   ,, ,     ,               ,,,,,,,,,,  ,,   ,  , ,  ,, ,,,,    ,,,,,,,,,, , , ,,,,,  , ,  ,,,, ,, ,  ,,,+,,,,,,,,,,,,   ,, ,,,,,,,,,,,,,,,,,,,,,,,,  ,,    
,, ,  ,, +          ,, ,,    ,,                   ,, ,,  ,,,  ,            ,   ,,   ,        ,,, ,,,  ,    ,,  , ,, ,, ,, ,,    ,,  ,,,   ,,   ,, ,, ,,, ,  , ,              ,     ,,       ,     , ,,    ,,            ,,   ,,,   ,,,      ,,      ,,   ,,,   ,,    ,        ,,,  ,,                    
,, ,,,, ,,        ,,  ,,   ,, ,, ,      , ,      ,, ,,   ,  ,,                                    ,    ,     ,, ,  ,, ,, ,,  ,,               ,, ,,  ,  ,,  ,,,                                  ,        , ,                             ,,     ,                                        , ,            
, ,,, ,,          ,,,,    ,  ,  , ,    , ,    ,,  ,,    ,,,,             ,,,                      ,   ,       ,  ,  ,, ,,   ,,,           ,,,  ,,   ,,,,   ,,                                  ,   , ,   ,,,                            ,  ,    ,                                        , ,            
,,, ,,,          ,,       ,,,           , ,,,,,  ,                     ,,,,,,                   ,,++  , ,         ,,  ,,    ,                ,,,                                        ,,    ,     ,,                                   ,,  ,,,                                                        
,,,                                   ,      ,,                      ,,  ,,                  ,+  +, ,,     ,,,   ,,,                    ,,,                         ,,,,                  ,, ,   ,,                                                                      ,                            
,                                 ,,,,,,               ,,,       +,,,                    +  +,  ,,,,,,  ,,,,,                                          ,,,    ,,  ,                    ,, ,,,                        ,,+,,                                         ,,+,                          
,+,,  ,,                                            ,,  ,,,,        ,,       ,,        ,,, ,+,,                          ,,,                            ,, ,,  ,, ,,                   ,,                             ,,   ,                                        ,, ,,                          
+, + ,,+,                                          ,,,, ,, ,,               ,+,,       +,+, ,                           ,+,+     ,                      , ,,  ,  ,,                                               ,,   +,,,,     ,,,,                               ,, ,,                 ,,,,     
+,,+ ,, ,,                                          ,,,  + +,  ,,, ,,,, ,,, ,, +  ,,,  + ,,  ,,,      ,,,      ,,,,     ,, +,  ,+,,                     ,,  ,, ,,   ,,,,              ,,,                 ,,  ,, ,,,,  ,,,      ,+ ,,                                ,,,, ,,,,           ,,   ,    
+,,+ ,+,,                                                + ,  ,, , ,  , , ,, + + ,, ,  ,,,,  , ,,    ,, ,, ,,, ,, +,    ,+ +, ,,  ,   ,+,                 ,,, ,  ,, ,, ,             ,,,+                ,,,, ,  ,,,,       ,,, ,+ ,+                            ,        ,, ,           ,         
+,,+     ,                    ,,+,,,,,            ,,,,   , , ,, ,, ,,,  ,, , + , ,,,,  ,,,,   ,     ,  ,, ,, + ,, +,     + ,  ,,,,,  ,+,+,        ,,,,    ,  ,, ,,,, + +            ,,  ,,    ,,   ,,,   ,, , ,, , ,,   ,,, ,,+, ,,,+                          ,,,,  ,,,,  + ,  ,,,      ,  ,,,    
,+ +  ,+,+,                   ,,,,,, ,,           ,  ,,  , , ,,,,       ,, , , , ,     ,,,,  ,,,,,,, ,    ,+ ,, + +      , ,         ,+  +,       ,,,,    ,,, , ,+ + + +,           ,+,,,    ,, ,  , ,,   + +   ,,  ,,, , , ,,,+ ,, ,           ,    ,         , ,,  ,  ,, , , ,,,+,     ,,   ,,   
+ +, +, ,+                  ,+       ,,          ,, ,,  ,,,,    ,,,     , , , , ,,,,  ,,,,  +,    ,, ,,+, ,,,, + +      , ,  ,,,,,  ,+, +,      ,+ ,,      , ,  + + , ,  ,,,,       ,,  ,   ,,,   ,, ,,  , ,   +,    ,, ,  ,+ ,  , ,          ,,,  ,,,  ,,,,  ,,,   ,,, , , , ,   ,,    ,,    ,,  
, ,, +   +,                 ,,        ,,         ,, ,   ,,,+   ,, ,,,,, , , , , ,,,+, ,, , ,, ,,,, , , ,, ,,,, + +      , ,  ,,  ,  ,, ,+     ,,+  ,       , ,, + + , ,  ,, ,,  ,,,  ,,,+,         ,,,,  , ,   ,,     ,,    , ,  , , ,,   ,,, , ,  , ,, ,, ,,   ,,,   , , , , ,,  ,     ,,  ,,,,  
,, ,,,, ,,  ,+                ,,,     ,   +        ++, ,,, ,, ,,,,+  , ,,,,, , , , +  ,, ,, +, , ,  , , ,  ,,,,,, + + ,,,  , ,   ,, , ,+, ,,   ,,,,  ,,   ,,,,, ,,,, , , ,  ,  ,, ,+,, ,,   ,+ ,,,,   ,,,,  , ,    ,    ,, ,,, , ,  , , ,,,  ,,,,  ,  ,, , ,   ,, ,,,,, ,,,, , , ,   , ,, ,+  ,,,    
,, ,,,,+  +   ,,,       ,,,+++++,          +       ,,    ,,,,,  ,,,,   ,,    ,  , , ,, ,+ ,+ ,,,, ,,,+ , ,, ,,,,,, , + , ,, , ,, ,,, , +,   ,,,,+  ,,  ,,  ,,,    ,,  , , , ,,   ,,+, , ,,    , ,, +, ,, ,,  , ,   ,,       +,+,, ,  , , , ,  +    ,,  ,,        , ,, ,,,+,,, , , ,   ,,,,,,,  ,  ,,  
+ , ,,,+ ,+  , ,,++,,,,+,,,,      ,,      ,,       ,,,,   ,,,,,,   ,,,   ,,,,, , ,, ,, +, ,,      ,     ,,  ,,, , ,,,, ,,,, + ,,,,, ,, +,,,,  ,,  ,,,,,,,  +  ,,,,  ,, ,, , , ,   ,   ,,,   ,,, ,, ,, ,, ,,  , ,  ,, ,,  ,,,   , ,, ,+ ,  ,, ,, ,      , ,,   ,+,, ,,      ,, ,    ,,,  ,   ,  ,,  ,  
+,,, + +, +, +,+,          ,,,++++++++,,,,++         ,,,,,,+, ,,,++++,,+,,,     ,,,  ,, ,  + ,,,,,+,,,++,  ,,   ,, , +, , ,, ,,+ ,,,,,  ,+,,,,,   +,    ,  ,+ ,   ,,,,  , ,, ,,,,,  ,,,   ,,,    ,, ,, ,, ,, ,, ,  , ,,,,,   ,,,,,,  ,,,,,,, ,, ,  ,   ,,    ,,,    ,, ,,,,,,, ,   ,, ,,,,,,,,,,,  ,,  
,+ + ,, , ,, +, ,,,+,,,,,+++,,,,,,    ,,,,,,             ,,,             ,,,  ,,   ,,,  ,  ,  ,   ,      ,,,,,,,,, ,,,, ,, ,  ,,,  ,,,      ,, ,,,,, ,,, ,  +,,  ,        ,,      ,,,,,,,,,,   ,  ,, ,  ,, ,,  ,, , , ,   ,+++,,,,,    ,,     , ,,,,    ,   ,,      ,, ,,  ,,,  ,   ,                   
,+ ,  ,  ,  +,       ,,,,,                                                    , , ,   ,,  ,    ,,                      ,, ,,               , ,  ,,,, ,     ,, ,  ,                       ,,      ,  ,, ,+ ,+     ,   ,                   ,,, , ,+  ,,      , ,,    ,, ,,          , ,  ,,,,   ,  ,      
,+ ,,,,   ,+,                                                                 , , ,,,,, ,, ,    ,        ,,,          ,, ,, ,,,            ,,,  ,,,, ,,  ,,  ,  ,,              ,,                 ,  ,, ,+      +   ,          ,,       ,  , ,,     ,  , , ,,   ,,  ,,           , , ,   ,   ,,,,      
,,     ,,,,              ,,,                                                 , ,     ,,  ,,,,,+,        , ,         ,, ,  ,, ,                  , ,    ,  ,, ,,,             ,,  ,        , , ,,,,  ,, ,,       +   ,          , ,          ,,        ,  ,,,,,    ,,             ,     ,,   , ,        
,,,,,,,                ,, ,            ,,                                   , ,,,,,,  ,,  ,,,          ,,,    ,,,,,  ,  ,, ,,      ,+,,   ,,,  , ,,  ,,,+,                    ,,         , ,      ,  ,,        ,,  ,,         ,, ,,,,,,   ,,    ,,,,,   ,,     ,,,               ,     ,,,            
,,   , ,,   ,,,     ,,,,                   ,,,            ,  ,,,  ,,,                       ,    ,,, ,,,+,      ,+ ,,  ,, ,   , ,,  ,,           ,        ,,,          ,     ,,  ,,           ,, ,+         ,        ,,,       ,,                               ,,  ,               
,,   ,,,           ,,,,  ,  ,   +,,,    ,, ,,            ,,   ,,,,,       ,    ,,    ,                          ,,,,,,   ,,,       ,, ,+   , ,,    ,  ,,,           ,  ,                            ,,             ,, ,,      ,+,  ,,,,,,,                                            ,     ,,          
,,,,  , ,,  ,,,  ,, ,,,,   , ,  ,+  +     ,,,, ,+,, ,,,, ,,,,  ,  ,,      ,,+     ,, ,,                                          ,,,  ,,    ,  ,     ,,              , ,                       ,  ,,                 ,,,       ,  ,,                                                     ,,              
,+ +  , , ,+,,,,,,,, , ,   , ,  ,, ,,,    ,,, ,, ,       ,, +  ,+,+,  ,,, , ,,     ,,,,    ,      ,,,  ,,,                      ,,,  ,,  ,,  , ,,                   , ,   ,,,                                      ,,          ,,                 ,,,   ,,,,       ,++,,                    ,,,          
+ +  ,,, ,  ,     , , ,  ,, ,,  ,,,,     ,,, ,,,   ,,,  ,, +   ,,    , ,,,, , ,+,  + +   ,,,    ,+,+, ,,,,                     ,,  ,,  , ,  +,,,     ,, ,         ,, ,   ,  ,              ,       ,,,          ,,,,,           ,,,              , ,   ,         ,,, ,,    ,,  ,,, ,,,,                 
, ,  , , , ,,,,   , , , ,,   ,, ,  ,     , , ,  ,,      ,, ,,        + ,, , ,,,  , , ,  ,, ,,   ,, ,, ,, ,                 ,,,  ,,, ,  , ,  ,,,,    ,, , ,,      ,+ ,,    , ,   ,,        , ,     ,, ,,        ,,  ,,          ,+ ,,             ,,,   ,,,,       +,,+,   +,,  ,,, ,, , ,+,,            
,,,  ,  ,,, , ,, ,   , , ,,, ,,, , ,,,,,    , , ,,,,,, ,,,  , ,+   ,,,  ,,,, ,  ,,,   , ,  ,+  ,   ,, ,,  , ,  ,,,       ,+,  ,,,,   , , ,, ,  ,,,,    + , ,,+,     ,    ,,  , ,  ,,,,       ,,,     ,,,,         + ,,             + ,,                 ,            ,,,,   ,, ,, + ,  , , ,,,,     ,      
,+ ,  , ,+      ,,,   , ,  , ,, ,   ,  ,  ,  ,,,, ,  ,, , ,  ,, +,  ,,,, ,,,, ,,,   ,, , ,   ,,,,    ,+,,  , ,  , ,,     ,,,+, ,, +   , , ,  ,, ,,,,   ,, , ,, ,,    , ,,, ,  , , ,,  ,           ,,,             ,,,,  ,,  ,,,     ,,,     ,,,        ,,,,  ,,,   ,,,    ,,  ,,,, + ,, , , , ,,    ,,,,    
,+ ,  , +, ,  ,,      , ,  ,  +,,,, ,  ,  ,, ,,  ,   ,, , +  ,, ,,  ,,,, ,,,, ,,, ,, , ,,,,       ,,       , ,  + ,+  ,, ,+ ,+ ,,,,   , , ,,, , ,, ,   ,, , ,  ,, ,, , , ,, , , , ,   ,   ,      ,, ,  ,,+,       + +  ,,+, , ,,  ,,    ,,  ,,,,       ,, ,, , ,  ,+ ,,  ,,,, ,, , , ,  , , +,,,     , ,    
+ ,  , + ,   ,,,++,,,, , ,,, ,       ,   ,  +      ,  , ,, ,+, ,,,,, ,, , ,,,,,, ,, , ,, , ,,,,,,,,, ,,,  + + ,,  ,,,,,,,, ,+ ,, ,,,,, ,   , + ,,,,    , ,,,  ,,,,,,, ,,,, , , , ,,  ,,  ,  ,,,,   ,, , ,,       + , ,, ,, , ,, ,+++,,,,,, ,, +,,,,,,, ,  , , ,, +, ,,  + ,,  , , , ,, , ,       ,,,  ,    
,+ , ,,,, ,,,,,   ,,,  , , ,, ,,,+,,,,,, ,, ,,   ,++,,,,,,  ,, ,  ,,    ,, , , ,, ,, , ,, ,,,, ,,,  , ,,,, + + +            ,,  ,,,+,, ,, ,,  , + ,     ,,   ,,         ,,   , ,, ,,   ,,           ,, +, +       ,, ,,, ,, , ,,,,   ,,  ,  ,,  ,,,,,,,,,  , , , ,,   ,,,,  ,,,,,, ,,,, , , ,,,,  ,   ,     
,,, ,,        ,,        ,  , ,,,,   ,, ,,,  ,,, ,,    ,,,   ,,+,,,  ,,,,  ,    ,  ,  , ,,         ,,, ,, , , , ,,+,,,,,,,,,,,   ,,   ,,,  ,,,, ,,,,      ,,,,,,+,,,,,,,,  ,,   ,  ,  ,,,,  ,  ,,, ,,,  ,, ,        ,    ,  ,  , , ,+,, ,,, ,, ,, ,,         ,  , ,,,,     ,,    , ,, ,, , , ,, +, ,      ,  
,  , ,,,+,,+,+           ,, ,    , ,         , +,           ,,  ,,,,  ,,,, ,,,   ,,,, ,,,,,,,,,,,,,  ,,,, ,,, ,,,       ,  ,   ,,,++,,    ,,,  ,,              ,,  ,,,,,,,,,,,  , ,,,    ,, ,  ,,,   ,, ,,        ,,+,,,,, ,, , ,,,,++,,  , ,,,,,,+,,,,,,,,,,,   ,   ,,,,,,,,,,  ,  ,  , , ,, ,,,,   ,     
,,,,,,   ,,  ,,,      ,, ,    ,, , ,,,    + ,,          ,,                     ,,,                ,, +          ,,,,,  , ,  ,, +,                          ,,  ,              , ,      , ,       ,,, ,,                 ,,  , ,        , ,,             ,,,     ,,,           ,,,,  , ,, ,,     ,   ,,   
,,          , ,     ,, ,,    ,,,, , ,   ,  +          ,,+,                           , ,        ,, ,,         ,   ,,   ,  ,, ,,                          ,    ,             , ,       , ,          ,,             ,,,,,   , ,   ,,   ,  , ,,  , ,,  ,         ,  ,       , ,     ,, ,, ,+ ,+,,,,,,,,,   
, ,,   ,, ,,     , ,      ,,  ,           +  ,,                          , ,,      ,, ,, ,,,,      ,            ,,                           ,,,,,              , ,      , ,       ,,,,,              ,     ,,, ,  , ,,,,  ,  ,   , ,   ,         ,,,,       , ,   ,,  ,  ,, ,,      ,,,    
, ,,,,  ,,      , ,     ,, ,,      ,,,   , ,,,               ,,,         , ,,   ,,, ,,  , ,,       ,,,     ,,,,                                                , ,      , ,                          ,,,,,,   ,,  ,,    ,,   ,,,   ,             ,,         ,  ,,,      ,  ,,              
,     ,,       ,,  ,,,,,  ,       ,, +   ,,,,          ,,,, ,+,+,        ,   ,,,,, ,,   ,  ,,     ,, ,      ,,                ,,                               ,     , ,  ,          ,,,             ,,   ,   ,    ,, ,,,            ,,,                     ,,   ,,      ,,               
,, ,,,,,       ,, ,          ,,,   ,+ +      ,      ,,, ,, , ,+ ,,       ,  ,,    , +   ,, ,  ,    ,, ,                       ,++,                  ,,,               , ,  ,    ,     , ,, ,,,,            ,,  ,    ,, ,  ,,        ,,  ,                       ,,,   , ,,,     ,,          
,, ,,,          ,+ , ,,,,   , , +    , ,            ,,,+  + ,  +,,,   , ,,,,  ,,,,,, +    ,,,, ,     ,, ,,             ,,   ,, ,, +                  , ,,           ,,,,    ,   , ,    , ,,,,  ,   ,,       ,,  ,,   ,, +  ,,,         ,,                               ,,      ,,,,         
,,,              + ,      , , + +    , ,    ,       ,, ,, , ,  ,, ,     ,  ,       , +         ,,,,  ,,  ,     ,,,     , , ,,, ,+ +                  ,, ,  ,,         ,,    ,   , ,   ,,     ,    ,,,,      ,,  ,,   ,+ + ,, ,,                                                 ,,,,   ,,    
, ,,            , , ,,,  + , + , ,    ,   ,,,,,,    , ,, + +   , ,     ,,,   ,,,  , ,      ,,    ,,   ,, ,,  ,, ,     ,,  ,, , + ,                   , , ,                     , ,   ,,    ,  ,, ,,,,     ,+ ,, ,    , ,  ,,,,      ,,,                           ,,,,   ,,,   ,,,,  ,,,,   
,+,+,        ,,,, , ,,+, , , + ,      ,  ,, ,, ,, ,,, ,, , +   , , ,,  ,  ,, , ,, , ,,,+,  ,  ,,, ,     ,,+, ,,,, ,,      ,+ , , ,                   , ,  ,,      , ,,,        , ,    ,   ,  , + ,, ,      + ,,,,    , ,  ,,,, ,,,, , ,,                          ,, ,   ,,,      ,  ,, +   
,++, ,, ,, ,,,,,,,  , ,  + ,,, , ,   ,, +  ,  , , ,,,   ,  , , ,,, , ,,,  ,+,,  , , , ,,,,,, + ,, ,, ,    + ,+     ,,,,  ,,, , , , ,          ,,       , ,       ,, , , , ,  ,,,        ,,  ,  + ,, , , ,,,  + ,,  ,+, , ,  ,  , ,,,, + ,, ,,,                      ,+ +  ,, ,,  ,,,,   + +   
,,   ,,+,+,,,,,  , ,,,   ,,,,, , ,  ,,  +, ,,,,,, ,,,   ,, , , +,  , + +,,,, ,,, ,, + ,,, ,, , ,, ,, ,,+, ,, +  ,,+, ,,  , + , , , ,         ,,,,  ,,  , ,   ,,,,     , , , ,,,,         ,, ,, + +, , , +,,, , ,  ,, , , ,, ,,,,, + , , , ,, ,,                      + +  ,+ ,,  ,,  ,  , +   
,+++, ,         ,, ,     ,,, , , ,  +   + ,,            ,, , , ,  ,, ,  ,,    ,, , ,+ , ,       ,,,  ,, ,,, ,,  + ,  ,   + ,,, , + +         ,, + ,,,, , ,  ,,       ,  , ,,, ,,    , ,,,  , , , ,  , ,,, ,, , ,  ,,+, + ,, ,,  , , + ,,, ,, ,                 ,,    + +   ,,,,   ,, ,  , +   
,,,,,,,,,,,++++, ,,,,,,,,  ,, , ,  ,+,,, , ,,,,,,,,,,+,,,  ,, ,,,, , ,,,,,,,,  ,, ,,, ,, ,,,,,,   ,,, ,,  ,+, ,, ,,    ,,    ,, , ,       ,,, ,,  + + , ,, ,,      ,  , ,,,, ,, , , ,,,,,,, ,,, ,  , ,,, +, + ,,     ,,  ,,,, ,, + + ,,,, , ,,          ,,,, +,,,   , ,    ,,     , ,  , +   
,,,  ,,      ,,,,,  ,+,,  ,,,   ,,, , ,,  ,,,,,  ,,,,,,,      , ,,,,,, ,+,,,   ,    +,,,,  ,,   ,,   ,,  ,, ,,     , ,,,,,  ,,,      ,,  ,,  ,, + ,   ,,          , , ,, ,, , , , ,,     ,, , ,, ,,, ,, +  ,,,,++, ,,      ,,, + ,,,, +,,+,        ,+,,,,, ,,,  , +      ,    , ,  , ,   
, , ,           , ,            , , ,,,                   ,,,  ,,       ,,   ,,,,  ,,       , ,  , ,    ,, ,,       ,,      ,,       , ,,   ,, ,, ,,,   ,,,            ,,,  ,    ,,,,,,,, ,,, , , ,, +, , ,, ,,    , ,,,,,    ,, , ,, +  ,,        +         ,, + ,,,,,,,,,,,,, ,  , +   
,   ,,           , ,            , ,  ,                   ,   ,,,         , ,     ,,         , ,  ,,  ,,,, ,,    ,  ,  ,,,        ,  , ,,  ,,, ,,   ,,,,,, ,,,,         ,,,  ,    ,      ,,,,  ,,, ,,+,  ,,,,,,,,,, ,,  ,,,,,,,,  , ,  ,, ,,       ,, ,,,,,,,,,, ,  ,,,     ,,,  ,, , ,   
,,,                             ,    ,                    ,,,,           ,,, ,,,,              ,  ,   ,  ,,  ,   ,,  ,  ,        ,  , ,     ,,,                                 , , ,                       ,,,,,+++,            ,,    , ,,      ,, ,, ,,  ,,,, ,,,, ,,,,,,, ,,,,, ,,,   
,    ,                                    ,         ,,,         ,,,    ,,,   ,,,,,  , ,,,       ,,, , ,    ,,                                   , , ,                             ,,                  ,, ,,      ,,,,            ,,,,,,,+,,,,,,,,,       
,   ,                                              , ,,      ,,   ,,,,,            ,,,         ,,,    ,,, ,                                    ,  ,                                              , ,,, ,,    ,  , , ,,, ,                               
,,,                  ,,  +,                                             , ,,        ,,,,,,                          ,,     ,  ,,                                ,                                                      ,   ,,    ,,  , ,     ,                               
,,                    ,,,,                  +  ,,                                             ,  ,                                         ,      ,,,                                    ,,                                                ,,  ,,      ,,, , , ,,,,                                
,++,                   + ,,       ,,,        +,,+          ,,                     ,      ,,    ,, ,,              ,,,,               ,,     ,,  ,,                             ,,   ,,, ,  ,                     ,,,                         ,,,        ,,  , ,                                     
,+ ,+             ,,,,  ,,,,       , ,        ,, ,         ,,+,                   ,,,,   ,,,,    ,, +             ,,  ,       ,,     ,,,,     ,, ,,  ,,,                 ,        ,  , ,, ,      ,,              ,, ,  ,,                                 ,    ,  ,,,                                
,+ ,+             ,,,,  ,,,,  ,,     ,        ,, ,  ,,,    ,,,+                   ,, ,   + ,,    ,+ + ,+,,        ,,,,,      ,,,,    ,,,+  ,,, ,, ,  ,,+,              , ,   ,  ,  , ,, ,       ,,,,     ,,,     , ,, ,,,,                                ,    , ,, ,                                
,+,+,     ,,,,  ,,,, +  ,,,,,,,, ,,,,,,,      ,, , ,, ,    ,, +  ,,+,             ,, +   ,, ,     + , , ,,        ,          +,,+    ,, + ,,,,, , ,  + ,,              , , ,,,   , ,  , , ,  , ,,  ,,  ,+,,,    , ,,  ,, ,               ,,,     ,,,       ,  ,, ,, +   ,,           ,,              
,+,,, ,,,  ,,  ,, ,, ,  ,  ,  ,,      , , ,,+,    ,, +  + ,,      ,+,   ,, ,,   ,,,, ,,  + , ,,,,        ,,,,       ,+ ,,   ,, + ,,,,  + ,, ,,,,              , , ,  ,  , ,  ,    ,,,  ,      ,  ,,   ,, ,   ,, +  ,,,+,,   ,   , ,,   ,, ,,       ,,,, ,, ,  ,,,,   ,,    ,,,,             
,,,,,++++++,  , ,,    ,, ,, , ,, , ,,,,, , ,,,  + +         ,, , ,+ ,,      +,++  ,,,,         ,,, + , ,  ,        ,,,+,       ,+,     + + ,,    +,,, ,,,,              , , ,,,   , ,  , ,        ,,,   , ,,   ,+ ,,,   + +  ,,,  ,  ,,+, + ,,    + ,,        ,    ,  , ,, ,  ,,,    ,, +             
,+,         ,, ,,,    ,,, ,,,,  ,,, ,,  , , ,,,, + ,+,,,,,++,,,,, +, ,, ,,,, +  +,     ,,, ,,,,,, , + + ,,,+,       ,, ,,            ,  + +  ,,+, ,,,, ,,,,          ,,  , ,    ,,,, ,, , , ,,,,   + +, ,, ,,  ,,    ,,  , ,  ,+ ,+,  ,  , ,,,,    , ,,    ,,     ,,   ,, + , ,, ,     + ,             
,, ,,,  ,,+++++,   ,,,  ,, , ,  ,,,    ,,, , ,,,,,+   ,,  ,   , ,,,,   ,,,,,,,,  +,  ,,+,,,,+,,,,  , + + ,+ ,+      ,+  ,, ,++,,,,,,,,,, + +  ,,,+ ,,,, ,,,,  ,,,,,,,,,,, , ,  ,,,     , , , +,,+  ,+ +, ,   ,, , ,,,, ,  , ,   +,+,   ,+ , ,,,,    , ,     , ,   ,, , , , , , , , ,,,, , ,   ,,,,,     
+ ,+,+,,+,     ,, ,,,,+,,, ,,, ,, ,,+,,   ,, +,,,,+,+,,,,,,,,,,,, ,,,,,    ,    ,,  ,,  ,,   ,  ,,,, , +  +, +     ,+    ,++,,,,,,,,,  , + +, ,, ,,,,,, ,,,,            , , ,, ,,  ,,,,, , , +, +  +, ,, ,,  ,, , ,  , ,, , ,   , ,     , , ,,,, ,  , ,     ,     ,+ , , , , , , , ,    , ,   +,   ,    
,, +   ,,,       ,,+,           ,,,+,   ,,,  ,+ +  ,,,,,,,,,,  ,,  ,,,  ,,,,,,,,+,   ,, ,,,+,++++,,  ,,,,  ,,,+     ,,,++,   ,,  ,,  ,,,, + ,+,,,  ,, , ,+ ,, ,   ,,,,,,,, ,   ,,         , , ,+ + ,, , ,,,,  , ,, ,,,, ,,,, +,,,,, ,++, , , ,,, ,,, , ,  ,,    ,   , , , , , , ,,, ,,,, , +  ,+ ,       
+ ,,           ,,      ,,,,    ,, ,   ,,     , ,,            ,,      ,,           ,,,,,,,             ,,  ,, +,      ,,,,,,,,,,+++,+++,, ,,,  ,  ,,  ,, , ,, ,,  ,,,,,,    , ,        ,    ,  ,, + ,,++,    ,     ,  ,  , ,,  ,,,,,,,, ,,, , , , , , , , ,,,, ,,,,  , , , , , , , +      , +   + ,,      
+ +          ,+, ,     , ,,  ,,, ,,  ,, , ,,, ,,     ,,  ,   , ,   ,+,,,,,,,  ,,  ,  , ,  ,, ,          ,,  ,,           ,                ,++,,,,,,,,,, ,,,  , ,,          ,,,,,,,,,,,,      ,, ,,     ,,,,,,,,,,,,,,,,,  ,,,,   ,   ,,    , , , , , , , ,  ,,, ,,  , , , , , , + ,,,,,, + + ,,,  ,,     
, ,         ,,  +,     , ,,,,,  ,,    ,  ,,  ,,     ,,   ,   ,,    ,, ,    ,  ,  ,,, , , ,   ,       ,,,,  ,,            , ,  ,,             ,,   ,        ,, ,,                    ,,      ,, ,,        ,,    ,,   ,,,    ,,,,++,++++,,,,, ,,,, , , , , ,,     ,, ,,  ,  , , , ,,  ,  , + , ,,,    ,    
, ,         ,,,+,     ,, , ,  ,,,      ,,  ,,,       ,,            ,,,,,,,,    , ,,,  ,   ,,,        ,  ,,,              ,,,,,,               ,    ,      ,, ,,  ,,                           ,,                                       ,,,   ,  , ,, ,,, ,  ,,,,, ,, ,,,,,  ,,,  ,,,,,,, , , ,   ,,,,    
, ,,         ,,       ,,,, ,,,,         ,,                                       ,,,  ,               ,,,                                     ,    ,    ,,  ,, ,, ,                         ,,                                                ,, ,,     ,, +,,,    ,,,,,,         ,,,,,  ,,, ,,,,,,,     
, ,,,,               ,,,                ,,,          ,,,,                       ,,   ,                                                        ,  ,,,,,,  ,,     ,,          ,,                                                           ,,,,, ,,   ,,,  ,,                                  ,,,        
,,  ,,                  ,               , ,,  ,,    ,,   ,,                  ,   ,   ,   ,,,         ,,,     ,                                ,,               ,           ,,,,                                                          ,    ,,    ,  ,,,                                              
,+, +   ,,,           ,,,              ,,,, ,+,,  ,, ,, ,+, ,,, ,,+        ,,+  ,,  ,,  , +        ,,,+,   ,,,             ,                   ,,,,,,, ,+,                ,  ,               ,,,                                        ,,,,,      ,,,,                                                
,+ +, ,+,,           + +,             ,, , + ,, +, ,, ,,,, ,,+ , ,, ,,,   , +,  ,,,,,  + +,       +  ,,  ,  ,      ,,,,  , ,     ,,                  , ,,   ,,,          ,,,,               ,,+,                                                                                                      
+ ,  +, ,,          + +,              , , + ,, , ,,  ,, + + , ,, ,,,,,   +,,,         ,,,,       ,,,,, ,  , ,,,   +,,+  ,      ++,,                 ,,,,  ,, ,                         ,,  +,,,                   ,,,  ,,+,                                                  ,,    ,,,               
, ,, ,  ,,     ,,,  + ,,              , , +, , +, ,,  + + ,,,,,,   ,,,   ,,,,   ,,,,  ,,,,       ,,,, ,, ,     , ,+ ,+    ,,  ,+ ,+                 ,, ,  ,,                      ,,, ,, , ,, ,                  ,+ +  ,  ,                       ,,,,                      ,,,,   +,,,              
,,,, ,,,,,    ,+++, , ,, ,,      ,,,  , , ,++,  ,, ,  + + ,,,, , ,,      ,,,,   ,,,,  ,,,,           ,   ,  ,,,   +,,,  +,,,,  +,,,                 ,, ,     ,,,               ,, , ,, , ,  , ,           ,,,,    + +  ,,,,   ,,,,                ,, ,                      ,,,+   + ,+              
,,,,  ,,, ,,  +,  ,, ,,,,,,,     , ,, , +        , ,, + + , ,, , , ,,,   ,,,,   + ,,  ,,,, ,,,  ,,  ,, ,,  ,      ,,,  ,,  ,,  ,,,  ,,,             ,, ,  ,,   ,,             ,,  ,, , , , ,, ,          ,,  ,    + +    ,,   ,,,+,  ,       ,+,,  , ,   ,,,,,              ,,,,   ,,,,      ,,      
+,,+,    ++++,,    , ,,,,, ,     ,,,, + +,, ,,,  , ,  + , , ,, , , , ,,  ,,,,  ,, ,,  ,, , , , ,,,, ,,,, , , ,,,    ,,,,,  ,        +,,,        ,,, ,, , ,, ,,, ,,      ,,,,  ,, , , , , , ,, ,           ,,,     , ,  ,,,+,  +, ,, ,,+,    ,,  ,, , ,   ,   ,               ,,,    ,,     ,,,,,     
,,    ,,,,,     ,,,,   ,,,, ,     ,,,, +   ,,, ,,,  , ,, , , ,,,, , +  +  ,, ,,,+, ,,  ,, ,,, , ,, +,,,,,,, , , ,  ,,,,,,   ,,,  ,,,,, ,+        ,,,, + +,,, , ,, ,,,,  ,, ,,, ,, , , , , , ,, ,     ,,,       ,   , ,,,,  ,,  ,+,+ ,+ ,,    ,,   , , ,    ,        ,,   ,,,  , ,  ,,  ,,,, ,  ,,     
,++,,    ,++,,++,   , , , ,,    ,, ,  ,,+,   ,,  ,,  ,,, ,,,    ,, ,, ,  ,,       ,,  ,,     , ,+       ,,    ,, ,, ,    ,  ,,  , ,,  ,,        ,, , , ,,,, ,,,,  ,,,, ,    , ,, , , , , , ,, +,,,, , +,,,,,,,,,  ,,,, ,       ,,  ,, ,+  ,,,  ,,, , ,     ,,,    ,,,, ,,,,  ,,,,,,,,,,, , ,,        
,,,,,,,,    ,    , ,,  , ,    ,, ,,     ,,+,,,,,,    ,,  ,,,,,,  ,, ,, ,, ,,,,,+,,, ,, ,,,,,  ,, ,,,,,,,,,  ,, ,  ,,, ,+,,+,,  + ,,,,+,        ,, ,,,,,        ,,  ,   ,,,,, ,  ,, , ,,,, ,, ,,,,,,, ,,,,,,,  , ,,   ,, ,,,,   ,,+,  ,,,,+,  ,    , , ,,,,, , ,,,,,,, ,, , ,,          ,,  ,,,,     
,,    , ,   ,, ,    ,  ,,                              ,, ,, ,, ,, ,,,,,,  , ,,     ,, ,,,   ,,,,  , ,, ,  , ,,  ,,  ,, +,           ,, ,, ,++,,,,,,,,,,,,, ,,,,    ,,,   , , ,  +  ,      ,,  ,,    , ,,         ,,,+,,     ,,,   ,   , , , ,,,, ,, ,,,  ,, ,, , , ,,,,,,,,,,,   ,, ,     
,   ,            ,, ,,,  ,                             ,,,, ,, ,, ,,         ,  ,   ,,,, ,,         ,,, , ,  , ,,      ,, ,  ,,,        , ,,          ,,                  ,     ,, ,, +,+++,,,,,,,,,,,+,, ,+ , ,,+,,,, ,  ,    ,   ,, ,   , ,, ,,  ,,, ,, ,,,,, ,, , , ,,        ,   ,, ,     
,,             ,,      ,,                           ,, ,   ,  ,, ,         ,,    ,,    ,,,          , ,, ,, ,  ,      ,, ,  ,  ,       , ,,                            ,          ,, ,                     , ,, ,  ,, , ,+++,,,,+,+,,,,, ,  ,  , ,,,,  , ,,     ,,,, ,, ,,,,      ,, ,, ,     
,,,,                           ,,,  ,,   ,, ,,, ,     ,, ,     ,,,,,            ,,   ,  ,, ,     ,, ,, , ,,      ,, ,,                             ,        ,,  ,    ,,               ,, ,,      , ,,  ,,,, ,,,          ,, ,,    ,, , ,,,  ,,,   ,,    ,,,,,  , ,, ,     
,,, ,, ,,  ,,,  ,, ,  ,,,,,  ,, ,,,,,                 ,   ,   , ,,, ,,  ,,  ,,,    ,,,, ,,                              ,      ,,, ,,      ,  ,, ,,,      ,, ,,      ,, ,            ,,,   ,,,,  ,    ,, , ,,,,,        ,,,,,,       ,, ,,     
,,,                 +,+  ,,       ,,    ,,     ,,                         ,   ,   ,, ,,,  ,,           ,   ,,                               ,        ,,,      ,,     , ,     ,, ,,       ,,,,           ,  ,  ,      ,,,,,  ,  +   ,                 ,     ,,      
,,,       ,,,  , ,  ,,             + +,  ,,,,,,,,       ,,,,,,     ,,,,                  ,   ,    ,+   ,,,            ,,,,                                  ,,    ,,,               , ,,,,,,, ,,   ,,,   , ,           ,,,,  ,,,,,,      ,,   , ,,,                 ,   ,,,       
,,,, , ,,,,,   , ,, + , ,,+,  ,,        +,,,  ,   ,  ,,,                , ,,   ,,,             ,  ,     ,, ,         ,,,                                                                  ,  ,,   ,,   ,,,,   , ,,      ,,              ,,,,,,,    ,,,                    ,,,,         
,, ,, ,,, ,,,,  + ,, + , ,  ,, ,,  ,,,   ,,,, ,,,,  ,,, ,   ,,,,         ,, +   , ,     ,,,,    ,, ,      , ,,       ,+ +,           ,,,              ,,,          ,  ,      ,,            ,,   ,,,     ,, ,    , ,,    +,,,        ,+,,   ,               ,,,                          
,,          ,, ,  ,,,,,, ,  ,,,, + , +  ,, ,, ,+,,,  ,,,,,,  ,  ,  ,,  ,+, ,,        ,+ +          ,,, ,     , ,,     ,,,+  ,,,, ,,,,       ,,,  , ,,             , ,        ,           ,,,             , ,,  ,,,  ,,,, ,,  ,  ,, ,  ,,  ,,,   ,, +,   ,,,           ,,,,,                         
,,             ,  ,  ,,  , ,  ,,,, + + ,++,     ,, ,,  ,,,, ,  ,  ,,,,   , ,+,    ,,,   + +  ,,,,,,  , ,,,     ,,,,     ,+ +  ,  ,  ,,, ,,   ,,,,, ,,,, ,,,         , ,      , ,       ,,  , ,,  ,,        + ,, ,,        ,,,,        ,,    , ,,  ,, ,,   , ,,          ,   ,       ,,,               
,, ,,            ,,,     ,, ,  ,, , + +  ,,  ,,, ,+ ,,  ,,,, ,  ,         ,,,      ,,+,  + + ,,    ,, ,,        ,, ,      + +  ,,,      ,,,,  +, ,, ,,,, ,           , ,      , ,,     ,, , ,,, ,,,,   ,,,, +,,, , ,,  ,,,, + + ,,       , , ,, ,  ,,,,,   , ,,          ,,,,,  ,,   , ,,              
,, ,   ,,            ,,,, + ,,  ,, , + ,,  ,, , + ,, ,,  ,,,+  ,,   ,,,,      ,,,   + ,,  , , ,, ,,, ,  ,,,, ,,,  , , ,,   , +    ,,   ,,,,,,  ,,,,, ,,,   ,,                  , ,    ,  , , ,,    ,,   ,  , ,,,, ,     , ,, + + , ,      , ,  , ,          , ,,           ,,    ,,,  ,,,,              
,, ,  ,+,,,        ,,,+,,,,,  ,, ,,,, +, ,,+++,, , +, ,,  ,, ,,   ,,,,, ,,,,,,,,,,  ,, ,,  + , ,,,, ,,,, ,  , , , ,, , ,,,  , ,  ,,,,, ,,,, ,,  ,,,,  ,,,                   ,,, ,,   ,,,, , , ,,  ,     ,, ,        ,,,, ,,,, , , ,,, ,,        , ,   ,,,+,    , ,,    ,,,    ,,  , ,, ,, , ,,,          
, ,, ,+, ,,,,,,,++,,,         ,, , , ,+          , +  ,,, ,  ,  ,   ,,   ,,,,,,  , ,,   ,,,, ,,,  ,,,, , ,, , , , , ,  , ,, ,,,, ,,  , ,,  ,,      ,  ,,,, ,,,              , ,,     , ,, , +  , , ,,,,  , ,  ,++, ,,  ,, , , , , ,   ,,,  ,,, ,, , ,,,,   ,,,,,,,,,   +,+   ,,,, , ,, ,,     ,  ,,      
, ,  ,,             ,,,+,,,,,,,,  ,, ,, +,,,++,,, ,+,,, , ,,+,,, ,,,  ,,,   , ,,,, ,,,,, ,, ,    ,      ,,  ,,, ,  , ,,  ,, ,,,+, ,  , ,,        , ,, ,,,,,, ,,             ,  ,,,,, , ,, + + ,, ,,, ,,  , ,, ,, +, ,, ,, , + , , ,     ,  , , ,, + ,,  ,,,        ,  ,+ +,  ,,,, ,,,, ,,  , ,  ,,,,     
, ,, ,, ,,,,,,,,++,,,,,,,,  ,,,  ,, ,, +, ,,   ,   ,,,,,,  ,,  ,,,,,,,,  ,++,,,,,  ,,   ,,,,,,,,,,,,,,,,  ,,   ,,   ,   ,  ,,   ,,         ,,,,  ,, , ,,,, , ,,        ,    ,,           ,, , , ,,,+, +, ,, , ,, ,,,,  ,,,, , , , ,,     ,,, ,  + ,,    ,  ,,,,,,,,,  ,, ,,, ,  ,,, ,, ,,,,  ,,,, ,,     
,, ,,,, ,,               , ,       ,, ,,                          ,    ,             ,   ,,,   ,   ,,   ,,  ,,  ,   ,,,+,,,  ,+,  ,,,, ,,,,  ,,  ,,,, ,,,  , ,,       ,  ,  ,  ,,,,,  ,,  ,,, , ,  ,, +   , , ,,      , ,, ,, , ,  ,,,,,    ,,  ,        ,, ,,,,,,,  ,,    ,,    ,, ,  , ,,,, ,,  ,,     
,,    ,,                , ,      ,  ,   ,                      , ,    , ,         ,, ,,                 , ,  , ,    ,,   ,,,,  ,,,         ,,   , ,   ,  ,   ,     ,, ,,  ,  ,,    ,,,,,,,   ,,   , ,,  , ,  ,, ,,,,,,,  ,,  ,,,  ,,,,,,,,,,   ,++,,,,,, ,,         ,,,,,   ,,,,  ,,  , , ,,   ,,,,     
,,,,,,                 ,, ,, ,,,  ,  ,,  ,        ,,          ,,,    ,,          , ,,                   ,,  ,,,                    ,      ,   , ,,    ,     ,    ,, ,,  ,  ,                  ,,,  ,  , ,, ,, ,,,,   ,,,,   ,,                    ,    ,,          ,,    ,,,    ,    ,,  ,, ,,,,       
,   ,,  ,,  , ,                                          ,,,                                               ,  ,   , ,, ,,  ,,,  ,    ,,           ,               , ,     ,  , ,, ,, ,,                                     ,               ,,, ,    ,          ,, ,,          
,,,  ,,   , ,,                                                                             ,,,            ,       ,  ,       ,,   ,     ,       ,                , , ,,,, ,, ,, ,, ,,                                      ,,,            ,, ,,     , ,       ,, ,,           
,,,     ,,,                                                                             ,+,,,           ,  ,,,,,  ,     ,,,      ,,,,                          , ,,    ,, ,  ,, ,,                                        ,             ,,,,      , ,      ,, ,,            
,,,                                                 ,                        ,,            ,,  ,  ,,        ,      ,,                                              , ,,,,,  ,,    ,,      ,,+,,                                                      , ,,   ,,  ,,             
,,+,  ,,,                    ,,,,                  ,,,    ,++,,         ,,  ,,,,   ,,,     ,+,,,  ,,,        ,,,,,,                               ,,           ,,,, ,     ,,    ,,       ,,,, ,                                                      ,,  ,,    ,               
+,,, ,, ,                   ,,  ,    ,,,           , ,   ,,  ,,   ,,,, ,,,, ,, +  ,, ,      ,, ,  + +,                                ,,,       ,+,,,         ,   ,  ,,,                 ,+, ,,              ,,                                        ,     ,,                
,,,                    ,,,, , ,,                   ,,,,,   ,,,,,             ,  , ,,,   ,,  , , ,,  + +  , ,,   ,,, ,,,, + +,     ,,                         , ,     ,, , ,      ,,, ,,,,      ,,,  ,+,,         ,+,,   ,,+,       ,,,,                  ,,,,             ,,,   ,,     ,,,            
,, ,                ,+, ,,,, ,             ,,,   ,   ,  ,   ,  ,   ,,,      ,,,, ,       ,   , ,,,   + +  ,,,    , ,,,, + + +     ,,,,              ,,,       ,,,   ,,   ,,,  ,,  ,,+,   ,     ,,,,,,  ,,    ,,,   ,     ,, ,       ,, +               ,  ,, ,             , ,          ,,,,           
,, +,               ,+ ,, ,,, ,,,,,         , +, ,,+   ,,+,  ,  ,  ,+,+,    ,,  + ,,,+,   ,,  ,       + +    ,,   +,,, + + + +     , ,,              , +,  ,,    ,,  + ,, , , ,,+, ,,,+ ,, ,    ,+           ,, ,,   , ,  ,+ +        + +              ,,,  + + ,,,,       , ,,  ,,  ,,,  + + ,,,,      
+ +,  ,,,  ,,,,,++,,,+ ,, ,,, +, ,,       ,,, ,, + +, ,,  ,,  , ,, ,+ ,+,,,, +, , ,, ,,,   ,  ,, ,,,  + ,   ,,+,  ,,,, + + + + ,,, ,  ,              , ,,  ,,, ,,,+,,, , ,, , ,, + ,+ +  ,       ,,,,   ,,,   , ,,   , ,   + +        + +              , ,, + , ,  ,     ,,, ,  ,  , ,,,+ + , ,, ,      
,,,,  ,,,,,,,,,,,, ,,,, +,,,,, + ,,       ,+, ,,  +, ,,,,   ,, ,, +, +, ,,,,+,,, , ,    , ,+,  ,, , ,  +,,, ,,  ,+ ,,,, + + , , ,,,  ,,+,           ,,, ,,  + + ,+ ,,, , ,, ,, ,+ +  + +  ,,,,         ,   ,   , ,  ,       , ,        + +  ,,           , , + , , ,     ,+,  ,   ,,  ,+ ,,, ,   ,,      
, ,   ,+     ,,,,,,    ,, , , ,, ,,      ,, ,,   ,+ ,    ,  ,, ,, +, ,,              ,,,, ,,    ,,, , ,+ ,,,,,   ,,,,,,,, , , , , ,, +, +,        ,,,  ,,   + +, , ,,, ,   ,    , ,  + +  ,  ,,   , , ,,  ,,   ,,, +,+   ,, , ,        , , ,,,,   ,      + + , , , ,,    ,  ,  ,    ,  + ,,,,,           
, ,, ,,, ++,,,,,  ,,,,,, ,,,, ,, ,,     ,, ,  ,,,+ ,,,,  ,, ,  ,, +  ,  ,,,,,,,+,,,,+,,,  ,,,,,    ,, ,,      ,,,  , ,,, ,, + ,,, ,, +, +,       ,,        ,+  ,,  +,,,     ,,, , ,  + +   ,  ,  ,, ,,,, ,        ,+ +, , , , ,        , , ,, ,,,+,,,+,, , + , , ,, +,   , ,,, ,, ,,,, , ,,,,, ,,,+      
,, ,,,  +,     ,,,   ,    ,,  ,,++      , ,     , ,+  , , , , ,, ,, ,  ,,        ,,,      ,,,   ,,,,  ,,++,,,,,,,,  ,,  ,, ,, ,   ,  ,, +,       , ,,   ,, + ,,, ,,,  ,    ,,,,,, , ,, , ,, , , ,,    ,   ,,,   ,,,  ,, , , ,,,,       , , ,+  ,,   ,  ,,, , + , ,+ ,+  ,, ,, , , , ,+ + , , , ,, ,,     
,,   ,,,      ,   ,           ,,    ,, , ,,     ,,   ,,,, , ,  ,  ,  ,, ,,,,,               ,,       ,,,  ,,    ,,,,,,,,  ,,+,,, ,  ,, +     ,,, ,, , ,  , ,,,,,,     ,,  ,,    ,  , ,,,,   ,,      , ,,, ,,   ,  ,,     , ,,,,      ,, , ,, ,,,,,  ,,   ,  , ,  ,,,, ,,   ,, ,, ,, ,,, , , , ,+ ,,     
,,,,,        ,,,,,                 ,, ,  ,   ,,,    , ,, ,, ,,  , ,,   ,   ,              ,, ,  , ,        , ,                    ,, ,,     +,  ,  ,     ,,            ,,,,,,, , ,,,,  ,,,,,  ,,,,,,,,,,+,,   ,,+,+,, ,,  ,,,,     ,, ,  , ,,,,,,,,,,,,,,  ,,   , , ,, +,,  ,,  , ,   ,, ,,,  , ,,     
,     ,,,,,      ,, ,   ,  ,, ,,    ,,,,,              ,,,,  ,,,        ,,     ,,,         ,     ,,      +  ,,  ,    ,,                     , , ,,   ,,     ,,,,                ,,,,     ,     ,,    , ,,                   ,, , , ,, ,,,,  , ,,,,,,  ,,  ,, ,      
+    , ,,         , ,,,,,,  ,,,                        ,,,                     , ,,            ,,        +  ,,  ,,,,,                   ,+ ,, , ,           , ,,                      , ,     ,,      ,,                   ,    , ,,      ,, ,,          ,, ,,      
,,,                                 ,,  ,,             ,      ,,,    ,,,                                          ,+ ,,        ,,,,,         +   ,                          , ,,  , ,          , ,,                       , ,, ,,,       ,,                 ,,,  , ,, ,         ,,          ,  ,,       
,+,,,   ,,                 ,          ,,  ,               ,,,,,,  ,, ,,,,,                    ,                    ,, ,,                     ,,   ,                          ,,,   , ,         , ,,                         ,  ,   ,,,  ,,                      ,, ,,  ,       ,,,            ,,         
,,  ,   , ,  ,,+     ,,   ,,,          ,+, ,,      ,,, ,,,       , ,    ,      ,,,           , ,           ,,,     ,,,,  ,,,,            ,, ,,    ,,                               ,, ,,    ,,,  ,                          ,,, ,,,,                         ,,,   ,, ,,   ,,            ,   ,,          
,,,,,   , ,  , ,,   , ,   , ,, , ,       , ,+ ,,,, , , ,,,,      ,, , , , ,,,  ,,,,  ,,,     , ,       ,,, , ,          ,,  ,       ,,, ,,, ,,,,,, ,       ,,                          ,+,,,,  ,       ,,,          ,         ,,      ,,,              ,,         ,,,  ,   ,,,,    ,,,, ,,,              
,,     , ,, +,,,   ,,,   ,,,, ,     ,,  ,, , ,, , + + ,,,,      ,, , , , ,,,, ,, + ,,,,     , ,,,     , , + ,,  ,++,   , ,,        ,  ,  , ,,,, ,,,  ,,  ,,+,  ,,                ,,, ,      ,,       ,, ,         ,,,            ,,, , ,,   ,        ,,,,        ,,   ,, ,,  ,   ,,  , , ,,   ,,,       
,,   ,,,, ,,,  ,       ,,,,      ,,,,  +,+ ,+ + , + ,,,,      , ,,     ,, + ,+ + , ,,     +   ,,    +,,,,,,,  +,,+                  , ,  ,, ,      ,,, +,,, ,,,,              ,+ ,,,+ ,,,,         ,, ,   ,     , , ,,,,   ,,  , , ,,,,  ,,,,      ,, ,        + ,,, , ,, ,,   , ,,  + ,, ,,, ,       
,,,,  +, + ,,, ,,+ ,,,, ,,,,      ,, +,      , , , + ,,,,   ,, ,,,  ,,,, + +  , , ,       ,, ,, ,,   ,,,,,,,,  +,,+  ,,,+,  ,     ,,,  ,  ,, , ,    , , ,,,, , ,,         ,,,  ,, ,, , ,   ,,,      , ,,         ,,, , ,,  ,,,, , ,,,,,, ,, ,,      ,+ ,   ,,   + ,,,,   ,,     ,   , + ,, , ,,,       
,,,,,,, ,+   ,,, +,,, , ,,,,     ,+, ,,,,,,  , , + +  , ,  ,,,,  ,, ,, , , +  , , ,,,,   ,+ ,,  ,,   , ,,,,,,  ,+,,  ,   , ,,,   ,+  , ,,  , , ,,   , ,, , , , ,          + ,,  ,+,  , ,  ,,,,,     ,,  ,  ,,,,  ,,, ,,,   ,+ + ,,,,,,,, ,,,,        , , ,+,,,  +,,,     ,  ,,  ,     ,,,,  ,          
+,   , ,+,  ,,,  ,,,, + ,,,,     +,    ,  ,,+, , + +  , ,  +, ,,+,,,,, , , + ,,,, ,, +, ,+,     , ,,,, , , ,,      , ,,  + , ,,  ,+ ,,, ,  + +      ,,,, , , ,,,,        ,+  ,       , , ,, ,,,      ,,,,, ,,,+, , ,   ,,   + + ,,,, , ,     ,  ,,   , , ,, ,,  +,,+    ,,,  ,, , ,,, ,, ,   ,,,       
,, ,,,,,,,   +, ,  ,, ,, ,,,,    ,+ ,,,, ,,,   , ,+ +  , +  ,,  ,          ,, + ,, ,+ ,, ,, ,,,,,, ,   ,, + , ,,++,,,,,   , , ,,  ,, ,,, ,,,+ +,,,+,,,,,, , , +, +        ,,  ,  ,,,  , + ,,      , , ,,  , ,, ,, , , ,,,+,  + + ,,,, , ,   ,,+, ,,,  , , ,, ,,  + ,+    ,    ,, ,,,   ,+ + ,+, ,,      
,, ,, ,,,     ,++++,, ,,  , ,,     ,,+,,,,,,,,,,, , ,, ,, ,  ,, ,,,,,,,,,,,,,  , ,  ,, +, ,, ,,,,  ,, ,,,  , , ,,   ,    ,  ,  ,  ,,      , +,  ,,,  ,, , ,,,, ,, +        ,,, ,,,,,,,,, ,,,,  ,,, , , ,   , ,, ,, , , ,, ,,  , + , ,  , ,,  + ,, , ,  , , ,,,,  ,, ,,    ,,   ,,       ,+ + ,,   ,,     
,+, ,,               ,,,,  ,,,,       ,        , ,  ,,, ,, ,, ,, ,+,    ,,  ,,   ,,  ,,  ,  ,, ,,   ,+ ,     ,,  + ,++,,,,,,,, ,, ,,, ,+,,,,, ,++,  ,,,  ,,  ,,  , ,,        ,,,,         ,    ,, ,, , ,,,   ,,,  , ,, ,  ,  ,,, ,, , , ,, ,,,,, ,,,, ,  , ,     ,,,   ,    ,,   ,, ,,,,, ,, + ,,   ,,     
,, ,,,                       ,,           ,,    , ,     ,, ,, ,, ,,  , ,            ,,,  ,   ,, ,   ,,, , ,,,    ,, +        ,    ,,  ,,  ,,    ,,,,,,,,,,,      ,, ,            ,,,,+,,,,,,,,,,,,,,, ,,    ,     ,, , ,, ,, ,   ,,  , , ,,,,    ,    ,  , ,,   ,+,  ,, ,,,++,  ,  ,,,, ,,,, ,, ,,,  ,,     
,+,,                                     ,     , ,+,,+,  ,  ,, ,,   ,,,          ,,   ,,   ,, ,  ,,   , ,, ,,  ,, ,,     ,  , , ,,  ,,                        ,,  ,                    ,,   ,,   ,,   ,,,,,,,,,,,    ,  ,, ,+,,+,  ,,,, ,,,,,,,+,,, ,,  ,     ,,,     ,  ,   ,+,,,    ,,   ,,    ,, ,,     
,    ,,         ,,  ,,                 ,+ ,,   ,,, ,,    ,,   , ,,  ,, ,,     ,,   ,  ,   ,,                       ,, ,,                ,                                    ,, ,,        ,,           ,,,,   ,     ,,   ,, ,,,,,,+,   ,+,,,,,,,,,     ,, ,      
,,,                                  ,,+,,+,,   ,,,                    , ,,,,,,  ,,  ,        ,,  ,  ,,    ,         ,   ,                        ,,,,                ,                                  ,,,  ,,                                  ,,     ,,  ,,,,       ,,    ,,     ,, ,,      
+,,,                                        ,,,,,               ,      ,, ,,   ,,    ,,,  ,     ,   ,,               ,   , ,,                     ,,              ,,,,                                   ,  ,,,                             ,,   ,  ,,,  ,,                         ,, ,,       
,+ ,,       ,,,                                                 ,,,      ,,,,,,,      + ,, ,,     ,,,                 ,,  , , ,                                    ,, ,                                    ,,,  ,,,,                         ,   , ,+,,,,,,                        ,,, ,,        
,   ,, ,,      ,,  ,                                ,,              , ,,        ,   ,,    + +   ,,  ,,,         ,,,      ,,       ,                                    ,  ,,                              ,,,       ,, ,,        ,              , , , ,,,   ,,                ,,,     ,,  ,,         
,,,,  ,,,     ,,, ,,              ,,                ,,,+             + ,,     ,,,  ,+,,,   , ,    , ,            , ,,     + , ,,  ,,    ,,,                     ,,      ,,,,,      ,,,                 ,  ,, ,  ,,,  +, , ,,    ,+,,             , , , +                       ,,,, ,,,  ,,,          
,,,,  , ,    ,,  ,,               ,,      ,++,  ,,  ,+ +, ,+,,       ,,,,  ,, ,,,, ,+ ,, ,,   ,+, , ,,  ,,       ,,,, ,,  + +,  ,,, ,+, , +,                   ,,,, ,,,  ,     ,,  ,,,,   ,,,   ,    , , ,, ,, ,,,,  , ,, ,,,   ,, ,  ,,, ,,,    , ,, ,     ,,,                ,, ,,   ,,,            
,, +  +,,, ,+, ,,                    ,,  ,,  +,,,+,  + + ,+  ,       + +  ,,, ,+ + ,+,+, ,,,,,, + +,,,,,,,       ,,,,,,,, + +      ,,,+ ,,,,  ,,               ,,,,,,         ,,,,  + + ,,  , ,+,,      ,, ,, ,, ,,  , , ,,,,   ,+ , ,, , ,,,,   ,  ,   ,   , ,,       ,,,      ,,  ,,,,              
,,, + ,+ +, ,  ,  ,,,   ,+++,         ,,+ + ,,,,,, ,  +,+ , ,+,  ,,,  + + ,, ,, + +  ,,,  ,+ + + + ,,, ,  ,   ,,, ,,  , ,, , , ,,,,, + + ,,,, +,,,              ,+,,, ,,       ,, ,, + + ,  ,  , ,,     ,,  ,, , ,,  ,+ +,,, ,    , ,   ,, ,,,+   +   , ,,,  ,,,,      ,, ,        ,,                  
,    , ,+ ,, ,+ ,, , ,, ,,   ,,  ,,,,,,, + ,,,+ + + ,  +,+ ,,     +,,, + + ,,,,  + +  ,,,   + + + +,, , ,  ,   , ,  ,  ,,,  + + ,,  ,,, , ,,,,,  ,  ,,            , ,  ,         ,, , + + ,     ,,,     +, ,, ,    ,,  +,,, , , ,, ,   ,,,  ,,,,   ,,  , , ,   , , , ,  ,,,,    ,+,,                    
,    ,,  ,,,   ,, , , ,, , ,+, ,,,,,,,    , ,, , , + +  + +   ,,, ,+ ,, + +  ,    , ,, +,+,  + + + +,, ,  ,,,+  , ,  , ,    ,+ ,, ,  ,,, , ,,,, ,,  ,+++           , ,  ,   ,,,    , , , ,  ,,,     ,,,  + ,,,, , ,,,+, ,,,, ,  ,     ,       , ,    ,, , , ,   , , , ,          +, +, ,,,,,             
,    ,,         , , , ,, , , ,, ,,,    ,,,, , ,   ,, ,  + ,, +,,,  , ,, + ,+  ,,, ,,,,,+ ,, ,, , + + , ,  +, +, , ,,,, ,   ,, ,  ,,  , , , ,,,,   , +, ,,      ,,  + + , ,  ,,,,  ,, , , ,  +,,,  ,+,,,  + ,, , , ,, ,, ,,,, ,  ,,  , , ,,,   , ,     , , , ,  ,, , ,      ,     ,, ,, , ,,,, ,,,        
,,  ,, ,,,,,, ,,,, ,,, ,, +,,, ,,          ,  ,,,   ,  +,,,,,  , ,, ,,,+  ,+,,,,,, ,, + ,, ,, ,,, , , ,  ,, + ,, , ,,  ,++,  ,,,  ,  ,  , + ,, ,,,,,  ,,     ,,,, + + , ,  ,, ,,,+ ,, , , ,+ ,+  + ,  ,,, ,,,, ,,,, ,, , ,,,,,,   ,, , ,,,,  , ,     , , , , ,, ,, , ,  ,,,,,   ,, ,, ,,  , ,,,+,       
,,  ,,,,,,, ,,, ,     ,,   ,,     ,,  ,,    ,,   ,, ,, +   ,  ,, ,  , ,,,,    ,   ,, ,, +, ,     , ,, ,  ,, + , ,, ,,,      ,, ,++,,,,,, ,, ,  ,,   ,+,      , ,,,, , , , ,,  ,,,, ,, , , +   ,,,, ,     ,  ,   ,,   ,,, , , ,, ,,, ,, ,, ,,,, ,,,,, , , , , +  ,, + ,,,+, ,,,  ,, ,   ,,,, ,, ,,       
,,,, ,,       ,,    ,, ,,   ,+++++,+,,,     ,+++, , , ,,,,,,,, ,,,,,  ,,,,,,,,,,,  ,, ,, ,,+,,,,, , ,,  ,, , ,,,   ,,,+,,,, ,           ,,,, ,, ,+,,,      ,, , ,,,  , ,    ,   ,  , , ,,, ,, ,,  ,  ,,,,,,,,,,,,,,,   ,, , ,  ,,  ,, ,,  ,,   ,,  ,, , , , ,  ,, +  ,,     + ,,  ,,   ,,  ,  ,,       
,,, ,, ,+,+++,   ,,,, ,,,,+,,  ,,   ,,            ,,, ,,,,,,    ,, ,,           ,,,  ,,       ,    ,   ,, ,    ,,     ,,,,,,,     ,,,       ,, ,,         ,,  ,  ,  , ,,,,,,,,,,++,,  , ,,,,,,,,,, , ,,   ,,, ,,,, ,,,,,  ,,,  ,,,,,  , ,,,,,,,  ,,  ,, , , ,,,, ,, ,,,,,,,,, ,,   ,,    ,,  ,,        
,, ,,         ,    ,                                          ,, ,   ,,         ,,,            ,,,,,  ,    ,  ,                , ,      ,, ,,         ,, ,  ,  ,  ,            ,  ,       ,,,,,,,                 ,            ,  ,  ,,,,, ,,, ,,,,   ,      ,, ,,,,,,,++, ,,,,, ,,   , ,,          
,,,  ,,          ,   ,,              ,                          ,, ,,   ,,,,,  ,,,,                    ,,    ,,,,                 , ,     ,, ,,        ,,, ,,, ,,,  ,                                                            ,,,, ,,                    ,,,,, ,,              ,,    , ,,           
,,,           ,   ,               ,,,     ,,+,               ,,,,     ,,                       ,,,,,       ,,             ,,,  ,  , ,,,  ,,       ,,,  ,  ,,   ,,                             ,,,                            ,    ,,                    ,     ,,            , ,    , , ,            
,,,,       ,,   ,,   ,     ,,               ,,  ,               ,,,                             ,,                           , ,, ,,      ,,         ,  ,,    ,,,,,                              ,                       ,+,,   ,,,,,,                      ,,,,,              , ,  , ,   ,            
,,,, ,,    ,,    ,,,       ,,+,  ,, ,+,                                 ,,       ,,   , ,, ,,,,                ,,  ,  , +,   ,,,           ,,,                                          ,,  ,,                 ,, ,                                                       ,     ,            
,,        , ,,,,  , ,,    + +       ,,,, ,, ,,        ,,,      ,,+,   ,,,       ,,,,     ,,,,  ,, , ,,,,           ,,,,,,,, ,,,,,, ,,,   ,,,                   ,                       ,,,,,        ,,+,                ,,,+        ,                     ,,                        ,   ,,            
,,,,        , , , , ,,,    + +  ,,,, ,,,, , ,  ,,     ,+,,  ,,  ,,,,  ,  ,       ,, , ,,, ,, +  , ,, ,, + ,,,,  ,,, ,,,, + + , , ,, ,,   ,,,,        ,,,  ,,,  ,,,   ,    ,,,          ,,, ,,        + ,,  ,,,,   ,,,    ,,,,   ,  ,++,        ,,         ,,,,                        ,   ,            
,, ,,    ,,,, ,, , ,        , ,  ,  , ,, ,,,,, ,, ,    ,+ + ,,,, ,,,, ,, ,,       ,, , , ,, + ,, ,,,   + , , ,,  , ,,,+ + + +    , ,+,,   ,,,,    ,,, ,,,, ,,,, , ,, , ,  ,,,,         ,,  ,,         ,,,, ,,  ,  ,+,+,    , , ,+,, ,, +       ,,,,       ,+ ,,                         ,,,,            
, ,,     ,,   ,  , ,        , ,  ,, ,  ,  , + ,  ,,    ,+ ,  + , ,,,,  ,  ,   ,,,  ,,, + ,, , ,,   ,,  , , ,,,   ,,,, + + + +  , ,        ,, ,   ,,,+ ,+ + ,,,+ + +,   ,  ,,,+          ,,, ,          , , ,,,,   ,, ,+    , + ,, ,  + ,,      ,, ,       ,+ ,,   ,   ,,+,   ,,,       ,,               
, ,      ,,   ,  , ,  ,,,   , ,   , , ,,  , , ,,    ,,, ,,,  , , ,,,,   ,,,,  ,,+,     ,,,, , ,  ,,,+, , ,  ,,,  ,,,, + + + ,  ,,,  ,,     ,,,    + ,, + + ,, , , +  ,    ,, +            , ,          , ,        ,+,,,    + +  + ,, , ,,  ,,, , ,,  ,,,,  ,++, ,,    ,,,,, ,   ,  ,,, ,,,, ,,,         
,,  ,,,,    ,,,,,, ,, ,,  , ,, ,, ,  ,  , ,   ,,, ,,+      , , ,,,,  ,+ ,,  + ,, ,,  ,,,, , ,  ,+ ,, , , ,,,+  ,,,, + + , ,      +,,,   ,    ,, ,,,, , +  , , , , ,,,,,  , ,            , ,     ,,,  , ,  ,,+,   ,,,     + ,  ,, , , ,  ,+,+  ,,  ,,  ,       , ,   ,, ,, ,   ,  , ,  + , ,,          
,+ ,, ,,  ,,+,, , , ,,   ,, ,,,+,,, ,  ,  , ,,++,,,,, ,,,,,, , , , ,, ,,, ,,  ,  ,,+,, , ,, , ,  ,, ,, , , ,, +, ,,,, , + , ,  ,,++,  ,,,++++++++,, ,, , ,  , , + , ,,     , ,           ,, ,     , ,, , ,  ,, ,,      ,,  , ,   , , , ,  ,, ,,     ,  ,,   ,,,       ,,,,,     ,  ,, , , ,   ,         
,+, ,, ,,  ,  ,, ,,, , ,,  , ,,      ,, , ,,,           ,,  ,, ,, , ,  ,  ,  ,,,      ,,  ,  , , ,, ,, ,, ,,, ,+ ,+ ,,,, , , ,  ,,   ,,   ,          , ,,,, ,, , + ,  , ,  ,, ,          ,, ,      ,, , , ,, ,, ,,,,,,,,, , , ,,  , , , , ,,   ,     ,       ,,++   ,,  ,,,    , ,,  , , , , ,  ,        
,, ,  ,,   ,,,      ,,,,,+,, ,,+,,,,,,,  ,,  ,++++,,,+,,,,,,  ,,  ,,, ,,,,,,    ,,,,,,,  ,, ,,,, , ,,  ,,,,  ,+, ,      , ,  ,  ,+,  ,  ,,,++++++,  ,, , ,  , ,  ,      ,  ,, ,,,     ,,,,  ,,      , , ,  ,,,    , ,,,,  , +,,,,, ,  , , , ,, ,,,,,,,  ,,,, ,, ,,,+,,,,,,,,,,  , ,  , , , ,  ,,,,       
,,,++,   ,,,  ,    ,,, ,  ,,,  ,,,,,,,,  ,,     ,,,,,,  ,,+,,,,,   ,,   ,,,  ,,+,,,,   ,,,    ,, ,, ,    ,,,,,,,  ,,,,  ,  ,,+,   ,,   ,   ,,,,   , ,,  ,,, ,, ,  ,,,   ,   ,                 ,     , ,,,,,,   ,,,,,,,  ,,,, ,      , , ,, ,,,,,       ,,   , ,,   ,   ,,,,,,,,,,, , ,, , , , ,, ,,       
,,, ,,,,,,,,,,   ,,   ,                                                            ,,           , ,,             ,, ,  ,  ,,     , ,+,,,         , ,      ,, ,,  ,,, ,,    ,,,,,      ,,,,,,,,    ,, ,  ,,,,, , ,,,,,,,,,  ,,,+,,,,  ,,,      ,+,,,,,,,,,,,  ,  ,,,,,,  ,,       ,, ,, ,,, , ,, ,,       
,,  ,,                             ,                             ,,,+,         , ,                 , ,,        ,, ,,,    ,      , ,,,,,,,  ,,                                   ,  ,                       ,,       ,,                     ,  ,,,,,,,++,,+++,,,,,  ,,    ,   , ,,       
,+  ,,                             , ,         ,,,               ,,  ,         ,,,,               ,+ +,    ,,  , ,,   ,,        ,,  ,,   ,,                                    ,  ,                                     ,,     , ,      ,,, ,,,                    ,,,,,,   ,  ,        
,+, ,,                                        ,,,,               ,,,,,          , ,      ,,       ,, +    ,+,, , ,    ,     ,,   ,,,,,,,,,                                   ,,  ,                                     ,+,,    , ,         ,,                              ,  ,         
,+ ,,                                        + ,,                ,,            ,, ,,  ,,,        ,,,,    +, ,,,, ,        ,, ,     ,,                                     ,,  ,,          ,                           +  ,,            ,,,                             ,,,  ,          
,,,                                        ,,,,    ,,                         ,   ,,   ,        ,,     ,,,,, ,  ,,,,,,,,  ,                                                ,          ,,,,,                        ,+  ,,                                           ,   ,,           
,,,      ,  ,,           ,,                     ,,    , ,  , ,,,,          ,,+,         ,,     ,,,                ,,+,   ,,        ,,                   ,   ,,    , ,    ,,,        ,        ,,, ,, ,,                         +,,,                                             ,,,,            
, ,,    ,,,,     ,, ,,, ,,,,   ,,              , ,    , , ,,, + +,         ,,,,        ,,,,  ,,,          ,,,     ,     ,  ,,,,,,              ,,  ,,,   ,    ,   , ,, ,,  ,         ,       , ,  ,,+,     ,,            ,,    ,,           ,,   ,,,   ,,,            ,,                        
,,,,   ,+ + ,,,,    ,,, ,,,+  +,,,             ,,,   ,,,,,, , + ,,         ,,,,       ,, ,,              ,, +    ,,+,  ,,,       ,,+,         ,,,, , , ,, , ,  ,  ,,,,    ,,        , ,      , ,  ,,   ,,  , ,         ,+,,,    , ,   ,,   ,,,, ,, ,   +,,,   ,,,     ,,,                       
, ,,   + ,, +,,,    , ,,,+ + ,, ,,                ,  ,+,,,, , , ,,  , ,    ,, , ,,,, +, ,,  ,             + ,,   ,, ,  ,,,,     ,+  ,,        ,, , ,,,  ,,,  , ,   , ,  ,           , ,      , ,      ,,,, + ,  ,,,   ,, ,,     ,,,, ,,,,  ,, ,  + +  ,,  ,   , ,     , ,,     ,,    ,          
, ,   ,,,  ,, ,+, +  ,  , ,, ,,,, + + ,,  ,               , , ,+ , + + ,,,,  , , ,,, , ,     ,, ,, ,,,,,           ,,,,    , ,, ,,,,     ,+  ,, ,,+,   ,, , ,,,  ,   ,  ,   , ,    ,,,      ,, ,      , ,       , + , , ,+,,,  , ,,           ,, ,,  , +  , ,   +,+,   , ,,    , ,,    ,,,, ,,,,,        
, ,  ,++,, ,,,+,,  ,  ,+, ,,,, , , + +  ,,,+,   ,,, ,,  ,, , ,, , , + + ,,,  ,, ,,,,, , , ,,, ,+ ,, ,, ,,       ,,, ,, ,   ,, ,, ,, ,  ,,, ,,,, ,,  ,,  ,,,,         ,, ,    , ,  ,    ,   ,,  ,,      , , , ,,  + + , , ,, ,+  + +       ,,,,  ,, ,  , ,  , ,    ,,    , ,, ,,,  ,     , ,, ,   ,        
, , ,+,  , ,  ,  ,  ,     , ,, , , , ,  ,, ,,  ,,+++,,+,,,,, ,,,, , , , ,,, ,,   ,, , , ,      ,, + ,, ,,      ,+,,  , , ,,,  ,   , , ,, +  ,,  ,, ,,,   ,  , , ,   ,        , ,  ,  ,     ,,  ,,   ,  , ,       + + , , ,, ,,  + ,      ,,,,,,  + +  , ,  , ,          ,,, ,     ,     ,,,, ,  ,,        
,,,, +  ,+, ,    ,   ,  ,,,, ,,,,,, , ,  ,+  ,  +,            , ,, ,,, , , , ,  ,   ,, , +,,,,, ,, , ,, ,,     ,+  ,,,, + ,,  ,    , ,  + +,  ,, ,,        ,,,,, ,   ,  ,,,,  , ,,,      ,  ,,   ,, ,,  ,,,,  ,,, , , , ,  , ,,  , ,,,,,  ,,  ,, ,, ,  , ,  , ,  ,,,,    ,,, ,,,,  ,  ,,      , ,,,        
, , ,+ ,+, ,,     ,  , ,,,  ,    , ,, ,   + ,,  +  ,,,,,,,,,,,  , ,   ,    , ,,,,,,,, ,,       ,, ,, ,, ,,, ,++,,   ,,   ,,   ,,,,,, ,  +,,,,+,+,,  ,,,    ,,   ,,  ,,  ,     ,            ,, ,,,,, , , +, +,,,  ,  , , , ,, ,,, ,, ,,,, ,,   , ,, ,,  , ,  , ,, ,, ,,   , ,,   ,, , ,, ,   ,,    ,,       
, ,,, ,+  ,, ,,,,,,,,  ,  ,,,,,,,, , ,,   , ,, ,, ,+,,      ,   ,,,,,,,  ,,      ,,,  ,,+,,,,,,  ,,  ,    ,,,         ,,,   ,   , , ,,  +          ,   ,   , ,,,,  ,, ,,,,,,  ,,,,,,,,  ,    ,,,,  ,  , ,      ,,  ,  , , ,   ,,, ,,,,,, , ,,  ,,   , ,,,,  ,  ,,,   ,,, , ,, ,,,  , ,, ,,,,+  ,,, ,       
,, ,  ,   , ,,,,,,,,   , ,,   ,,    ,,  ,,  ,  + ,,   ,,,  ,                           ,,,,,  ,,,    ,,,,,   ,,+, ,,,,, ,,,,,++++ ,,,  ,  ,,,,+,,,,,,,,,  ,, ,     , ,,                   , ,    ,,  ,  ,++,,,,,,,,, , ,, ,,,,,,,  ,,+, , ,,,,,  ,, , , ,  ,, ,   ,,  ,, , ,, ,,  ,, ,        ,, ,,,       
,, ,,     ,,         , ,,       ,,   ,,, ,,  + ,,    ,, ,,,                     , ,                  ,   ,, , ,+,,           ,,   ,  ,  ,               ,, ,      , ,                           , ,,                 ,,   ,  ,,        , ,   ,,,, ,,  ,  ,, ,,,,,,,,,,, ,,,  ,,,,, ,, ,,,,,,, ,           
,,,                ,  ,        , , ,   ,,  ,, +      ,,,                       ,,,                  , , ,,,,      ,,,          ,,,,, ,,   ,,,  ,     ,,  ,,      , ,                       ,,   ,                          ,   ,,, ,  , ,      , ,     ,, ,,       ,   ,,    ,,, ,, ,,  ,,,,,,           
,,  ,         , ,  ,,,  ,,  ,,                                                      ,  ,,,,    ,,   ,        ,,    ,,    ,,,  , ,,,,  ,,        ,  ,        ,               ,                             ,   , , ,  ,,,      , ,   ,,  ,,  ,,                 ,  ,,                    
,,+,, ,,          ,  ,,   ,,, ,,      ,,                                                    , ,    ,,,,,          ,,,,       ,,,,,  ,   ,,,          ,  ,,  ,,                     ,,,                             ,, ,   ,,      , ,,,,,  ,, ,, ,,                 ,,                      
,    ,,            ,  ,,,,,  ,,     ,,,,,                                                   , ,     ,,                ,,,        ,, ,,,,,             ,         ,             ,,,,,   ,                               ,    ,       ,     ,,   , ,,             ,,,,,,                       
,,,,,  ,            ,,      ,,     ,,  ,,                             ,,,                   ,  ,                    ,,,,,,          ,                   ,   ,,              ,      ,,,         ,,,                    ,   ,,        ,,,,,     ,,,                                           
,,,              ,, ,                 , ,,     ,,,  ,                             ,,,,                      ,         ,,        ,,  ,,                                                    ,  ,,,          ,, ,,            ,       ,,  ,,                                                               
, +             ,, ,,       ,,,, ,,,, ,, ,      ,,, ,  ,,       ,,        ,       + ,,             ,,  ,,,   ,,,,    ,,,,        +,,,,       ,             ,,,          ,                 ,,,,     ,,,    ,,, ,,         ,,,,       ,,, ,                                                ,,,            
+ +       ,    ,, ,,  ,,   ,, ,, ,, + ,, +   ,,   + ,  , ,    ,,,,,     ,,,,      , ,  ,,,   ,,   ,,,, , ,  ,         + ,        ,,,       ,+,,            , ,           ,        ,,      +,,,    ,     ,   ,, ,         ,, ,  ,,,      ,                             ,,,                , ,            
+ +  ,,,     ,+, ,,  ,, ,  ,+ ,,  + +, , , ,+,,   , ,  ,,,    ,  ,     ,, ,,      , , ,  ,        ,,,, ,,,,  ,,,,     , ,            ,,   ,,  ,            , ,           ,       ,  ,     ,, ,    ,    ,,,   , ,          + + ,, ,   ,, ,  ,,            ,,,         ,, ,             ,  , ,            
, , ,, ,  ,, ,, ,,  ,  ,    ,,,,  + ,, , , ,  ,   , ,    ,    , ,      ,,,,   ,,, , ,  ,,,     ,,  + , ,,,,  ,,       , ,         ,   ,   ,,+,             , ,   ,,              ,, ,      , ,         , ,,  , ,          , , , ,,  ,                   ,, ,         ,, ,     ,,,,,   ,  , ,            
, , ,,,       +, ,  ,,,           + ,  , , ,,  ,  , ,  ,+++,  ,,,,        ,  ,, ,     ,     ,,,,,, , , ,,,, ,,,,  ,,  , ,  ,,     ,,,      ,   ,             ,  ,,,,        ,,,   ,   ,,   , ,    ,,,, ,,,  ,, ,          , ,    ,   ,  ,,  ,           ,,,          ,,  ,   ,,   ,,     , ,            
, ,    ,,  ,   , ,      ,,  ,,,,  , ,  + ,    ,,, +,,, ,  ,+  ,  ,,     ,,,, ,, ,  , ,  ,,  ,,   , , ,,,,,, ,, , ,,,, , , ,,,, ,,,      ,,,  ,,,,       ,,,  ,  ,  ,  ,,,   , ,        ,,  , ,     , ,  , ,,, ,       ,,, , , ,+,+,   ,,,,      ,,,  ,,,   ,,  ,,,,   , ,,, ,, ,,, ,  ,, , ,  ,,        
, ,,  +,, ,,,, + , ,,,,,+,, +, ,  , ,  + ,,  , ,,,,  ,,,   ,, ,   , ,,+,,,,+  , ,, , , ,,,, ,,  ,, , , ,, , ,, , ,, ,,, , ,  , ,,+,,,,,,+,,,+,, ,,,,++,,, ,  , ,, ,,  , ,   , ,   ,,   ,,  , ,     , ,  ,,,,  ,   ,,,,, , , , ,+ ,,  ,+  ,  ,,  , ,  , , ,,,,, ,,,,    , ,, ,     ,  , , , , ,,,,       
,,,,,,, , ,  ,,, ,,,,,,   + ,, +  , , ,+  ,,,,  ,, ,,    ,+,  ,   , , ,,  ,,  , , ,, ,,, ,, ,     ,  , , ,,  ,,,, + , , , , ,, ,      ,,          ,,      ,    , ,,   ,   ,,  ,   ,    ,   , ,    , ,, ,,  ,  ,, ,,     , , ,, , ,, ,+   ,,,,,,,, ,  , ,,,, ,, ,, ,, ,,   ,,,  ,,,, ,, , , , , ,,       
,          ,,  ,,   ,  ,,+, ,, + ,,,, ,,,,,   ,   ,,,,,,+,,  ,,  ,, ,, ,,,,  ,, , , ,,  ,, , ,,,         ,   ,, , , ,      ,,, ,,+,,,,  ,+,,+++,,,  ,,,,,,      ,,  ,,  ,    ,    , ,,,    + +, ,, ,,  , ,,,+,,, ,  ,,,,  ,   ,  +, ,, ,,    ,    ,  ,      ,, ,, ,, , ,,,             , ,     ,,       
,,,,,,,,  ,,,,,,,,,,,,,,,   , ,, ,,,,  ,++,,+++++,,,          ,,+,  , ,,,    ,,,, ,,+,,,   , , ,,,,  , ,,   ,, ,  , , ,,,,,,     ,,,,,++,,,,,,   ,++,,,,,,      , ,,,  ,,        , ,,     ,, ,  , ,,   , ,   ,,  , ,,     ,,,, ,,,  ,,,,,,+,,,,,,   ,, ,,,,,,  ,, ,   ,,  ,,,,,,,,,,,,, , ,,, ,,        
,,,,, ,+,,,              ,, ,,   ,,            ,,,            ,   , ,,                     ,      , ,    ,,, ,  , ,,                             ,            ,      ,         ,  ,       , , , ,,  , , ,      , ,  , ,  ,  ,                      , ,,,,,  ,,  ,,                     ,,, ,,,         
,,             ,,, ,,                          ,,        ,  ,               ,, ,   ,      , ,,,,,,  , ,, ,,                                +,,,          ,,, ,        ,  ,            , ,  ,    ,,,,, ,, , , ,,                      ,      ,,     ,   ,,                                      
,,                          ,,,,     ,,  ,                   ,   ,      ,,        ,,  ,,                        ,,,,    ,,   ,                                  , , , , , ,, ,  ,     ,  ,  ,      ,               ,  ,  ,,     , ,,,                                        
,,,                            ,, ,   ,,  ,,                 ,, ,   ,       ,,,,,,,,   ,,       ,,,,              ,, ,,    ,,+,,,                 ,,   ,               , , , ,   ,,     ,    , ,                      ,    ,        ,,                                          
,,,        ,  ,,,   ,,           ,,,,      ,,  ,,              ,,,        ,, ,+,            ,  ,,  ,,, ,,                       ,              ,  , , , ,         ,    , ,,           ,     ,,   ,, ,,                 ,                                   
,,    ,,,            ,,         ,,,            , ,,        ,    ,,,     ,,      ,,,,       ,,, ,,                       ,  ,,,,       ,,   ,,,    ,,,,                                        ,  , , , ,   ,,,  ,      ,,           ,,,   ,,,,                       ,,, ,,,          ,,                  
,,,,   , ,        ,,  ,,,        , ,,       ,,  ,,,,      ,   ,,,       ,,,,     ,, +         ,, ,,               ,,,  ,, ,,,+ ,  ,,  ,,,,     , ,,,  ,              ,,,,,,                ,,,  ,   ,    ,,,   ,,    ,,              , ,, ,  ,,  ,,,,                ,, , ,,,,        ,,,,   ,             
,,,,   + ,, ,,,  ,,,, , , ,,,    ,, ,  ,,       , ,, ,,  ,,,   ,,       ,, ,   ,  , ,      ,,, ,, ,              ,,,, ,,  ,  , , ,  , ,, ,     ,, ,,,,,  ,,,        ,                      , ,    ,,,  ,,   ,,,  ,,  ,,,  ,,         , ,, ,      ,, ,   ,           ,, , ,,  ,        ,  ,  ,,, ,,,        
,,,,   , ,,,+,,,, , , , , , ,     , , ,,,,  ,,   ,, ,,,, , , ,,, ,       , , ,,,, , ,  ,,  , ,  + +  ,,,         ,, ,     ,  , ,  ,,  ,+ , , ,     ,, ,  , ,   ,    ,  , , ,,              , ,  ,,       ,,,,   ,,,, + , ,      ,,,  , ,  ,   ,,  , , ,,,,   ,,     , ,  ,,,,,        ,  ,  , ,            
,, ,   , , ,, ,   , , ,    ,,     , , , ,,          ,, + ,,, ,  ,,   ,,, , , ,  , , , ,,++ ,,,, + + ,,,,         ,+ ,  ,, ,, , , ,     , , ,,, ,,+, , ,  , ,        ,, , , ,   ,,,,,       , , ,, ,             ,, + + ,  ,,         , ,  , ,,  , , , , ,,   , ,  ,,, ,   ,,          ,,,,  , ,            
, ,   , , ,,,,, , ,, , ,      ,, , , ,,   ,,+,  ,,, + , , , ,      ,, , , , ,,,, , , ,  ,, ,,, + + , ,,  ,,      ,,,   ,, , , ,  ,,,  , ,     , ,, ,,+, ,,+,            , ,  ,,,, ,       , , ,,,   ,,,    ,,   + + , , ,       ,   , ,,,, , , , + ,  ,,    ,,, ,+,  ,,    ,,              , ,   ,,       
, ,  ,,,,,,,,,,,,  , , ,  ,, ,,, , ,   ,  ,,,+ ,,,+,, , , ,  ,,+,  , ,, , ,  ,  ,, , ,  ,,  ,, + , ,,   ,,,,   ,,   ,  ,,,, , ,  + ,,,, , ,,+,, ,, +,,, + ,+ ,, ,  ,    , ,  ,,  ,, ,,,,  , ,    ,, ,,,,  ,,,,  , , , ,  ,,,,    ,,,        ,  , + , ,  ,,      ,, ,,  ,  ,,,,           , , , ,,,,       
, , ,,    ,       ,, , , ,,+ , , , , ,,,, ,, + ,, ,,  , , ,  + ,+  , ,, , ,,   ,,   ,,  , , ,  , ,   ,,,,  ,  ,+,,,,,,,, , ,, ,,,, ,,, ,, ,,   ,,, +,,, , ,+ ,  ,, , ,  , ,         ,  ,  , ,  ,,,,,,, , ,+  ,  , , , ,,,,  ,,  ,,       ,,,  ,  , ,   ,,,, ,,, ,,,, , ,  ,  ,,,,,,,,,,,  ,, , ,, ,,      
, , , ,,,,,,,,,,,,, , ,, + , , , , ,,,, ,,,  ,,,,  ,, , , ,  +, +  ,,,, +  ,,,,,      ,,    , ,,,,  ,,,   ,, ,,         ,, ,,,    ,   ,,  + ,++,   +      ,, ,, ,, , ,  ,   ,,,,, ,,   ,  , ,,,,, , , ,, +   ,,,, , +  ,,    ,   ,,  ,,  , ,,,   + ,, ,+ ,,,,,,,,  ,,, ,  ,, ,,   ,        , ,  , ,,      
+ , , ,   ,,,,,,,   ,,,  + , , , + ,,,     ,,    ,    , , ,  ,, , ,, ,, ,,,       , ,,,,,,,   , ,,  ,,,,,,   , ,,,,,,,,,    ,,,,,++,,,,  ,,,,     ,, ,,,,,,   , ,  , ,  , ,           ,   ,   ,      ,, ,,,,,    , ,, ,   ,,,,   ,,    ,,       ,,   ,,    , ,,   ,   ,,  ,,  ,,,, ,,,,,,,  ,,  , ,       
,,     ,,,,          ,,  ,, , ,,, ,,,  ,,   , ,,,,,,,,,  ,,,  , ,,  ,  , ,,,,,,,,,,,,          ,,,  ,, ,,,    , ,                   ,,,, ,, ,     ,  ,  ,,    ,  , ,, ,   ,  ,,,,,,,,,     ,,,,,,,,  ,,  ,,,,,,,,,, ,,,,,,,,,,    , ,+++,,       , ,,,  ,,,  ,,,,,,,,,,,  ,, ,,,, ,,,,,,,    ,  , ,,       
,,   ,,  ,,          ,,,, ,, ,,  ,,,,,,,,+,,  ,,           ,,  ,      ,     ,,,,,,,        ,      ,, ,,   ,   ,  ,                     ,  ,  ,,,, ,,     ,,,  ,,  , ,                       ,,,,  ,,,                           , ,,              ,  ,,,,,+,,,         ,,, ,,               ,,, ,,        
,,,,,,,,,           ,,  ,,              ,,     ,        ,,  ,,    ,  ,                    , ,,,,,  ,     ,   ,                    ,,,, ,,  ,,   ,,  , , ,   ,  ,  ,                               ,, ,                         + +              ,        ,,,      , ,,   ,,              ,    ,,         
,,,,,                               ,,,,,,    ,,,,                     ,      ,,      ,   ,  ,                     ,,    ,,,,    ,    ,,   ,                                      ,,                        , ,                                 ,   ,,                 ,,,,           
,,                        ,,        ,,        ,,                       ,,,,,,,       ,   ,                    ,,,,               ,  ,,,,,   ,                                                              , ,                                  ,                                    
,,  ,,                                       ,       ,                    , ,                         ,              ,, ,,       ,  ,                       ,,,,                  ,,            
,,,               ,,             ,,                   ,,,                           ,, ,,                                        ,,,,,                       , ,           ,,,          ,,,,           ,,,  ,     ,,              ,,           ,, ,            ,,   ,,,            
,,,,              ,,,,           ,,,              ,,   , ,,  ,                        ,,,  ,,                            ,,,           ,,         ,,,,              ,,     ,, ,  ,, ,,,, , ,,  ,,       ,,    ,,,,,  ,  ,,,   ,,  , ,, ,,,      ,, , ,,,       ,,,,  ,  ,,,         
,, +         ,,,  ,, ,          ,, ,,             ,,,  , , ,,,,                 , ,       ,,,,   ,,      ,,,             +,+,  ,,     , ,,          ,,             ,,,,   ,, ,, ,,,,,,,, ,,,, ,,,,      ,, ,,,     ,,  ,, ,   ,,, ,, ,,, ,       , , ,         ,, ,, ,,             
, ,         , ,  ,, +          ,, ,,             , ,, , , ,  ,       ,,,       ,     ,,, , ,,  ,,+,    ,, ,      ,,,,  ,+ ,, ,,,,    , ,,  ,,,,         ,,,      ,  ,,  ,,  ,  ,,,, , , ,,,,   ,,   ,, ,, ,  ,        ,, ,   , ,  , , ,,,   ,,  ,  ,,,,    ,   ,, ,,,, ,,,         
, ,  ,,+,,  , ,,  , ,  ,,,      +,,,      ,       , , , , ,          , ,    ,    ,,  , , ,,,,  ,  +,  , ,+,      ,,,+  ,,,,, +, ,,   ,,,  ,,  ,   ,,,, ,,        , ,,  ,,   ,, ,,,, , , ,,   ,     ,,,  , ,   ,,,,,    ,  ,  , ,  , ,      ,,,, ,        ,,,,,  , ,,  ,,           
,  ,,,,         , , ,,,  ,  , ,   , , ,, ,,  ,,  ,   ,,, ,,,  ,,,, ,, , ,  ,,,      ,, ,   ,,, ,,,,, , , ,,,,  ,, +,   ,,     ,, ,, ,,  ,,  ,,   ,,       ,,      , ,, , ,,            ,  ,, ,  , , , + ,, ,  ,,, ,, ,  , ,  ,,  ,,    ,,,   , ,  , , ,,,  , ,, , , ,,,  ,   ,  ,,  ,  ,           
,,,,  ,,+ ,, +,,,+,    , , ,, ,,,  ,,    , , ,,, , ,,,  ,,  , , , ,, ,   ,,  , , ,,,+, ,,+,, ,    , , ,, ,, , , ,,,,  , ,+      ,,, ,+,, , ,,    ,+,  ,  ,,  ,,,,,   ,,  ,, , , ,,     ,  ,,, , ,  , , , , , + ,, , +,,, ,, ,  , ,  ,,   , ,,       , ,  , , , ,, ,, , , , , ,, ,,, , ,, ,, , , ,,,       
,+, , ,, ,,,, ,,,,,+    , , ,,        ,,  , ,     , , ,,,+,,,, , ,, , , ,     , , ,, ,,,,     ,    , ,,,,  , , , ,,,, ,, ,     ++, ,,,, ,,, ,,  ,+,,    ,,,  ,,           ,, , , ,,  ,, ,,,  ,,, ,,,  , , ,,, , , ,,,,  , ,, ,  , ,   ,   , ,,,,,,,, , , ,,,, + ,, ,, , , , , ,,   , , ,  ,, ,  ,,,,       
+,  , ,, ,, , ,,,, +,  ,,  ,,   ,,,  ,,,, + + ,,  , ,, ,,     ,, ,, ,,,  ,,,, , ,  , , , ,+,,      ,       ,,, , , ,, ,  ,,,, ,,  ,   ,,   ,   ,, ,,,  ,    ,  ,,,,,,,,,  ,, , ,,,   ,      ,        , ,, ,, , ,, ,  ,, , ,, , ,+ ,  ,  ,      ,    ,  , , ,  , ,, ,  , ,  ,  ,, ,, ,, ,  , ,,  ,  ,,      
,, ,  ,,,  ,,, , ,, ,  ,, ,    ,,  ,   , , + + , ,,  ,,,  ,,,,,,  ,      ,   , ,,, ,, , ,,,  ,,,,    ,,, ,,,   ,, , ,  ,,    , ,, ,,,,,,,, ,  , , ,,,+,,,    , ,           , ,        ,,,,,,,,,,,,,,,, ,  ,,,,,   ,,  ,, , ,,+, ,, , ,, ,,,,,,,,,,,,   ,  ,,, ,, ,  ,,,, ,,,  ,,  ,,,,  ,  ,  ,, ,, ,,      
,,+,,,  ,,,    , ,  , ,,  , ,,,,,++,  ,, , ,,, ,,,     ,,,,    , ,, ,,,,,,,,,     ,, , , ,       ,, ,   ,,,,,,,,  ,,,  ,,,,,, , ,,,,     ,,,     ,,   ,, , ,  ,          ,  ,,                         ,,,,,,  ,,,,  ,, ,,  ,,  ,, , , ,   ,,,,,,,,,,,,      ,  ,,  ,,   ,,,,           ,,,,  ,  ,, ,,      
,,,,,,,  ,    ,    ,  ,       ,, ,,  ,              ,,  , , ,            ,,, ,, , ,     ,,, ,                         ,  ,,,,         , , ,       ,    ,            ,                                       ,,, ,,        , ,  ,,                   ,,  ,            , ,                  ,, ,       
,,,        ,  ,, , ,,,,,         ,, ,,                  ,      ,,               ,,  , ,    ,  ,,                     ,,     ,, ,,           , ,       ,    ,         ,,                                            ,,         , ,  ,                      ,,             ,,,              ,,,,  ,,       
,, ,,         ,, ,, ,  ,,       ,,  ,,                  ,      ,,             ,,,    , ,    ,,,,  ,,,                 ,    ,,, ,,            , ,       ,                                                        ,,,,           ,    ,                   ,,,                                     ,,        
,,,        ,,,   , ,,         ,,  ,,                     ,,   ,                         ,                             ,      ,+,             , ,       ,,                                                                      ,,   ,                                                      ,,,,,          
,            , ,            ,,,                    ,,, ,,,                        ,                                ,,  ,,,                , ,       ,  ,                                                                    ,  ,,                                                                     
, ,            , ,          ,,                         ,,,                           ,  ,,                            ,,,,                     ,,      , ,,                                                                   ,,, ,                                                                     
,,,            , ,,                                                                                                                           ,        ,,,,                                                                     ,,                                                                      
,                                                                       ,,                                                     ,,        ,                                                                                                                                              
,                                                                         ,                                                                                                                                                                                                            `