import React from 'react';
import Markdown from './Markdown';
import FooterShim from './FooterShim';
import PlainTextSketch from './PlainTextSketch';

function About({ lang, content }) {
  let text = ("en" === lang)
    ? content.text
    : content.text_arabic

  return(
    <>
      <div className="about-background-sketch__outer">
        <div className="about-background-sketch__inner">
          <PlainTextSketch
            sketch="arabicText"
            style={{
              color: "#dbdbdb"
            }}
            preStyle={{
              textShadow: '#dbdbdb 0px 0px 2px'
            }}
          />
        </div>
      </div>

      <div className="about__wrap rtl">
        <div className="about__wrap-inner no-list-style">
          <Markdown>
            {text}
          </Markdown>

          <FooterShim />
        </div>
      </div>
    </>
  )
}

export default About;
