import React from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

//
import 'mapbox-gl/dist/mapbox-gl.css';

//
import About from './components/About';
import Saint from './components/Saint';
import Header from './components/Header';
import Map from './components/Map';
import FooterShim from './components/FooterShim';

//
import mapLayers from './content/serve/mapLayers';
import pageContent from './content/serve/pages.json';

export const RemoveTrailingSlash = ({...rest}) => {
    const location = useLocation()

    // If the last character of the url is '/'
    if (location.pathname.match('/.*/$')) {
        return <Navigate replace {...rest} to={{
            pathname: location.pathname.replace(/\/+$/, ""),
            search: location.search
        }}/>
    } else return null
}

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedMapLayer: "known_burial_locations",
      selectedSaint: null,
      showMobileMap: false,
      lang: "en"
    }
  }

  onSelectMapLayer(selectedMapLayer) {
    console.log("--> onSelectMapLayer:", selectedMapLayer);

    if ("image" === mapLayers[selectedMapLayer].mapType) {
      this.setState({ selectedMapLayer: null });
    } else if (selectedMapLayer !== this.state.selectedMapLayer) {
      this.setState({ selectedMapLayer });
    }
  }

  onSelectSaint(selectedSaint) {
    if (selectedSaint === this.state.selectedSaint) {
      this.setState({
        selectedSaint: null,
        showMobileMap: false
      });

      if (this.Map) {
        this.Map.reset();
      }
    } else {
      this.setState({
        selectedSaint
      });

      if (this.Map) {
        this.Map.flyTo({ center: selectedSaint.location_polygon
          ? selectedSaint.location_polygon.centroid
          : selectedSaint.location.coordinates });
      }
    }
  }

  onShowMobileMap() {
    this.setState({ showMobileMap: true });

    this.Map.flyTo({
      center: this.state.selectedSaint.location.coordinates,
      zoom: 13.7
    });
  }

  onHideMobileMap() {
    this.setState({ showMobileMap: false });
  }

  onSelectLang(lang) {
    if (lang === 'ar') document.body.classList.add("ar");
    else document.body.classList.remove("ar");
    this.setState({ lang });
  }

  renderSaint(o, addProps) {
    let props = {
      ...o,
      ...(addProps)?addProps:{},
      isSelected: this.state.selectedSaint && this.state.selectedSaint.name === o.name,
      onSelect: () => this.onSelectSaint(o),
      locationType: (o.location_polygon)
        ? "approximate"
        : (o.location && o.location.coordinates)
          ? "exact"
          : "unknown"
    }

    return(
      <Saint
        {...props}
        lang={this.state.lang}
        isSelected={this.state.selectedSaint && this.state.selectedSaint.name === o.name}
        onSelect={() => this.onSelectSaint(o)}
      />
    )
  }

  render() {
    let mapLayer = mapLayers[this.state.selectedMapLayer];

    return(
      <div className="app-wrap__outer">
        <Header
          lang={this.state.lang}
          onSelectLang={this.onSelectLang.bind(this)}
        />

        <div className={["app-wrap__inner", "/" === this.props.location.pathname && "index"].join(" ")}>
          <Routes>
            <Route path="/" element={(
              <React.Fragment>
                <div className="side-menu__wrap">
                  { /* select collection */ }
                  <div className="map-nav">
                    <p>Select map layer:</p>

                    <div className="map-nav__select">
                      <select name="map-layer" onChange={(e) => this.onSelectMapLayer(e.target.value)} defaultValue={"known_burial_locations"}>
                        {
                          Object.keys(mapLayers).map((k, i) => (
                            <option key={`option-${k}-${i}`} value={k}>
                              {mapLayers[k].displayName}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  { /* list of saints  */ }
                  <div className="saint-listing__wrap">
                    {mapLayer.items.map((o, i) => this.renderSaint(o, { key: o.id }))}
                    <FooterShim />
                  </div>

                  { /* saint details panel overlaid when a saint is selected */}
                  {this.state.selectedSaint && (
                    <div className="saint-details__wrap">
                      <div className="saint-details__controls">
                        <p onClick={() => this.onSelectSaint(this.state.selectedSaint)}>
                          {"⬅️  Back to list"}
                        </p>

                        <p
                          className="view-on-map-toggle mobile"
                          onClick={() => this.state.showMobileMap ? this.onHideMobileMap() : this.onShowMobileMap()}
                        >
                          {this.state.showMobileMap ? "❌  Close map" : "📍  Open map"}
                        </p>
                      </div>

                      { this.renderSaint(this.state.selectedSaint, {
                          selected: true,
                          inactive: true
                        })}
                    </div>
                  )}
                </div>

                <div className={["page-wrap", this.state.showMobileMap && "show-mobile-map"].join(" ")}>
                  {"mapbox" === mapLayer.type
                    ? <Map
                        ref={ref => this.Map = ref}
                        items={mapLayer.items}
                        initialViewState={mapLayer.initialViewState}
                        selectedItem={this.state.selectedSaint}
                        isMobileMap={this.state.showMobileMap}
                        onClick={(item) => this.onSelectSaint(item)}
                        // onMove={evt => console.log(evt)}
                      />
                    : <div className="print-map">
                        <p className="location-unknown-tooltip">
                          {"❌  Location unknown"}
                        </p>
                      </div>
                  }
                </div>
              </React.Fragment>
            )} />

            <Route path="about" element={(
              <About
                content={pageContent.About}
                lang={this.state.lang}
              />
            )} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default () => {
  let location = useLocation();
  return <App location={location} />
}